import React from "react"
import {Navigate, useLocation} from "react-router-dom"
import {apiProvider} from "../api/api"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {User} from "../api/models/users";

interface AuthContextType {
    user: User | undefined
    userName: string | undefined
    signIn: (user: string, password: string) => Promise<void>
    signOut: () => Promise<void>
}

let AuthContext = React.createContext<AuthContextType>(null!)

export function AuthProvider({children}: { children: React.ReactNode }) {
    const queryClient = useQueryClient()
    let [userName, setUserName] = React.useState<any>(apiProvider.userName)
    const {data: user} = useQuery(["me", userName], () => userName ? apiProvider.getMe() : undefined)

    let signIn = async (newUser: string, password: string) => {
        await apiProvider.login(newUser, password)
        setUserName(newUser)
    }

    function onLogout() {
        setUserName(undefined)
        queryClient.clear()
    }

    let signOut = async () => {
        await apiProvider.logout()
        onLogout()
    }

    apiProvider.loggedOutDelegate = async () => {
        onLogout()
    }

    let value = {user, userName, signIn, signOut}

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
    return React.useContext(AuthContext)
}

export function RequireAuth({children}: { children: JSX.Element }) {
    const auth = useAuth()
    const location = useLocation()

    if (!auth.userName) {
        return <Navigate to="/login" state={{from: location}} replace/>
    }

    return children
}
