import {DataGrid, GridColDef, GridRenderCellParams, GridToolbarExport} from "@mui/x-data-grid"
import { useQuery } from "@tanstack/react-query"
import { apiProvider } from "../api/api"
import { Avatar, Chip, Grid, Stack, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

function CustomToolbar() {
    return (
        <GridToolbarExport sx={{ml: 1}} csvOptions={{
            fileName: 'userListe'}} />
    )
}

export const UserList = () => {
    const {t} = useTranslation()
    const {data, isLoading} = useQuery(
        [`users`],
        async () => await apiProvider.getUsers()
    )

    const rows = data?.map((user) => {
        return {
            id: user.username,
            name: user.name,
            email: user.email,
            username: user.username,
            groups: user.groups,
        }
    })

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            editable: true,
            renderHeader: () => <strong>{t("user_list.table.user_name")}</strong>,
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Grid container flexDirection={"row"} alignItems={"center"}>
                        <Tooltip title="Name" placement="bottom-end">
                            <Avatar
                                // alt="Avatar"
                                // src={require(`../../src/assets/avatar/Avatar1.jpg`)}
                                sx={{width: 20, height: 20, marginRight: 1}}
                            />
                        </Tooltip>
                        <Typography variant="body2">{params.value}</Typography>
                    </Grid>
                )
            },
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            editable: true,
            renderHeader: () => <strong>{t("user_list.table.user_email")}</strong>,
        },
        {
            field: "username",
            headerName: "Username",
            flex: 1,
            editable: true,
            renderHeader: () => <strong>{t("user_list.table.user_user_name")}</strong>,
        },
        {
            field: "groups",
            headerName: "Groups",
            flex: 1,
            renderHeader: () => <strong>{t("user_list.table.user_roles")}</strong>,
            renderCell: (params: GridRenderCellParams<string[]>) => {
                return (
                    <Stack direction="row" spacing={1}>
                        {params.value?.map((group) => {
                            return (
                                <Chip
                                    label={group}
                                    variant="outlined"
                                    size="small"
                                    color={"primary"}
                                />
                            )
                        })}
                    </Stack>
                )
            },
        },
    ]

    return (
        <div style={{display: "flex", height: "100%", marginTop: 40, backgroundColor: "white"}}>
            <div style={{flexGrow: 1, backgroundColor: "white"}}>
                <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    marginLeft={3}
                    marginBottom={2}
                >
                    {t("user_list.title")}
                </Typography>
                <DataGrid
                    loading={isLoading}
                    rows={rows !== undefined ? rows : []}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick
                    sx={{bgcolor: "white"}}
                    disableColumnMenu={true}
                    components={{
                        Toolbar: CustomToolbar,  // This line enables the custom toolbar with only CSV export
                    }}
                />
            </div>
        </div>
    )
}
