import {createTheme} from "@mui/material/styles"

export const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: "#0000FF",
        },
        secondary: {
            // This is green.A700 as hex.
            main: "#d3d3d3",
        },
    },
})
