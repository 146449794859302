/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Checkbox,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Help } from "@mui/icons-material";
import { MultiSelectionQuestion } from "../questions";
import { FormElementProps } from "../FormContent";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { useSavableManyState } from "../util";

export default function MultiSelectionQuestionComponent({
  formElement,
  hasValidInput,
}: FormElementProps<MultiSelectionQuestion>) {
  const [inputs, setInputs] = useSavableManyState(formElement);

  const handleToggle = (parameterName: string) => () => {
    const newValue = (!(inputs[parameterName] === "true")).toString();
    setInputs({ ...inputs, [parameterName]: newValue });
  };

  useEffect(() => {
    hasValidInput(true);
  });

  return (
    <div>
      <Grid
        container
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingTop={2}
        paddingBottom={2}
        width={"100%"}
      >
        <Grid
          container
          flexDirection={"row"}
          paddingBottom={2}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "100%" }}
        >
          <Typography
            sx={{
              fontSize: { xs: 20, md: 30 },
              fontWeight: "bold",
              align: "center",
            }}
          >
            {formElement.title}
          </Typography>
          <Tooltip title={formElement.info} placement="bottom-end">
            <Help
              sx={{
                height: "20px",
                width: "20px",
                marginBottom: "6px",
                marginLeft: "2px",
                color: "gray",
              }}
            ></Help>
          </Tooltip>
        </Grid>
        <Grid item width={"80%"}>
          <Typography
            sx={{
              fontSize: { xs: 12, md: 16 },
              align: "left",
              textAlign: "left",
              marginBottom: 2,
            }}
          >
            {formElement.info}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          paddingBottom={2}
        >
          <Grid
            container
            width={"80%"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography fontWeight={"bold"}>Verarbeitungszweck</Typography>
            <Typography fontWeight={"bold"} paddingRight={33}>
              Rechtsgrundlage
            </Typography>
          </Grid>
          <List
            sx={{
              maxHeight: 300,
              width: "85%",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 10,
                borderRadius: 2,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "lightgray",
                borderRadius: 2,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#0B1364",
                borderRadius: 2,
              },
            }}
          >
            {formElement.options.map(
              ({
                title,
                description,
                property,
                answerOption,
                legalBasis,
                id,
              }) => {
                const labelId = `checkbox-list-secondary-label-${answerOption}`;
                return (
                  <ListItem
                    key={id}
                    onClick={handleToggle(property)}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(property)}
                        checked={inputs[property] === "true"}
                        inputProps={{ "aria-labelledby": answerOption }}
                        color={inputs[property] ? "primary" : "secondary"}
                      />
                    }
                    sx={{ paddingBottom: 0.5 }}
                  >
                    <ListItemButton
                      sx={{
                        height: 30,
                      }}
                    >
                      <Box width={"60%"}>
                        <ListItemText
                          id={labelId}
                          primary={`${answerOption}`}
                        />
                      </Box>
                      <ListItemText id={labelId} primary={`| ${legalBasis}`} />
                    </ListItemButton>
                  </ListItem>
                );
              }
            )}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
