import React, {useEffect} from "react"
import Grid from "@mui/material/Grid"
import {Tooltip, Typography} from "@mui/material"
import Mediacard from "./mediacard"
import {OptionQuestion} from "../questions"
import {Help} from "@mui/icons-material"
import {FormElementProps} from "../FormContent"
import {useSavableState} from "../util"

export default function OptionQuestionComponent(
    {
        formElement,
        hasValidInput
    }: FormElementProps<OptionQuestion>) {
    const [selected, setSelected] = useSavableState(formElement)

    useEffect(() => {
        hasValidInput(selected !== undefined)
    })

    function update(option: string) {
        setSelected(option)
        hasValidInput(true)
    }

    return (
        <div>
            <Grid
                container
                columns={12}
                flexDirection={"column"}
                paddingTop={2}
                sx={{width: "100%"}}
            >
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: "100%"}}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        {formElement.title}
                    </Typography>
                    <Tooltip title={formElement.info} placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Grid>
                <Grid
                    container
                    flexDirection="row"
                    justifyContent={"center"}
                    paddingBottom={2}
                >
                    {formElement.options.map((option) => {
                        return (
                            <Mediacard
                                key={option.property_value}
                                heading={option.title}
                                content={option.description}
                                image={option.image}
                                selected={selected === option.property_value}
                                onSelected={() => {
                                    update(option.property_value)
                                }}
                            />
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    )
}
