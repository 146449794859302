import {
    Autocomplete,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    ListItem, ListItemButton, ListItemText,
    Select, Stack,
    TextField
} from "@mui/material"
import * as React from "react";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditableUserItem from "../ui/EditableUserItem";
import { BaseTask, Task, TaskGroup, FormTemplate, ProcurementRequestTemplate } from "../api/models/tasks"
import { User } from "../api/models/users";
import { BaseTaskContract, FormTaskContract, TaskGroupContract, TaskTemplateContract } from "../api/apiContracts";
import { useQuery } from "@tanstack/react-query";
import { backendApiProvider } from "../api/backendApiProvider";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import { ArrowDropUp } from "@mui/icons-material";

export default function EditTaskDialog({ open, onClose, onSave, task, parent }: {
    open: boolean,
    onClose: () => void,
    onSave: (newTask: Partial<BaseTaskContract>) => void,
    task?: BaseTask
    parent?: TaskGroup
}) {
    const [taskType, setTaskType] = useState<string>(task?.resourcetype ?? "Task")
    const [title, setTitle] = useState<string>(task?.title ?? "")
    const [order, setOrder] = useState<number | undefined>(task?.order)
    const [assignee, setAssignee] = useState<User | undefined>(task instanceof Task ? task.assignee : undefined)

    // field for selecting form on FormTask
    const [formOptions, saveFormOptions] = useState<FormTemplate[]>([])
    const [selectedForm, setSelectedForm] = useState<FormTemplate | undefined>()

    // get form types from backend
    useEffect(() => {
        backendApiProvider.getFormTypes().then((formTypes) => {
            saveFormOptions(formTypes.map((form) => ({ id: form.id, label: form.name, key: form.key })))
        })
    }, [])

    const { t } = useTranslation()

    const handleSave = () => {
        let newTask: Partial<BaseTaskContract> = { title: title, order: order, parent: parent?.id }

        if (taskType === 'Task') {
            newTask = {
                ...newTask,
                resourcetype: "Task",
                assignee_id: assignee?.id
            } as Partial<Task>
        } else if (taskType === 'TaskGroup') {
            newTask = {
                ...newTask,
                resourcetype: "TaskGroup"
            }
        } else if (taskType === 'FormTask') {
            newTask = {
                ...newTask,
                resourcetype: "FormTask",
                form_id: selectedForm?.id,
                assignee_id: assignee?.id
            } as Partial<FormTaskContract>
        } else if (taskType === 'Integration Task') {
            newTask = {
                ...newTask,
                resourcetype: "IntegrationTask"
            }
        }

        onSave(newTask!)
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth
            maxWidth="md">
            <DialogTitle>{task === undefined ?
                <Typography sx={{ fontWeight: "bold" }}>{t("edit_dialog.new_task")}</Typography> :
                <Typography sx={{ fontWeight: "bold" }}>{t("edit_dialog.edit")}</Typography>}</DialogTitle>
            <DialogContent>
                {task === undefined && <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                    <Typography sx={{
                        fontWeight: "bold",
                        marginLeft: 0.5,
                        marginBottom: 0.5
                    }}>{t("edit_dialog.type")}</Typography>
                    <Select
                        value={taskType}
                        onChange={(e) => setTaskType(e.target.value as string)}
                    >
                        <MenuItem value="Task">Task</MenuItem>
                        <MenuItem value="TaskGroup">Task Group</MenuItem>
                        <MenuItem value="FormTask">Form Task</MenuItem>
                        <MenuItem value="Integration Task">Integration Task</MenuItem>
                    </Select>
                </FormControl>}
                <Typography sx={{ fontWeight: "bold", marginLeft: 0.5, }}>{t("edit_dialog.title")}</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ my: 1 }}
                />
                <Typography sx={{ fontWeight: "bold", marginLeft: 0.5, }}>{t("edit_dialog.order")}</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={order !== undefined ? order + 1 : undefined}
                    inputMode={"numeric"}
                    onChange={(e) => setOrder(e.target.value !== "" ? Number.parseInt(e.target.value) - 1 : undefined)}
                    sx={{ my: 1 }}
                />

                {taskType !== "TaskGroup" &&
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography sx={{ fontWeight: "bold", marginLeft: 0.5 }}>{t("edit_dialog.assignee")}</Typography>
                        <EditableUserItem
                            initialItem={assignee?.username ?? "unassigned"}
                            onSave={(itemKey, itemObject) => (itemObject instanceof User ? setAssignee(itemObject) : setAssignee(undefined))} />
                    </Stack>}
                {taskType === 'FormTask' && (
                    <Stack>
                        <Typography sx={{ fontWeight: "bold", marginLeft: 0.5, }}>{t("edit_dialog.form")}</Typography>
                        <Autocomplete
                            disablePortal
                            options={formOptions.map((form) => form.label)}
                            sx={{ width: 300 }}
                            onChange={(event, newValue) => {
                                setSelectedForm(formOptions.find((form) => form.label === newValue)!);
                            }}
                            renderInput={(params) => <TextField {...params} sx={{ my: 1 }} label={t("edit_dialog.form")} />}
                        />
                    </Stack>
                )}
                {
                    taskType === 'Integration Task' && (
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: "bold", marginLeft: 0.5 }}>{t("edit_dialog.trigger")}</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={order !== undefined ? order + 1 : undefined}
                                inputMode={"numeric"}
                                onChange={(e) => setOrder(e.target.value !== "" ? Number.parseInt(e.target.value) - 1 : undefined)}
                                sx={{ my: 1 }}
                            />
                        </Stack>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button sx={{ textTransform: "none" }} variant={"outlined"}
                    onClick={onClose}>{t("edit_dialog.close")}</Button>
                <Button sx={{ textTransform: "none" }} variant={"contained"} onClick={handleSave}
                    disabled={!taskType || !title}>{t("edit_dialog.save")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export function SelectTaskTemplateDialog({ open, onClose, onSelect }: {
    open: boolean,
    onClose: () => void,
    onSelect: (selectedTemplate: TaskTemplateContract) => void,
}) {

    const { t } = useTranslation()

    const { data: prTemplates } = useQuery<ProcurementRequestTemplate[]>(['procurement-request-templates'], () => backendApiProvider.getProcurementRequestTemplates())

    const [filteredPrTemplates, setFilteredPrTemplates] = useState<ProcurementRequestTemplate[]>([])
    const [searchText, setSearchText] = useState<string>('')

    useEffect(() => {
        setFilteredPrTemplates(prTemplates ?? [])
    }, [prTemplates])


    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setSearchText(text);

        setFilteredPrTemplates(
            prTemplates?.filter((template) => {
                let searchMatch = template.title.toLowerCase().includes(text.toLowerCase());

                template.templateTasks.map(taskTemplate => {
                    // Check if the title of the task template matches the search
                    const taskTitleMatch = taskTemplate.title.toLowerCase().includes(text.toLowerCase());

                    let subtaskMatch;

                    if (taskTemplate.resourcetype === "TaskGroup") {
                        const taskGroup = taskTemplate as TaskGroupContract
                        // Check if any subtask title matches
                        subtaskMatch = taskGroup.subtasks?.some((subtask) =>
                            subtask.title.toLowerCase().includes(text.toLowerCase())
                        );
                    }

                    if ( taskTitleMatch || subtaskMatch) {
                        searchMatch = true
                    }
                    return taskTitleMatch || subtaskMatch
                })
                return searchMatch
            }) ?? []
        );
    };

    const handleTemplateSelect = (selectedTemplate: TaskTemplateContract) => {
        onSelect(selectedTemplate)
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
            <DialogTitle sx={{ fontWeight: "bold" }}>{t("edit_dialog.select_template_title")}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    variant="outlined"
                    label={t("edit_dialog.search_template_placeholder")}
                    value={searchText}
                    onChange={handleSearch}
                    sx={{ my: 1 }}
                />
                {
                    filteredPrTemplates.length > 0 ?
                    <List>
                    {filteredPrTemplates.map((prTemplate, index) => {
                        return (
                            <PrTemplateListItem key={index} prTemplate={prTemplate} onSelect={handleTemplateSelect} index={index} searchText={searchText} />
                        )
                    })}
                </List>
                :
                <Stack>
                    <Typography>
                        {t("edit_dialog.search_template_no_results")}
                    </Typography>
                </Stack>
                }

            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} sx={{ textTransform: "none" }} onClick={onClose}>{t("general.close")}</Button>
            </DialogActions>
        </Dialog>
    )
}

const PrTemplateListItem = ({prTemplate, onSelect, index, firstLevel = true, searchText = ""} : { prTemplate: ProcurementRequestTemplate, onSelect: (template: TaskTemplateContract) => void, index: number, firstLevel?: boolean, searchText?: string }) => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(searchText !== "" && searchInTasks(prTemplate.templateTasks ?? [], searchText))
    }, [searchText])

    return (
        <ListItem>
            <Stack direction={"column"}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                >
                    <IconButton onClick={() => setOpen(!open)}>
                        {
                            open ?
                                <ArrowDropUp />
                            :
                                <ArrowDropDownIcon />
                        }
                    </IconButton>
                    <ListItemText
                        primary={prTemplate.title}
                        primaryTypographyProps={prTemplate.title.toLowerCase().includes(searchText.toLowerCase().trim()) && searchText.trim() !== "" ? { fontWeight: "bold" } : {}}
                        secondary={t("edit_dialog.select_template_list_item_subtitle")}
                    />
                </Stack>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                    {prTemplate.templateTasks?.map((template, index) => {
                        return (
                            <TaskTemplateListItem key={index} template={template} onSelect={onSelect} index={index} searchText={searchText} />
                            )
                        })}
                </List>
            </Collapse>
            </Stack>
        </ListItem>
    )

}

const TaskTemplateListItem = ({ template, onSelect, index, firstLevel = true, searchText = "" }: { template: TaskTemplateContract, onSelect: (template: TaskTemplateContract) => void, index: number, firstLevel?: boolean, searchText?: string }) => {

    // Determine initial open state based on searchText and subtasks
    const shouldOpen = searchText !== "" && template.resourcetype === "TaskGroup" && searchInTasks((template as TaskGroupContract).subtasks ?? [], searchText);

    // open state for displaying subtasks, only used if the template is a task group
    const [open, setOpen] = useState<boolean>(shouldOpen);

    // Update open state when searchText changes
    useEffect(() => {
        setOpen(shouldOpen);
    }, [searchText]);
    // on the first level, only display tasks that are not part of a task group
    if (firstLevel && template.parent) {
        return null
    }

    if (!template.title.toLowerCase().includes(searchText.toLowerCase()) && !shouldOpen) {
        return null
    }


    if (template.resourcetype === "TaskGroup") {
        const taskGroup = template as TaskGroupContract
        return (
            <ListItem key={index}>
                <Stack flexDirection={"column"} alignItems={"start"} >
                    <Stack flexDirection={"row"} alignItems={"center"}>
                    <IconButton onClick={() => setOpen(!open)}>
                            {
                                open ?
                                    <ArrowDropUp />
                                    :
                                    <ArrowDropDownIcon />
                            }
                        </IconButton>
                        <ListItemButton onClick={() => onSelect(template)}>
                            <ListItemText
                                primary={template.title}
                                primaryTypographyProps={template.title.toLowerCase().includes(searchText.toLowerCase().trim()) && searchText.trim() !== "" ? { fontWeight: "bold" } : {}}
                                secondary={`(${taskGroup.subtasks?.length} Tasks)`}
                                secondaryTypographyProps={{marginLeft: 2}}
                                sx={{display: "flex", flexDirection:"row"}}
                            />
                        </ListItemButton>

                    </Stack>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding dense>
                            {taskGroup.subtasks?.filter((subtask => {
                                return subtask.title.toLowerCase().includes(searchText.toLowerCase())
                            }))
                            .map((subtask) => (
                                <TaskTemplateListItem key={subtask.id} template={subtask} onSelect={onSelect} index={index} firstLevel={false} searchText={searchText} />
                            ))}
                        </List>
                    </Collapse>
                </Stack>
            </ListItem>
        )
    }
    else {
        return (
            <ListItem key={index}>
                <ListItemButton onClick={() => onSelect(template)} >
                    <ListItemText primary={template.title} primaryTypographyProps={template.title.toLowerCase().includes(searchText.toLowerCase().trim()) && searchText.trim() !== "" ? { fontWeight: "bold" } : {}} />
                </ListItemButton>
            </ListItem>
        )
    }
}


// Recursive function to check if an array of tasks matches a search string
const searchInTasks = (tasks: TaskTemplateContract[], searchText: string): boolean => {
    for (const task of tasks) {
        if (task.title.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }

        if (task && task.resourcetype === "TaskGroup") {
            const taskgroup = task as TaskGroupContract
            const subtasks = taskgroup.subtasks ?? []
            // If the subtask is a task group, check if any of its subtasks match
            if (searchInTasks(subtasks, searchText)) {
                return true;
            }
        }
    }

    return false;
};
