import {Button, Grid, Typography} from "@mui/material"

interface DummybuttonProps {
    nextScreen: (params: any) => any;
    lastScreen: (params: any) => any;
    enabled: boolean;
}

export default function Dummybutton({
                                        nextScreen,
                                        lastScreen,
                                        enabled,
                                    }: DummybuttonProps) {
    return (
        <div>
            <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
                paddingBottom={2}
            >
                <Button
                    onClick={lastScreen}
                    variant="outlined"
                    color="primary"
                    sx={{width: 120, height: 40}}
                >
                    <Typography variant={"body2"}>Zurück</Typography>
                </Button>
                <Button
                    onClick={nextScreen}
                    color="primary"
                    variant="contained"
                    disabled={!enabled}
                    sx={{width: 120, height: 40}}
                >
                    <Typography variant={"body2"}>Weiter</Typography>
                </Button>
            </Grid>
        </div>
    )
}
