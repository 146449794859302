import {BackendApiProvider} from "../backendApiProvider";
import {ProcurementRequestContract, ProcurementRequestUpdateContract} from "../apiContracts";
import {Product, Vendor} from "./vendors";
import {User} from "./users";

export type ProcurementRequestState = "open" | "approved" | "canceled"


export interface PropertyValue {
    value: string
    name: string
    translation?: string
    order?: number
    group?: string
}

export interface PropertyValueUpdate {
    key: string
    value: string
}

export interface PropertyGroup {
    key: string,
    name: string
    order?: number
}

export interface ProcurementRequestUpdate {
    title?: string
    description?: string
    vendor?: Vendor | null
    product?: Product | null
    coordinator?: User
    properties: {
        values: { [key: string]: PropertyValue }
        groups: PropertyGroup[]
    }
}

export class ProcurementRequest {
    id: number
    title: string
    conclusion: string
    created_by: User
    coordinator?: User
    properties: {
        values: { [key: string]: PropertyValue }
        groups: PropertyGroup[]
    }
    description: string
    status: ProcurementRequestState
    created_at: Date
    tasksStatus: { completedTasks: number, totalTasks: number }
    vendor?: Vendor
    productId?: string
    documents: Document[]

    get product(): Product | undefined {
        return this.vendor?.products?.find(product => product.id === this.productId)
    }


    constructor(id: number,
                title: string,
                conclusion: string | undefined,
                created_by: User,
                coordinator: User | undefined,
                properties: {
                    values: { [key: string]: PropertyValue }
                    groups: PropertyGroup[]
                },
                description: string,
                status: ProcurementRequestState,
                created_at: Date,
                tasksStatus: { completedTasks: number, totalTasks: number },
                documents: Document[],
                vendor?: Vendor,
                productId?: string,
    ) {
        this.id = id
        this.title = title
        this.description = description
        this.conclusion = conclusion ?? ""
        this.created_by = created_by
        this.coordinator = coordinator
        this.properties = properties
        this.status = status
        this.created_at = created_at
        this.tasksStatus = tasksStatus
        this.vendor = vendor
        this.productId = productId
        this.documents = documents
    }


    getPropertyByKey(key: string): PropertyValue | undefined {
            return this.properties.values[key]
    }

    getPropertiesGrouped(): {
        default: { [key: string]: PropertyValue },
        groups: (PropertyGroup & { items: { [key: string]: PropertyValue } })[]
    } {
        return this.getPropertiesByOrder()
    }

    getPropertiesByOrder(order?: number): {
        default: { [key: string]: PropertyValue },
        groups: (PropertyGroup & { items: { [key: string]: PropertyValue } })[]
    } {
        const groups: { [key: string]: PropertyGroup & { items: { [key: string]: PropertyValue } } } = {}
        const default_group: { [key: string]: PropertyValue } = {}

        for (const group of this.properties.groups) {
            if (order === undefined || (group.order ?? Number.MAX_VALUE) <= order) {
                groups[group.key] = {...group, items: {}}
            }
        }

        for (const [key, value] of Object.entries(this.properties.values)) {
            if (value.group && groups[value.group]) {
                groups[value.group].items[key] = value
            } else {
                if (order === undefined || (value.order ?? Number.MAX_VALUE) <= order) {
                    default_group[key] = value
                }
            }
        }

        const groupsSorted = Object.values(groups).sort((a, b) => (a.order ?? Number.MAX_VALUE) - (b.order ?? Number.MAX_VALUE))
        return {default: default_group, groups: groupsSorted}
    }

    static fromBackendData(data: ProcurementRequestContract) {
        const property_values: { [key: string]: PropertyValue } = {}
        data.properties?.values?.forEach((element => {
            property_values[element.key] = {
                value: element.value,
                name: element.name,
                translation: element.translation,
                group: element.group,
                order: element.order
            }
        }))

        const properties = {
            values: property_values,
            groups: data.properties?.groups ?? []
        }


        const documents = data.documents.map((document => {
            return new Document(
                document.id,
                document.title,
                document.file,
            )
        }))

        return new ProcurementRequest(
            data.id,
            data.title as string,
            data.conclusion,
            new User(data.created_by),
            data.coordinator ? new User(data.coordinator) : undefined,
            properties,
            data.description,
            data.status,
            new Date(data.created_at),
            {completedTasks: data.tasks_status.completed_tasks, totalTasks: data.tasks_status.total_tasks},
            documents,
            data.vendor ? Vendor.fromContract(data.vendor) : undefined,
            data.product,
        )
    }
}

export abstract class Comment {
    id: number
    created_by: User
    created_at: Date

    protected constructor(id: number, created_by: User, created_at: Date) {
        this.id = id
        this.created_by = created_by
        this.created_at = created_at
    }
}

export class UserComment extends Comment {
    message: string

    constructor(id: number, message: string, created_by: User, created_at: Date) {
        super(id, created_by, created_at)
        this.message = message
    }
}

export class RobotComment extends Comment {
    translationKey: string
    formattingArgs?: { [key: string]: any }
    actions: { type: string }[]

    constructor(id: number, message: {
        key: string,
        formatting_args?: { [key: string]: any },
        actions: { type: string }[]
    }, created_by: User, created_at: Date) {
        super(id, created_by, created_at)
        this.translationKey = message.key
        this.formattingArgs = message.formatting_args
        this.actions = message.actions
    }
}

export class Document {
    id: number
    title: string
    file: string

    constructor(id: number, title: string, file: string) {
        this.id = id
        this.title = title
        this.file = file
    }
}
