import * as React from "react"
import { Box, Stack, TextField } from "@mui/material"
import List from "@mui/material/List"
import { Edit } from "@mui/icons-material"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import { StatusText } from "./Sidebar"
import { humanReadableDate } from "../../util/date"
import { UserItem } from "../../ui/UserListItem"
import { ProcurementRequest, ProcurementRequestUpdate } from "../../api/models/procurementRequest";
import { User } from "../../api/models/users";
import EditableUserItem from "../../ui/EditableUserItem"
import { Product, Vendor } from "../../api/models/vendors"
import { VendorAutocompleteTextField } from "../../vendors/VendorAutocompleteTextField"
import { ProductAutocompleteTextField } from "../../vendors/ProductAutocompleteTextField"

import { ProcurementRequestUpdateContext } from "../AllPropertyDetails"

export function TicketParamList({
    procurementRequest
}: {
    procurementRequest: ProcurementRequest,
}) {

    return <List
        sx={{
            width: "100%",
            bgcolor: "background.paper",
            alignItems: "flex-start",
            borderRadius: 5,
            paddingTop: 7.8,
        }}
    >
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ marginBottom: 1, }}>
            <Edit onClick={() => console.log("Edit")} sx={{
                marginLeft: 2, width: 20, height: 20, "&:hover": {
                    backgroundColor: "transparent",
                    cursor: "pointer",
                }, color: "white"
            }} />
            <StatusText procurementRequest={procurementRequest} />
        </Stack>
        <TicketParams />
    </List>
}


function TicketParams() {

    const { procurementRequest, editMode, procurementRequestUpdate, handleFieldChange, propertyConfigs } = React.useContext(ProcurementRequestUpdateContext)

    if (!procurementRequest) {
        return <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant={"h6"}>Kein Ticket ausgewählt</Typography>
        </Box>
    }

    // get vendor and product name from properties
    const vendorName = procurementRequestUpdate.properties.values["vendor_name"]?.value
    const productName = procurementRequestUpdate.properties.values["product_name"]?.value

    return <List component="div" disablePadding>
        <TextListItem title={"Ticket ID"} content={procurementRequest.id.toString()} />
        <TextListItem title={"Erstellt"}
            content={procurementRequest?.created_at !== undefined ? humanReadableDate(procurementRequest.created_at) : ""} />
        <UserListItem title={"Ersteller"} user={procurementRequest?.created_by} />
        {
            editMode ?
                <TextInputListItem
                    title={"Titel"}
                    content={procurementRequestUpdate.title ?? procurementRequest?.title}
                    onChange={(value: string) => handleFieldChange("title", value)} />
                :
                <TextListItem title={"Titel"} content={procurementRequest?.title} />
        }
        {
            editMode ?
                <UserEditableListItem title={"Koordinator"} user={procurementRequest?.coordinator} onChange={(value: User) => handleFieldChange("coordinator", value)} />
                :
                <UserListItem title={"Koordinator"} user={procurementRequest?.coordinator} />
        }
        {
            editMode ?
                <TextInputListItem title={"Angefragter Lieferant"} content={vendorName} onChange={(value: string) => handleFieldChange("property", { key: "vendor_name", value: value })} />
                :
                <TextListItem title={"Angefragter Lieferant"} content={vendorName} />

        }
        {
            editMode ?
                <TextInputListItem title={"Angefragtes Produkt | Dienstleistung"} content={productName} onChange={(value: string) => handleFieldChange("property", { key: "product_name", value: value })} />
                :
                <TextListItem title={"Angefragtes Produkt | Dienstleistung"} content={productName} />

        }
        {
            editMode ?
                <VendorEditListItem
                    title={"Lieferant"}
                />
                :
                <TextListItem title={"Lieferant"} content={procurementRequest?.vendor?.name ?? ""}
                    link={procurementRequest.vendor !== undefined ? `/vendors/${procurementRequest.vendor.id}` : undefined} />
        }
        {
            editMode ?
                <ProductEditListItem
                    title={"Produkt"}
                />
                :
                <TextListItem title={"Produkt"} content={procurementRequest?.product?.name ?? ""} />
        }
        {
            editMode ?
                <TextInputListItem title={"Beschreibung"} content={procurementRequest?.description} onChange={(value: string) => handleFieldChange("description", value)} />
                :
                <TextListItem title={"Beschreibung"} content={procurementRequest?.description} />
        }
    </List>
}

function GenericListItem(props: { title: string, children: React.ReactNode }) {
    return <ListItem key={"value.key"} dense>
        <ListItemText
            primary={props.title}
            primaryTypographyProps={{
                fontWeight: "bold",
            }}
        />
        {props.children}
    </ListItem>
}

function TextListItem(props: { title: string, content: string | undefined, link?: string }) {
    return <GenericListItem title={props.title}>
        <Typography
            style={{ wordWrap: "break-word" }}
            width={"40%"}
            textAlign={"right"}
            component={props.link ? "a" : "span"}
            href={props.link}
            fontSize={14}>
            {props.content ?? ""}
        </Typography>
    </GenericListItem>
}

function TextInputListItem({ title, content, onChange }: { title: string, content: string | undefined, onChange: (value: string) => void }) {
    const [value, setValue] = React.useState(content)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onChange(event.target.value)
    };

    return <GenericListItem title={title}>
        <TextField
            sx={{ minWidth: 300 }}
            value={value}
            onChange={handleChange}
        />
    </GenericListItem>
}

function UserListItem(props: { title: string, user: User | undefined }) {
    return <GenericListItem title={props.title}>
        <Box alignItems={"right"}>
            <UserItem user={props.user ?? "Unassigned"} />
        </Box>
    </GenericListItem>
}

function UserEditableListItem(props: { title: string, user: User | undefined, onChange: (value: User) => void }) {

    const handleSave = (itemKey: string, itemObject: User | string) => {
        if (typeof itemObject === "string") {
            return
        }
        props.onChange(itemObject)
    }
    return (<GenericListItem title={props.title}>
        <Box alignItems={"left"} marginY={1} minWidth={"300px"} alignContent={"center"}>
            <EditableUserItem
                initialItem={props.user ? props.user.name : "unassigned"}
                onSave={handleSave}
            />
        </Box>
    </GenericListItem>
    )
}


function VendorEditListItem(props:
    { title: string }) {

    return <GenericListItem title={props.title}>
        <VendorAutocompleteTextField />
    </GenericListItem>
}

function ProductEditListItem(props:
    { title: string }) {

    return <GenericListItem title={props.title}>
        <ProductAutocompleteTextField />
    </GenericListItem>
}
