import {useQueryClient} from "@tanstack/react-query"
import * as React from "react"
import {CompoundFormElement, Form} from "../../forms/questions"
import {apiProvider} from "../../api/api"
import {mapPropertiesToAnswers} from "../../util/utils"
import {UpdateProcurementRequestTerminal} from "../../forms/FormTerminals"
import {Dialog} from "@mui/material"
import {Fragment, useEffect} from "react"
import {FormContainer} from "../../forms/FormContent"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import {OpenInNew} from "@mui/icons-material"
import {FormTask} from "../../api/models/tasks";
import {ProcurementRequest} from "../../api/models/procurementRequest";

export function FormTaskListItem(props: {
    procurementRequest: ProcurementRequest,
    task: FormTask
}) {

    const [open, setOpen] = React.useState(false)

    return (
        <>
            <FormDialog open={open} setOpen={setOpen}
                        formTask={props.task}
                        procurementRequest={props.procurementRequest}/>
            <ListItem key={props.task.id}
                      onClick={() => setOpen(!open)}
                      dense>
                <ListItemText
                    primary={props.task.form.name}
                    sx={{width: "50%"}}
                    primaryTypographyProps={{fontWeight: "bold"}}
                />
                <OpenInNew sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                        cursor: "pointer",
                    },
                }}></OpenInNew>
            </ListItem>
        </>
    )
}

export function FormDialog(props: {
    open: boolean,
    setOpen: (value: boolean) => void,
    formTask: FormTask,
    procurementRequest: ProcurementRequest
}) {
    const queryClient = useQueryClient()
    const [form, setForm] = React.useState<Form>()

    useEffect(() => {
        if (props.open) {
            const asyncFunc = async () => {
                const questions = await apiProvider.getForm(props.formTask.form.key)
                const procurementRequest = await apiProvider.getRequest(props.procurementRequest.id)
                const answers = mapPropertiesToAnswers(procurementRequest.properties.values)
                setForm(new Form(
                        new CompoundFormElement("root",
                            [
                                questions,
                                new UpdateProcurementRequestTerminal("terminal", props.procurementRequest.id, props.formTask, queryClient)
                            ]
                        ),
                        answers
                    )
                )
            }
            asyncFunc()
        }
    }, [props.open, props.formTask.id])

    return <>
        <Dialog
            aria-labelledby="label"
            open={props.open}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    alignItems: "space-between",
                    justifyContent: "space-between",
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 1,
                    paddingBottom: 1,
                },
            }}
        >
            {form && <Fragment key={form.id}>
                <FormContainer form={form} onClose={() => props.setOpen(false)}></FormContainer>
            </Fragment>}
        </Dialog>
    </>
}
