import {Answers} from "../forms/questions"

export function mapPropertiesToAnswers(properties: {
    [key: string]: {
        value: string;
        name: string;
    }
}): Answers {
    const answers: Answers = {}

    for (const key in properties) {
        if (Object.prototype.hasOwnProperty.call(properties, key)) {
            answers[key] = properties[key].value
        }
    }

    return answers
}
