import * as React from "react"
import List from "@mui/material/List"
import ListSubheader from "@mui/material/ListSubheader"
import { Button, ListItemButton, Stack } from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import { Delete, Description } from "@mui/icons-material"
import { ProcurementRequest } from "../../api/models/procurementRequest";
import { apiProvider } from "../../api/api"

export function Documents(props: { procurementRequest: ProcurementRequest, reloadRequest: () => void }) {
    return <List
        sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
            <ListSubheader
                component="div"
                id="nested-list-subheader"
            ></ListSubheader>
        }
    >
        <DocumentInfo procurementRequest={props.procurementRequest} reloadRequest={props.reloadRequest} />
    </List>
}

function DocumentInfo(props: { procurementRequest: ProcurementRequest, reloadRequest: () => void }) {
    const documents = props.procurementRequest.documents

    return <Stack direction="column" sx={{ justifyContent: 'flex-start' }}>
        {documents.map((document) => (
            <ListItem key={document.title}>

                <ListItemButton component="a" href={document.file} target={"_blank"}>
                    <ListItemIcon>
                        <Description />
                    </ListItemIcon>
                    <ListItemText
                        primary={document.title}
                        sx={{ textDecoration: 'underline', color: 'blue' }}
                    />
                </ListItemButton>
                <Delete
                    fontSize="small"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        apiProvider.deleteDocument(document.id)
                            .then(() => {
                                props.reloadRequest();
                            })
                    }}
                />

            </ListItem>
        ))}
    </Stack>
}
