import * as React from "react"
import { Stack, TextField, Tooltip, Typography } from "@mui/material"
import { Help } from "@mui/icons-material"
import { VendorAutocomplete } from "../vendors/VendorAutocomplete"
import { useQuery } from "@tanstack/react-query"
import { apiProvider } from "../api/api"
import Autocomplete from "@mui/material/Autocomplete"
import { Category, Vendor } from "../api/models/vendors";

type SectionForTextFieldParams = { title: string, subTitle: string, footerText?: string, disabled?: boolean }


export function SectionForTextField(props: SectionForTextFieldParams & { children: React.ReactNode }) {
    return (
        <Stack direction="column" sx={{ marginBottom: 0.5 }}>
            <Stack direction={"row"} alignItems={"center"}>
                <Typography textAlign={"left"} fontWeight={"bold"} marginLeft={0.9}>{props.title}</Typography>
                <Tooltip title={props.subTitle} placement="top-start">
                    <Help
                        sx={{
                            height: "20px",
                            width: "20px",
                            marginBottom: "6px",
                            marginLeft: "2px",
                            color: "gray",
                        }}
                    ></Help>
                </Tooltip>
            </Stack>
            {props.children}
            {props.footerText && (
                <Typography
                    textAlign={"left"}
                    fontWeight={"medium"}
                    fontSize={"0.8rem"}
                    color={"text.secondary"}
                    marginLeft={0.9}
                >
                    {props.footerText}
                </Typography>
            )}
        </Stack>
    )
}

export function SimpleTextField(props: SectionForTextFieldParams & {
    value: string | undefined,
    setValue: (value: string) => void
}) {
    return <SectionForTextField {...props}>
        <TextField
            variant="outlined"
            disabled={props.disabled ?? false}
            id="outlined-start-produktvorschlag"
            sx={{
                m: 0.7,
                width: 600,
                maxWidth: { xs: 500, md: 700 },
            }}
            size={"medium"}
            value={props.value ?? ""}
            onChange={(event) => {
                props.setValue(event.target.value)
            }}
        />
    </SectionForTextField>
}

export function VendorTextField(props: SectionForTextFieldParams & {
    loading?: boolean,
    value?: Vendor | string,
    setValue: (value: Vendor | string | undefined) => void
}) {
    return <SectionForTextField {...props}>
        <VendorAutocomplete
            productAndVendor={false}
            initialValue={typeof props.value === "string" ? props.value : undefined}
            selectedItem={typeof props?.value !== "string" ? props.value : undefined}
            onUpdateValue={value => props.setValue(value)}
            fullWidth={false}
            loading={props.loading} />
    </SectionForTextField>
}

export function CategoryTextField(props: {
    value: { category: string | undefined, subCategory: string | undefined },
    setValue: (category: string | undefined, subCategory: string | undefined) => void,
    disabled?: boolean
}) {
    const { data } = useQuery(["categories"], () => apiProvider.getCategories())
    const categories = (data ?? []).map((category: Category) => category.name)
    const initialCategory = data?.find(category => category.name === props.value.category)
    const [selectedCategory, setSelectedCategory] = React.useState<Category | undefined>(initialCategory)
    const subCategories = selectedCategory?.sub_categories?.map(item => item.name) ?? []

    return (
        <div>
            <Stack direction={"column"} sx={{ marginBottom: 0.5 }}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography textAlign={"left"} fontWeight={"bold"} marginLeft={0.9}>{"Hauptkategorie"}</Typography>
                    <Tooltip title={"In welche übergeordnete Kategorie gehört der Lieferant?"} placement="top-start">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Stack>
                <Autocomplete
                    id="combo-box-demo"
                    options={categories}
                    disabled={props.disabled}
                    sx={{
                        m: 0.7,
                        width: 600,
                        maxWidth: { xs: 600, md: 800 },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={props.value.category}
                    onChange={(event, newCategory) => {
                        props.setValue(newCategory ?? undefined, props.value.subCategory)
                        setSelectedCategory(data?.find(category => category.name === newCategory))
                    }}
                />
            </Stack>
            <Stack direction={"column"} sx={{ marginBottom: 0.5 }}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography textAlign={"left"} fontWeight={"bold"}
                            marginLeft={0.9}>{"Subkategorie"}</Typography>
                        <Tooltip title={"In welche untergeordnete Kategorie gehört der Lieferant?"}
                            placement="top-start">
                            <Help
                                sx={{
                                    height: "20px",
                                    width: "20px",
                                    marginBottom: "6px",
                                    marginLeft: "2px",
                                    color: "gray",
                                }}
                            ></Help>
                        </Tooltip>
                    </Stack>
                </Stack>
                <Autocomplete
                    id="combo-box-demo"
                    options={subCategories}
                    disabled={props.disabled}
                    sx={{
                        m: 0.7,
                        width: 600,
                        maxWidth: { xs: 600, md: 800 },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={props.value.subCategory}
                    onChange={(event, newSubCategory) => {
                        props.setValue(props.value.category, newSubCategory ?? undefined)
                    }}
                />
            </Stack>
        </div>
    )
}

export function ProductCategoryTextField(props: {
    value: { category: string | undefined, subCategory: string | undefined },
    setValue: (category: string | undefined, subCategory: string | undefined) => void,
    disabled?: boolean
}) {
    const { data } = useQuery(["categories"], () => apiProvider.getCategories())
    const categories = (data ?? []).map((category: Category) => category.name)
    const initialCategory = data?.find(category => category.name === props.value.category)
    const [selectedCategory, setSelectedCategory] = React.useState<Category | undefined>(initialCategory)
    const subCategories = selectedCategory?.sub_categories?.map(item => item.name) ?? []

    return (
        <div>
            <Stack direction={"column"} sx={{ marginBottom: 0.5 }}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography textAlign={"left"} fontWeight={"bold"} marginLeft={0.9}>{"Hauptkategorie"}</Typography>
                    <Tooltip title={"In welche übergeordnete Kategorie gehört das Produkt?"} placement="top-start">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Stack>
                <Autocomplete
                    id="combo-box-demo"
                    options={categories}
                    disabled={props.disabled}
                    sx={{
                        m: 0.7,
                        width: 600,
                        maxWidth: { xs: 600, md: 800 },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={props.value.category}
                    onChange={(event, newCategory) => {
                        props.setValue(newCategory ?? undefined, props.value.subCategory)
                        setSelectedCategory(data?.find(category => category.name === newCategory))
                    }}
                />
            </Stack>
            <Stack direction={"column"} sx={{ marginBottom: 0.5 }}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography textAlign={"left"} fontWeight={"bold"}
                            marginLeft={0.9}>{"Subkategorie"}</Typography>
                        <Tooltip title={"In welche untergeordnete Kategorie gehört das Produkt?"}
                            placement="top-start">
                            <Help
                                sx={{
                                    height: "20px",
                                    width: "20px",
                                    marginBottom: "6px",
                                    marginLeft: "2px",
                                    color: "gray",
                                }}
                            ></Help>
                        </Tooltip>
                    </Stack>
                </Stack>
                <Autocomplete
                    id="combo-box-demo"
                    options={subCategories}
                    disabled={props.disabled}
                    sx={{
                        m: 0.7,
                        width: 600,
                        maxWidth: { xs: 600, md: 800 },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    value={props.value.subCategory}
                    onChange={(event, newSubCategory) => {
                        props.setValue(props.value.category, newSubCategory ?? undefined)
                    }}
                />
            </Stack>
        </div>
    )
}
