/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect} from "react"
import Grid from "@mui/material/Grid"
import {TextField, Tooltip, Typography,} from "@mui/material"
import {Help,} from "@mui/icons-material"
import {FormQuestion} from "../questions"
import {FormElementProps} from "../FormContent"
import {useSavableManyState} from "../util"

export function TextInput(props: { description?: string } & React.ComponentProps<typeof TextField>) {
    return (
        <Grid container flexDirection={"column"} justifyContent={"center"}
              sx={props.fullWidth ? {} : {
                  width: 600,
                  maxWidth: {xs: 400, md: 600},
              }}>
            <Typography textAlign={"left"} width={"97%"} fontWeight={"bold"} marginBottom={0.5}
                        marginLeft={1}>{props.label}</Typography>
            {(props.description?.length ?? 0) > 0 ? <Typography textAlign={"left"} width={"100%"} fontSize={12}
                                                                marginBottom={0.5}>{props.description}</Typography> : null}

            <TextField
                variant="outlined"
                id="outlined-start-produktvorschlag"
                sx={props.fullWidth ? {} : {
                    m: 0.7,
                    width: 600,
                    maxWidth: {xs: 400, md: 600},
                }}
                size={"medium"}
                {...{...props, label: undefined}}
            />
        </Grid>
    )
}

export default function FormQuestionComponent({
                                                  formElement,
                                                  hasValidInput,
                                              }: FormElementProps<FormQuestion>) {
    const [inputs, setInputs] = useSavableManyState(formElement)

    function checkInput() {
        hasValidInput(
            formElement.formFields
                .filter((item) => item.mandatory)
                .every((item) => inputs[item.property] !== "")
        )
    }

    function saveInputs(property: string, value: string) {
        setInputs({...inputs, [property]: value})
        checkInput()
    }

    useEffect(() => {
        checkInput()
    })

    return (
        <div>
            <Grid container flexDirection={"column"} paddingTop={2} paddingBottom={2}>
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: "100%"}}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        {formElement.title}
                    </Typography>
                    <Tooltip title={formElement.info} placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Grid>

                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    paddingBottom={2}
                >
                    {formElement.formFields.map(({title, description, property}) => {
                        return (
                            <TextInput
                                label={title}
                                description={description}
                                onChange={(event) => {
                                    saveInputs(property, event.target.value)
                                }}
                                defaultValue={inputs[property]}
                            />
                        )
                    })}

                </Grid>
            </Grid>
        </div>
    )
}
