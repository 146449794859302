/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"
import Grid from "@mui/material/Grid"
import {Box} from "@mui/material"
import Exiticon from "./exiticon"
import {staticFilesURL} from "../../api/backendApiProvider"

interface LogobarProps {
    onClose: (params: any) => any;
}

export default function Logobar({onClose}: LogobarProps) {
    return (
        <div>
            <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginBottom={1}
            >
                <Box width={20} height={20} color="#0000FF">
                    <Exiticon onClose={onClose}></Exiticon>
                </Box>
                <Box
                    component="img"
                    sx={{
                        maxWidth: {xs: 130, md: 260},
                        maxHeight: {xs: 20, md: 40},
                        width: "auto",
                        height: "auto",
                    }}
                    alt="Logo"
                    src={`${staticFilesURL}images/logo.png`}
                />
                <Box width={20} height={20}></Box>
            </Grid>
        </div>
    )
}
