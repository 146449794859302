import React, {useEffect} from "react"
import Grid from "@mui/material/Grid"
import {Tooltip, Typography} from "@mui/material"
import Mediacard from "./mediacard"
import {TimeQuestion} from "../questions"
import {Help} from "@mui/icons-material"
import {FormElementProps} from "../FormContent"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import dayjs, {Dayjs} from "dayjs"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {useCustomSavableState} from "../util"
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker"

export default function TimeQuestionComponent({
                                                  formElement,
                                                  hasValidInput,
                                              }: FormElementProps<TimeQuestion>) {
    const [value, setValue] = useCustomSavableState<{ selected: string | undefined, picker: Dayjs | undefined }, TimeQuestion>(formElement, "selected",
        (question, form) => {
            const dueDate = question.getAnswer(form, "due_date")
            return {
                selected: question.getAnswer(form, "urgency"),
                picker: dueDate ? dayjs(dueDate) : undefined
            }
        },
        (question, form, value) => {
            question.save(form, "urgency", value.selected)
            question.save(form, "due_date", value.picker?.toISOString())
        })

    useEffect(() => {
        hasValidInput(value.selected !== undefined && (value.selected !== "critical" || value.picker !== undefined))
    })

    function update(option: string | undefined, pickerValue: Dayjs | undefined) {
        setValue({selected: option, picker: pickerValue})
        hasValidInput(option !== undefined && (option !== "critical" || pickerValue !== undefined))
    }

    const today = dayjs()


    return (
        <div>
            <Grid
                container
                columns={12}

                flexDirection={"column"}
                paddingTop={2}
                sx={{width: "100%"}}
            >
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: "100%"}}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        {formElement.title}
                    </Typography>
                    <Tooltip title={formElement.info} placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Grid>

                <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"center"}
                    paddingBottom={2}
                    height={"100%"}
                    width={"100%"}
                >
                    {formElement.options.map((option) => {
                        return (
                            <div>
                                <Mediacard
                                    heading={option.title}
                                    content={option.description}
                                    image={option.image}
                                    selected={value.selected === option.property_value}
                                    onSelected={() => update(option.property_value, value.picker)}
                                />

                                {value.selected === "critical" && option.title === "Kritisch" ? (
                                    <Grid sx={{m: 1, width: "80%"}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Stack spacing={3} paddingLeft={2}>
                                                <DesktopDatePicker
                                                    inputFormat="DD/MM/YYYY"
                                                    value={value.picker ?? null}
                                                    onChange={(newValue: Dayjs | null) => {
                                                        update(value.selected, newValue ?? undefined)
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                    />}
                                                    minDate={today}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Grid>
                                ) : null}
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    )
}
