import { useState, createContext } from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    Stack, Typography
} from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { theme } from "../theme"
import List from "@mui/material/List"
import ListSubheader from "@mui/material/ListSubheader"
import * as React from "react"
import { useMemo } from "react"
import { FormTaskListItem } from "./sidebar/FormTaskListItem"
import { TicketParamList } from "./sidebar/TicketParams"
import { DefaultPropertiesItems, GroupPropertiesItems } from "./sidebar/DetailProperties"
import { TaskList } from "../api/models/tasks";
import { Documents } from "./sidebar/Documents"
import ExpandMore from "@mui/icons-material/ExpandMore"
import {
    ProcurementRequest,
    ProcurementRequestUpdate,
    PropertyGroup,
    PropertyValue,
    PropertyValueUpdate
} from "../api/models/procurementRequest";
import { useTranslation } from "react-i18next"
import { Product, Vendor } from "../api/models/vendors"
import { useQuery } from "@tanstack/react-query"
import { apiProvider } from "../api/api"
import { PropertyConfigContract } from "../api/apiContracts"
import { User } from "../api/models/users"
import { Edit } from "@mui/icons-material";

export const ProcurementRequestUpdateContext = createContext<{
    procurementRequest?: ProcurementRequest,
    editMode: boolean,
    procurementRequestUpdate: ProcurementRequestUpdate,
    handleFieldChange: (key: string, value: string | Vendor | Product | PropertyValueUpdate | User | undefined) => void,
    propertyConfigs?: PropertyConfigContract[]
}
>({
    editMode: false,
    procurementRequestUpdate: {
        properties: {
            values: {},
            groups: []
        }
    },
    handleFieldChange: () => {
    }
})

export function AllPropertyDetails(props: {
    procurementRequest: ProcurementRequest,
    saveRequestUpdate: (ProcurementRequestUpdate: ProcurementRequestUpdate) => void,
    loading: boolean,
    taskList?: TaskList,
    openSideBar: boolean,
    handleCloseSideBar: () => void,
    reloadRequest: () => void
}) {

    const [editMode, setEditMode] = useState(false)

    return (<Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={props.openSideBar}
        onClose={() => {
            setEditMode(false)
            props.handleCloseSideBar()
        }}
    >
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <HighlightOffIcon
                onClick={() => {
                    setEditMode(false)
                    props.handleCloseSideBar()
                }
                }
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                    height: 30,
                    width: 30,
                    color: theme.palette.primary.main,
                    marginLeft: 4,
                    marginTop: 2

                }}
            />
        </Stack>
        <DialogContent style={{ position: 'relative' }}>
            <DetailsList procurementRequest={props.procurementRequest}
                taskList={props.taskList}
                editMode={editMode}
                setEditMode={setEditMode}
                saveRequestUpdate={props.saveRequestUpdate}
                reloadRequest={props.reloadRequest}
            />
            {props.loading &&
                <Stack
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(255, 255, 255, 1)' // Add semi-transparent background
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <CircularProgress />
                </Stack>
            }
        </DialogContent>
    </Dialog>
    )
}

export function DetailsList(props: {
    procurementRequest: ProcurementRequest
    taskList: TaskList | undefined
    propertyOrder?: number
    editMode?: boolean
    setEditMode?: (editMode: boolean) => void
    saveRequestUpdate?: (ProcurementRequestUpdate: ProcurementRequestUpdate) => void
    reloadRequest: () => void
}) {
    const { t } = useTranslation()

    const defaultProperties = useMemo(() => {
        return props.procurementRequest?.getPropertiesByOrder(props.propertyOrder)
    }, [props.procurementRequest])

    const [procurementRequestUpdate, setProcurementRequestUpdate] = useState<ProcurementRequestUpdate>({
        properties: {
            values: {},
            groups: []
        }
    })

    const handleFieldChange = (
        key: string,
        value: string | Vendor | Product | PropertyValueUpdate | User | undefined
    ) => {
        let newProcurementRequestUpdate: {
            title?: string;
            description?: string;
            vendor?: Vendor | null;
            product?: Product | null;
            coordinator?: User;
            properties: {
                values: { [key: string]: PropertyValue }
                groups: PropertyGroup[]
            }
        } = { ...procurementRequestUpdate };

        switch (key) {
            case "title":
                if (typeof value === "string") {
                    newProcurementRequestUpdate.title = value;
                }
                break;
            case "description":
                if (typeof value === "string") {
                    newProcurementRequestUpdate.description = value;
                }
                break;
            case "vendor":
                if (value instanceof Vendor) {
                    newProcurementRequestUpdate.vendor = value;
                    newProcurementRequestUpdate.product = null;
                }
                if (value === undefined) {
                    newProcurementRequestUpdate.vendor = null;
                    newProcurementRequestUpdate.product = null;
                }
                break;
            case "product":
                if (value instanceof Product) {
                    newProcurementRequestUpdate.product = value;
                }
                if (value === undefined) {
                    newProcurementRequestUpdate.product = null;
                }
                break;
            case "coordinator":
                if (value instanceof User) {
                    newProcurementRequestUpdate.coordinator = value;
                }
                break;
            case "property":
                if (
                    typeof value === "object" &&
                    value !== null &&
                    "key" in value &&
                    "value" in value &&
                    typeof value.key === "string"
                ) {
                    if (newProcurementRequestUpdate.properties.values[value.key] === undefined) {
                        newProcurementRequestUpdate.properties.values[value.key] = {
                            name: props.procurementRequest?.properties.values[value.key].name,
                            value: value.value,
                        };
                    } else {
                        newProcurementRequestUpdate.properties.values[value.key].value = value.value;
                    }
                }
                break;
            default:
                break;
        }
        setProcurementRequestUpdate(newProcurementRequestUpdate)
    };

    const { data: propertyConfigs } = useQuery(
        ["property_configs"],
        async () => await apiProvider.getPropertyConfigs()
    )

    const handleCancel = () => {
        setProcurementRequestUpdate({
            properties: {
                values: {},
                groups: []
            }
        })
        props.setEditMode?.(false)
    }

    const handleSaveEdit = () => {
        props.setEditMode?.(false)
        props.saveRequestUpdate?.(procurementRequestUpdate)
    }

    return <ProcurementRequestUpdateContext.Provider
        value={{
            procurementRequest: props.procurementRequest,
            editMode: props.editMode ?? false,
            // merge current procurement request with the update (update should only contain changed values)
            procurementRequestUpdate: {
                ...props.procurementRequest,
                ...procurementRequestUpdate,
                properties: {
                    groups: props.procurementRequest.properties.groups,
                    values: {
                        ...props.procurementRequest.properties.values,
                        ...procurementRequestUpdate.properties.values,
                    },
                },
            },
            // handle changes to the procurement request update
            handleFieldChange,
            propertyConfigs: propertyConfigs
        }}
    >
        <Stack>
            {props.editMode !== undefined && <Stack direction={"row"} alignItems={"center"} sx={{ padding: 2 }}>
                {
                    props.editMode ?
                        <Stack direction={"row"} alignItems={"center"}>
                            <Button variant="outlined" onClick={handleCancel} sx={{ borderRadius: 3 }}>
                                {t("general.actions.cancel")}
                            </Button>
                            <Button variant="contained" onClick={handleSaveEdit}
                                sx={{ marginLeft: 2, borderRadius: 3 }}>
                                {t("general.actions.save")}
                            </Button>
                        </Stack>
                        :
                        <Button
                            variant="contained"
                            size={"small"}
                            sx={{ borderRadius: 2 }}
                            onClick={() => props.setEditMode?.(true)}
                        >
                            <Edit sx={{ height: 20, width: 20, marginRight: 1 }}></Edit>
                            {t("general.actions.edit")}
                        </Button>
                }

            </Stack>
            }
            <Accordion disableGutters={true} defaultExpanded={true} sx={{ marginBottom: 2 }} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ flexDirection: "row-reverse" }}
                > <Typography
                    sx={{ textAlign: "center", fontWeight: "bold", color: "black", marginBottom: 0.5 }}>
                        Allgemeine Ticket Infos
                    </Typography></AccordionSummary>
                <AccordionDetails>
                    <Divider />
                    <TicketParamList
                        procurementRequest={props.procurementRequest}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters={true} defaultExpanded={false} sx={{ marginBottom: 2 }} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ flexDirection: "row-reverse" }}

                >
                    <Typography
                        sx={{ textAlign: "center", fontWeight: "bold", color: "black", marginBottom: 0.5 }}>
                        {t("request_details.details_heading")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Divider />
                    <List
                        sx={{ width: "100%", bgcolor: "background.paper" }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                            ></ListSubheader>
                        }
                    >
                        <List component="div" disablePadding>
                            <DefaultPropertiesItems items={defaultProperties?.default} />
                            {defaultProperties?.groups?.map((group, index) => {
                                return <GroupPropertiesItems group={group} key={index} items={group.items} />
                            })}
                        </List>
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters={true} defaultExpanded={false} sx={{ marginBottom: 2 }} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ flexDirection: "row-reverse" }}
                >
                    <Typography
                        sx={{ textAlign: "center", fontWeight: "bold", color: "black", marginBottom: 0.5 }}>
                        {t("request_details.forms_heading")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Divider></Divider>
                    <List
                        sx={{ width: "100%", bgcolor: "background.paper" }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                            ></ListSubheader>
                        }
                    >
                        {props.taskList && props.taskList.getFormTasks()
                            .map(task => {
                                return <React.Fragment key={task.id}>
                                    <FormTaskListItem
                                        procurementRequest={props.procurementRequest}
                                        task={task}
                                    />
                                </React.Fragment>
                            })}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters={true} defaultExpanded={false} sx={{ marginBottom: 2 }} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ flexDirection: "row-reverse" }}
                >
                    <Typography
                        sx={{ textAlign: "center", fontWeight: "bold", color: "black", marginBottom: 0.5 }}>
                        {t("request_details.documents_heading")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Divider></Divider>
                    <Documents procurementRequest={props.procurementRequest} reloadRequest={props.reloadRequest} />
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        size="medium"
                    >
                        {t("request_details.upload_documents_button")}
                        <input hidden accept="pdf/*" multiple type="file"
                            onChange={async (event) => {
                                const response = await apiProvider.postDocument(event.target.files, props.procurementRequest.id)
                                props.reloadRequest();
                            }} />
                    </Button>
                </AccordionDetails>
            </Accordion>
        </Stack>
    </ProcurementRequestUpdateContext.Provider>
}
