import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Settings from "@mui/icons-material/Settings"
import Logout from "@mui/icons-material/Logout"
import { useAuth } from "../auth/auth"
import { useNavigate } from "react-router-dom"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import { Label, Password, Person } from "@mui/icons-material"
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack, TextField, Typography } from "@mui/material"
import { apiProvider } from "../api/api"
import userEvent from "@testing-library/user-event"
import { useTranslation } from "react-i18next"

export default function AccountMenuItem(props: { open: boolean }) {
    let auth = useAuth()
    let navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [openChangePassword, setOpenChangePassword] = React.useState(false)

    const ChangePasswordDialog = (props: { open: boolean, onClose: () => void }) => {

        const { t } = useTranslation()

        const [oldPassword, setOldPassword] = React.useState("")
        const [newPassword, setNewPassword] = React.useState("")
        const [confirmPassword, setConfirmPassword] = React.useState("")

        const [error, setError] = React.useState("")
        const [success, setSuccess] = React.useState("")
        const [loading, setLoading] = React.useState(false)

        const onSave = () => {
            setLoading(true)
            apiProvider.changePassword(auth?.user?.id ?? 0, oldPassword, newPassword)
                .then((result) => {
                    setSuccess(t("user_menu.change_password_dialog.success") as string)
                    setError("")
                })
                .catch((error) => {
                    setSuccess("")
                    setError(t("user_menu.change_password_dialog.error") as string)
                })
                .finally(() => {
                    setLoading(false)
                })
        }

        return (
            <Dialog open={props.open} onClose={props.onClose}>
                {
                    !success &&   <DialogTitle>{t("user_menu.change_password_dialog.title")}</DialogTitle>
                }
                <DialogContent sx={{ paddingX: 12 }}>
                    {
                        loading ? <CircularProgress /> :
                        success ?
                            <Typography sx={{ color: "green" }}>{success}</Typography>
                            :
                            <Stack>
                                <TextField
                                    type="password"
                                    sx={{ marginY: 1, minWidth: "300px" }}
                                    label={t("user_menu.change_password_dialog.old_password")}
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                                <TextField
                                    type="password"
                                    sx={{ marginY: 1, minWidth: "300px" }}
                                    label={t("user_menu.change_password_dialog.new_password")}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <TextField
                                    type="password"
                                    sx={{ marginY: 1, minWidth: "300px" }}
                                    label={t("user_menu.change_password_dialog.confirm_password")}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                {
                                    newPassword !== confirmPassword &&
                                    <Typography sx={{ color: "red" }}>{t("user_menu.change_password_dialog.pw_not_match")}</Typography>
                                }
                                {
                                    error &&
                                    <Typography sx={{ color: "red" }}>{error}</Typography>
                                }
                            </Stack>

                    }
                </DialogContent>
                {
                    !success &&
                    <DialogActions>
                        <Button onClick={props.onClose}>{t("user_menu.change_password_dialog.cancel")}</Button>
                        <Button disabled={newPassword !== confirmPassword} onClick={onSave}>{t("user_menu.change_password_dialog.save")}</Button>
                    </DialogActions>
                }

            </Dialog>
        )
    }

    return (
        <React.Fragment>
            <ListItem disablePadding sx={{ display: "block" }} onClick={handleClick}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: props.open ? "initial" : "center",
                        px: 2.5,
                    }}
                >
                    <Avatar
                        sx={{
                            minWidth: 0,
                            mr: props.open ? 3 : "auto",
                            justifyContent: "center",
                        }}
                    >
                        {auth.user && auth.user.username.charAt(0).toUpperCase()}
                    </Avatar>
                    <Typography noWrap sx={{ opacity: props.open ? 1 : 0, width: 80 }}>
                        {auth.user?.name}
                    </Typography>
                </ListItemButton>
            </ListItem>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    onClick={() => setOpenChangePassword(true)}
                >
                    <ListItemIcon>
                        <Password fontSize="small" />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem
                    onClick={async () => {
                        let result = await auth.signOut()
                        console.log(`Logout ${result}`)
                        navigate("/")
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <ChangePasswordDialog open={openChangePassword} onClose={() => setOpenChangePassword(false)} />
        </React.Fragment>
    )
}
