import * as React from "react"
import {CSSObject, styled, Theme, useTheme} from "@mui/material/styles"
import Box from "@mui/material/Box"
import MuiDrawer from "@mui/material/Drawer"
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar"
import List from "@mui/material/List"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {Add, Business, CloseFullscreen, Group, ViewList,} from "@mui/icons-material"
import {Link, NavigateOptions, Outlet} from "react-router-dom"
import AccountMenuItem from "./account/AccountMenuItem"
import {Grid} from "@mui/material"
import {useTranslation} from "react-i18next"
import {useAuth} from "./auth/auth"
import {useSessionStorage} from "react-use";

const drawerWidth = 220
const appBarHeight = 0

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    minHeight: 1,
    backgroundColor: "transparent"
    //   ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({theme, open}) => ({
    height: appBarHeight,
    backgroundColor: "transparent",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        height: appBarHeight,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}))

function DrawerItem(props: {
    open: boolean;
    icon: JSX.Element;
    text: string;
    link: string;
    navigationOptions?: NavigateOptions;
    textColor: string
}) {
    return (
        <ListItem
            disablePadding
            sx={{display: "block"}}
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: props.open ? "initial" : "center",
                    px: 2.5,
                }}
                component={Link}
                to={props.link}
                {...props.navigationOptions}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: props.open ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {props.icon}
                </ListItemIcon>
                <ListItemText
                    primary={props.text}
                    sx={{opacity: props.open ? 1 : 0}}
                    primaryTypographyProps={{
                        fontWeight: "bold",
                        color: props.textColor,
                    }}
                />
            </ListItemButton>
        </ListItem>
    )
}

export default function LoggedIn() {
    const theme = useTheme()
    const {t} = useTranslation()
    const auth = useAuth()

    const [open, setOpen] = useSessionStorage("ui_menu_open", true)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (

        <Box
            sx={{
                display: "flex",
                position: "absolute",
                bottom: 0,
                top: 0,
                width: "100%",
                bgcolor: "white"
            }}
        >
            <CssBaseline/>
            <AppBar position="fixed" open={open}>
                <Grid
                    container
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginLeft={3}
                    bgcolor={"transparent"}
                    height={"1px"}
                >
                    <Box sx={{bgcolor: "white", width: 25}}>
                        <Box sx={{color: "grey", width: 20}}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    ...(open && {display: "none"}),
                                }}
                            >
                                <MenuIcon color="inherit"/>
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <CloseFullscreen/>
                        ) : (
                            <CloseFullscreen/>
                        )}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <DrawerItem
                        key="requests"
                        open={open}
                        text={t("menu.request_list_title")}
                        link="/requests"
                        icon={<ViewList/>}
                        textColor={"theme.palette.primary.main"}
                    />
                    {auth.user?.hasChangeUserPermission() && <DrawerItem
                        key="users"
                        open={open}
                        text={t("menu.user_list_title")}
                        link="/users"
                        icon={<Group/>}
                        textColor={"theme.palette.primary.main"}

                    />}
                    <DrawerItem
                        key="vendors"
                        open={open}
                        text={t("menu.vendor_list_title")}
                        link="/vendors"
                        icon={<Business/>}
                        textColor={"theme.palette.primary.main"}

                    />
                </List>
                <Divider/>
                <List>
                    <DrawerItem
                        key="create"
                        open={open}
                        icon={<Add sx={{color: "#0000FF"}}/>}
                        text={t("menu.create_title")}
                        link="/search"
                        textColor={"#0000FF"}
                    />
                </List>
                <Divider/>

                <List style={{position: "absolute", bottom: "0", width: "100%"}}>
                    <AccountMenuItem open={open}/>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: "white"
                }}
            >
                <DrawerHeader/>
                <Box
                    sx={{
                        width: "100%",
                        height: `calc(100% - ${appBarHeight}px)`,
                        backgroundColor: "white"
                    }}
                >
                    <Outlet/>
                </Box>
            </Box>
        </Box>
    )
}
