import React, { useState } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import { debounce, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { Product, Vendor } from "../api/models/vendors";
import { backendApiProvider } from "../api/backendApiProvider";
import { ProcurementRequestUpdateContext } from "../requests/AllPropertyDetails";


export function VendorAutocompleteTextField() {

    const { procurementRequest, editMode, procurementRequestUpdate, handleFieldChange, propertyConfigs } = React.useContext(ProcurementRequestUpdateContext)

    const [open, setOpen] = useState(false)
    const [items, setItems] = useState<Vendor[]>([])
    const [ selectedVendor, setSelectedVendor ] = useState<Vendor | undefined>(procurementRequestUpdate.vendor ?? procurementRequest?.vendor ?? undefined) 

    const [inputValue, setInputValue] = useState(procurementRequestUpdate.vendor?.name ?? procurementRequest?.vendor?.name ?? "")

    // Call update function for parent component
    function onChange(newVendor: string | Vendor | null) {
        if (newVendor === null) {
            handleFieldChange("vendor", undefined)
        } else if (typeof newVendor === "object") {
            handleFieldChange("vendor", newVendor)
        }
    }

    const autoCompleteFunction = React.useMemo(
        () =>
            debounce(
                async (
                    request: { input: string },
                    callback: (results?: readonly Vendor[]) => void,
                ) => {
                    const result = await backendApiProvider.autocomplete(request.input)
                    callback(result)
                },
                0,
            ),
        [],
    );

    React.useEffect(() => {
        let active = true
        if (inputValue === "") {
            setItems([])
            return undefined
        }
        
        autoCompleteFunction({ input: inputValue }, (results) => {
            if (active) {
                let newOptions: Vendor[] = []

                if (results) {
                    newOptions = results.map((r) => r)

                    setItems(newOptions)
                }

                return () => {
                    active = false
                };
            }
            });
        }, [selectedVendor, inputValue, fetch])

        return <Autocomplete
            freeSolo
            id="vendor-autocomplete"
            includeInputInList
            filterSelectedOptions
            open={open && (inputValue?.length ?? 0) >= 3}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={items}
            value={selectedVendor?.name ?? null}
            onChange={(event, value) => onChange(value)}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
                setInputValue(value)
                if (reason !== "reset") {
                    onChange(value)
                }
            }}
            filterOptions={(options) => options}
            getOptionLabel={(option) => {
                if (typeof option === "string") {
                    return option
                } else if (option !== undefined) {
                    return option.name
                } else {
                    return ""
                }
            }}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    sx={{ minWidth: 300 }}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <img src={option.iconUrl} loading="lazy" style={{ maxWidth: 24, maxHeight: 24 }} alt="" />
                        <Stack direction="column">
                            <Typography variant="body1">{option.name}</Typography>
                        </Stack>
                    </Stack>
                </li>
            )}
        />
    }
