import {Answers, FormElement} from "./questions"
import {apiProvider} from "../api/api"
import {QueryClient} from "@tanstack/react-query"
import {QueryKeys} from "../api/queryKeys"
import jsonLogic from "json-logic-js"
import {NavigateFunction} from "react-router-dom"
import {FormTask} from "../api/models/tasks";
import {ProcurementRequest} from "../api/models/procurementRequest";

export abstract class FormTerminal implements FormElement {
    id: string
    condition: (answers: Answers) => boolean

    closeAction?: (answers: Answers, navigate: NavigateFunction) => void

    protected constructor(id: string, condition?: (answers: Answers) => boolean, closeAction?: (answers: Answers, navigate: NavigateFunction) => void) {
        this.id = id
        this.condition = condition ?? (() => true)
        this.closeAction = closeAction
    }
}

export class ExternalLinkTerminal extends FormTerminal {
    link: string
    description: string

    constructor(json: any) {
        super(json.id, (answers) => jsonLogic.apply(json.condition ?? true, answers))
        this.link = json.link
        this.description = json.description
    }
}

export abstract class SendProcurementRequestTerminal extends FormTerminal {
    protected constructor(id: string, condition?: (answers: Answers) => boolean, closeAction?: (answers: Answers, navigate: NavigateFunction) => void) {
        super(id, condition, closeAction)
    }

    async load(answers: Answers, queryClient: QueryClient): Promise<void> {
        console.log(`Answers: ${JSON.stringify(answers)}`)
        // TODO handle response
        // TODO filter all parameters that are not assigned to the questions

        const properties: { key: string, value: string }[] = Object.keys(answers)
            .map(property_key => {
                return {key: property_key, value: answers[property_key]}
            })

        const vendorId = answers["vendorId"]
        if (vendorId !== undefined) {
            properties.push({key: "vendor_id", value: vendorId})
        }
        const description = answers["additional_info"] ?? ""
        const documentIds = answers["document_ids"]?.split(",")?.map(id => Number.parseInt(id))
        await this.sendRequest(queryClient, {
            properties: properties,
            vendor: vendorId,
            document_ids: documentIds,
            description: description
        })
    }

    abstract sendRequest(queryClient: QueryClient, data: {
        properties: { key: string, value: string }[],
        vendor: string | undefined,
        document_ids: number[] | undefined,
        description: string
    }): Promise<ProcurementRequest>
}

export class CreateProcurementRequestTerminal extends SendProcurementRequestTerminal {
    newProcurementRequestId?: number

    constructor(json: any) {
        super(json.id, () => true, (answers, navigate) => {
            this.newProcurementRequestId ? navigate(`/requests/${this.newProcurementRequestId}`) : navigate("/requests")
        })
    }

    async sendRequest(queryClient: QueryClient, data: {
        properties: { key: string, value: string }[],
        vendor: string | undefined,
        document_ids: number[] | undefined,
        description: string
    }): Promise<ProcurementRequest> {
        const result = await apiProvider.createRequest(data)
        await queryClient.invalidateQueries(QueryKeys.getProcurementRequestList())
        this.newProcurementRequestId = result.id
        return result
    }
}

export class UpdateProcurementRequestTerminal extends SendProcurementRequestTerminal {
    procurementRequestId: number
    formTask: FormTask

    constructor(
        id: string,
        procurementRequestId: number,
        formTask: FormTask,
        queryClient: QueryClient,
        condition?: (answers: Answers) => boolean
    ) {
        super(id, condition)
        this.procurementRequestId = procurementRequestId
        this.formTask = formTask
    }

    async sendRequest(queryClient: QueryClient, data: {
        properties: { key: string, value: string }[],
        vendor: string | undefined,
        document_ids: number[] | undefined
    }): Promise<ProcurementRequest> {
        const procurementRequest = await apiProvider.updateRequest(this.procurementRequestId, data)

        await apiProvider.setTaskState(this.formTask.id, "completed")
        await queryClient.invalidateQueries(["procurement-request", procurementRequest.id, "tasks"])

        return procurementRequest
    }
}
