/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import {Box, Button, ListItemButton, Stack, Tooltip, Typography,} from "@mui/material"
import {AutoStories, Description, Help, OpenInNew,} from "@mui/icons-material"
import {VendorInfoQuestion} from "../questions"
import {FormElementProps} from "../FormContent"
import {apiProvider} from "../../api/api"
import {TextInput} from "./formQuestionComponent"
import {useCustomSavableState, useSavableState} from "../util"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {Product, Vendor} from "../../api/models/vendors";
import {Document} from "../../api/models/procurementRequest";
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import UploadFromElement from "./UploadFromElement"

function RequestInfoFields({formElement, hasValidInput}: FormElementProps<VendorInfoQuestion>) {

    const { t } = useTranslation()
    let location = useLocation()

    // TODO: get fixed vendor and product from formElement
    const [selectedVendorId, setSelectedVendorId] = useCustomSavableState<{
        vendorId: string,
        productId?: string
    } | undefined, VendorInfoQuestion>(formElement, "vendorId",
        (question, form) => {
            const productId = question.getAnswer(form, "productId")
            const vendorId = question.getAnswer(form, "vendorId")
            if (vendorId !== undefined) {
                return {vendorId: vendorId, productId: productId}
            } else {
                return undefined
            }
        },
        (question, form, value) => {
            question.save(form, "vendorId", value?.vendorId)
            question.save(form, "productId", value?.productId)
        }
    )

    const [selectedVendor, setSelectedVendor] = useState<{ vendor: Vendor, product?: Product } | undefined>(undefined)

    useEffect(() => {
        if (selectedVendorId !== undefined) {
            if (selectedVendorId?.vendorId !== selectedVendor?.vendor?.id) {
                apiProvider.getVendor(selectedVendorId.vendorId).then(vendor => {
                    const product = selectedVendorId.productId ? vendor.getProductById(selectedVendorId.productId) : undefined;
                    setSelectedVendor({
                        vendor: vendor,
                        product: product
                    })
                    setVendorInputValue(vendor.name)
                    setProductInputValue(product?.name)
                })
            }
        } else {
            setSelectedVendor(undefined)
        }
    }, [selectedVendorId])

    const [vendorInputValue, setVendorInputValue] = useSavableState(formElement, "vendor_name")
    const [productInputValue, setProductInputValue] = useSavableState(formElement, "product_name")
    const [quantityInputValue, setQuantityInputValue] = useSavableState(formElement, "license_amount")
    const [documents, setDocuments] = useSavableState(formElement, "document_ids")


    function checkInput(vendorInputValue: string | undefined) {
        hasValidInput((vendorInputValue?.length ?? 0) > 0)
    }

    useEffect(() => {
        checkInput(vendorInputValue)
    }, [selectedVendorId, vendorInputValue])

    return <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            paddingBottom={2}
        >
        {selectedVendor?.vendor && (
            <Stack direction={"row"} alignItems={"start"} justifyContent={"space-between"}                
            sx={{
              m: 0.7,
              width: 600,
              maxWidth: {xs: 400, md: 600},
          }}>
              <Stack direction={"column"}>
                <Typography
                fontWeight={"bold"} 
                marginBottom={0.5}
                marginLeft={1}  
                    sx={{
                        fontSize: {xs: 16, md: 20},
                        align: "center",
                        marginRight: 0.7,
                    }}>
                        {t("request_form.vendor")}
                </Typography>                
                <Typography 
                    fontSize={24}
                    sx={{
                        marginLeft: 0.7, 
                        maxWidth: {xs: 400, md: 600},
                    }}> 
                    {selectedVendor?.vendor.name}
                </Typography>
            </Stack>
            <Button
                    component={Link}
                    to={`/create?`}
                    state = {location.state}
                >
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                        <ListItemText
                            primary={t("request_form.vendor_preselected_newrequest")}
                        />
                    </Stack>
                </Button>
            </Stack>
            )}
        <TextInput
            label={t("request_form.product")}
            value={productInputValue}
            InputLabelProps={{shrink: !!productInputValue}}
            onChange={(event) => {
                setProductInputValue(event.target.value)
            }}
        />

        <TextInput
            label={t("request_form.number_of_licenses")}
            value={quantityInputValue}
            onChange={(event) => setQuantityInputValue(event.target.value)}/>

        <UploadFromElement documents={documents} setDocuments={setDocuments}/>
    </Grid>
}


// Component for procurement request form with preselected vendor (Vendor Info Fixed)
export default function VendorInfoFixedComponent(props: FormElementProps<VendorInfoQuestion>) {
    return (
        <div>
            <Grid container flexDirection={"column"} paddingTop={2} paddingBottom={2}>
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: "100%"}}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        {props.formElement.title}
                    </Typography>
                    <Tooltip title={props.formElement.info} placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Grid>

                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    paddingBottom={2}>

                    <RequestInfoFields {...props}/>
                </Grid>
            </Grid>
        </div>
    )
}
