import * as React from 'react'
import {useTheme, styled} from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Autocomplete, {
    autocompleteClasses,
} from '@mui/material/Autocomplete'
import ButtonBase from '@mui/material/ButtonBase'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import {useQuery} from "@tanstack/react-query"
import {apiProvider} from "../api/api"
import {UserItem} from "./UserListItem"
import {useEffect} from "react"
import {User} from "../api/models/users";

interface PopperComponentProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}

const StyledAutocompletePopper = styled('div')(({theme}) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${
                theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
            }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                {
                    backgroundColor: theme.palette.action.hover,
                },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}))

function PopperComponent(props: PopperComponentProps) {
    const {disablePortal, anchorEl, open, ...other} = props
    return <StyledAutocompletePopper {...other} />
}

const StyledPopper = styled(Popper)(({theme}) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${
        theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}))

const StyledInput = styled(InputBase)(({theme}) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light'
                    ? 'rgba(3, 102, 214, 0.3)'
                    : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}))

const Button = styled(ButtonBase)(({theme}) => ({
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
    fontWeight: 600,
    '&:hover,&:focus': {
        color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
    },
    '& span': {
        width: '100%',
    },
    '& svg': {
        width: 16,
        height: 16,
    },
}))

export default function EditableUserItem({
                                             initialItem = "unassigned",
                                             onSave,
                                             additionalItems = {"unassigned": "Unassigned"}
                                         }: {
    initialItem: string,
    onSave: (itemKey: string, itemObject: User | string) => void,
    additionalItems?: { [key: string]: string }
}) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const theme = useTheme()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'github-label' : undefined

    const {data} = useQuery(["users"], () => apiProvider.getUsers(), {enabled: open})
    const [selectedItem, setSelectedItem] = React.useState<User | string>(() => {
        if (initialItem in additionalItems) {
            return initialItem
        } else {
            return data?.find(user => user.username === initialItem) ?? initialItem
        }
    })

    useEffect(() => {
        setSelectedItem(data?.find(user => user.username === initialItem) ?? initialItem)
    }, [initialItem, data])

    const options = data ? [...Object.keys(additionalItems), ...data] : []

    function handleNewValue(user: User | string) {
        setSelectedItem(user)
        onSave(typeof user === "string" ? user : user.username, user)
    }

    function handleClose() {
        if (anchorEl) {
            anchorEl.focus()
        }
        setAnchorEl(null)
    }

    return (
        <React.Fragment>
            <Box sx={{width: 221, fontSize: 13}}>
                <Button disableRipple aria-describedby={id} onClick={handleClick}>
                    <UserItem
                        user={typeof selectedItem in additionalItems ? additionalItems[selectedItem as string] : selectedItem}/>
                </Button>
            </Box>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Box
                            sx={{
                                borderBottom: `1px solid ${
                                    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                                }`,
                                padding: '8px 10px',
                                fontWeight: 600,
                            }}
                        >
                            Benutzer auswählen
                        </Box>
                        <Autocomplete
                            open
                            onClose={() => {
                                handleClose()
                            }}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return
                                }
                                if (newValue) {
                                    handleNewValue(newValue)
                                }
                            }}
                            isOptionEqualToValue={(option1, option2) => {
                                switch (typeof option1) {
                                    case "string": {
                                        return typeof option2 === "string" && option1 === option2
                                    }
                                    case "object": {
                                        return typeof option2 === "object" && option1.id === option2.id
                                    }
                                }
                            }}
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderOption={(props, option) => {
                                return <li {...props}><UserItem user={option}/></li>
                            }}
                            options={options}
                            filterOptions={(options, {inputValue}) => {
                                return options.filter(option => {
                                    if (typeof option === "string") {
                                        return option.toLowerCase().includes(inputValue.toLowerCase())
                                    } else {
                                        return option.username.toLowerCase().includes(inputValue.toLowerCase()) || option.name.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                })
                            }}
                            getOptionLabel={(option) => (typeof option === 'string' ? additionalItems[option] : option.username)}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Nutzer durchsuchen"
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    )
}
