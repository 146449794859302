import {QueryClient} from "@tanstack/react-query"
import {apiProvider} from "../api/api"
import {CreateProcurementRequestTerminal, ExternalLinkTerminal} from "./FormTerminals"
import jsonLogic from "json-logic-js"
import { Vendor } from "../api/models/vendors"

export type Answers = { [parameter_name: string]: string }

export class Form {
    id: number = Math.random()

    root: CompoundFormElement
    answers: Answers

    constructor(root: CompoundFormElement, answers: Answers = {}) {
        this.root = root
        this.answers = answers
    }
}

export interface FormElement {
    id: string
    condition: (answers: Answers) => boolean
    load?: (answers: Answers, queryClient: QueryClient) => Promise<void>
}

export class CompoundFormElement implements FormElement {
    id: string
    formElements: FormElement[]
    condition: (answers: Answers) => boolean
    load?: (answers: Answers, queryClient: QueryClient) => Promise<void> = undefined

    constructor(id: string, formElements: FormElement[], condition?: (answers: Answers) => boolean) {
        this.id = id
        this.formElements = formElements
        this.condition = condition ?? (() => true)
    }

    static async fromJson(json: any, preset: { [ key: string] : string } = {}) {
        return new CompoundFormElement(
            json.id,
            await Promise.all(json.elements.map(parse)),
            answers => jsonLogic.apply(json.condition ?? true, answers)
        )

        async function parse(json: any): Promise<FormElement> {
            switch (json.type) {
                case "compound":
                    return await CompoundFormElement.fromJson(json)
                case "form":
                    return new FormQuestion(json)
                case "option":
                    return new OptionQuestion(json)
                case "multi_select":
                    return new MultiSelectionQuestion(json)
                case "free_text":
                    return new FreeTextQuestion(json)
                case "country":
                    return new CountryQuestion(json)
                case "time":
                    return new TimeQuestion(json)
                case "vendor_info" :
                    if (preset["vendor"]) {
                        if (preset["product"]) {
                            return new ProductInfoFixedQuestion(json)
                        } else {
                        return new VendorInfoFixedQuestion(json)
                        }
                    } else {
                    return new VendorInfoQuestion(json)
                    }
                case "link":
                    return await apiProvider.getForm(json.link)
                case "create_procurement_request_terminal":
                    return new CreateProcurementRequestTerminal(json)
                case "external_link_terminal":
                    return new ExternalLinkTerminal(json)
                default:
                    throw new Error("Unknown question type: " + json.type)
            }
        }
    }
}

export class VendorInfo {
    id: string
    vendor: Vendor

    constructor(id: string, vendor: Vendor) {
        this.id = id
        this.vendor = vendor
    }
}

export abstract class Question implements FormElement {
    id: string
    title: string
    info: string
    condition: (answers: Answers) => boolean

    protected constructor(json: any) {
        this.id = json.id
        this.title = json.title
        this.info = json.description
        this.condition = (answers: Answers) => jsonLogic.apply(json.condition ?? true, answers)
    }

    abstract removeAnswers(form: Form): void
}

export class SingleQuestion extends Question {
    property: string

    constructor(json: any) {
        super(json)
        this.property = json.id
    }

    getAnswer(form: Form): string | undefined {
        return form.answers[this.property]
    }

    save(form: Form, option: string | undefined) {
        if (option !== undefined) {
            form.answers[this.property] = option
        } else {
            delete form.answers[this.property]
        }
    }

    removeAnswers(form: Form) {
        delete form.answers[this.property]
    }
}

export class MultiQuestion extends Question {
    properties: string[]

    constructor(json: any, properties: string[]) {
        super(json)
        this.properties = properties
    }

    getAnswer(form: Form, property: string): string | undefined {
        return form.answers[property]
    }

    getAnswers(form: Form): { [property: string]: (string | undefined) } {
        return Object.fromEntries(this.properties.map(property => [property, this.getAnswer(form, property)]))
    }

    save(form: Form, property: string, value: string | undefined) {
        if (value !== undefined) {
            form.answers[property] = value
        } else {
            delete form.answers[property]
        }
    }

    saveAll(form: Form, values: { [property: string]: (string | undefined) }) {
        Object.entries(values).forEach(([property, value]) => this.save(form, property, value))
    }

    removeAnswers(form: Form) {
        this.properties.forEach(property => delete form.answers[property])
    }
}

export interface Option {
    property_value: string
    title: string
    description: string
    image: string
}

export class OptionQuestion extends SingleQuestion {
    options: Option[]

    constructor(json: any) {
        super(json)
        this.options = json.options.map((option: any) => ({
            property_value: option.property_value,
            title: option.title,
            description: option.description,
            image: option.image
        }))
    }
}

export class CountryQuestion extends SingleQuestion {
    options: Option[]
    property: string

    constructor(json: any) {
        super(json)
        this.property = json.id
        this.options = json.options.map((option: any) => ({
                property_value: option.property_value,
                title: option.title,
                description: option.description,
                image: option.image
            })
        )
    }
}

export class TimeQuestion extends MultiQuestion {
    options: Option[]

    constructor(json: any) {
        super(json, [
            "urgency",
            "due_date",
        ])
        this.options = json.options.map((option: any) => ({
                property_value: option.property_value,
                title: option.title,
                description: option.description,
                image: option.image
            })
        )
    }
}

export class VendorInfoQuestion extends MultiQuestion {

    constructor(json: any) {
        super(json, [
            "vendor_id",
            "product_id",
            "vendor_name",
            "product_name",
            "vendor_url",
            "license_amount",
            "document_ids"
        ])
    }
}

export class VendorInfoFixedQuestion extends MultiQuestion {

    constructor(json: any) {
        super(json, [
            "vendor_id",
            "product_id",
            "vendor_name",
            "product_name",
            "vendor_url",
            "license_amount",
            "document_ids"
        ])
    }
}

export class ProductInfoFixedQuestion extends MultiQuestion {

    constructor(json: any) {
        super(json, [
            "vendor_id",
            "product_id",
            "vendor_name",
            "product_name",
            "vendor_url",
            "license_amount",
            "document_ids"
        ])
    }
}

export class FormQuestion extends MultiQuestion {
    formFields: { title: string, property: string, description: string, label: string, mandatory: boolean }[]

    constructor(json: any) {
        const formFields: {
            title: string,
            property: string,
            description: string,
            label: string,
            mandatory: boolean
        }[] = json.formElements.map((jsonField: any) => {
            return {
                title: jsonField.title,
                property: jsonField.property,
                description: jsonField.description,
                label: jsonField.label,
                mandatory: jsonField.mandatory
            }
        })
        super(json, formFields.map(field => field.property))
        this.formFields = formFields
    }
}

export class MultiSelectionQuestion extends MultiQuestion {
    options: {
        title: string,
        property: string,
        description: string,
        label: string,
        answerOption: string;
        legalBasis: string;
        id: number,
        mandatory: boolean
    }[]


    constructor(json: any) {
        const options: {
            title: string,
            property: string,
            description: string,
            label: string,
            answerOption: string;
            legalBasis: string;
            id: number,
            mandatory: boolean
        }[] =
            json.options.map((option: any) => ({
                    title: option.title,
                    property: option.property,
                    description: option.description,
                    label: option.label,
                    answerOption: option.answerOption,
                    legalBasis: option.legalBasis,
                    id: option.id,
                    mandatory: option.mandatory
                })
            )
        super(json, options.map(option => option.property))
        this.options = options
    }
}


export class FreeTextQuestion extends SingleQuestion {
    property: string

    constructor(json: any) {
        super(json)
        this.property = json.id
    }
}
