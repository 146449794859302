/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"
import Grid from "@mui/material/Grid"
import {Box, Typography} from "@mui/material"
import thankyou from "../../assets/Thanks_Springer.png"
import {SendProcurementRequestTerminal} from "../FormTerminals"
import {FormElementProps} from "../FormContent"

export default function SendProcurementRequestTerminalContent(props: FormElementProps<SendProcurementRequestTerminal>) {
    return (
        <div>
            <Grid container columns={12} flexDirection={"column"} paddingTop={2}>
                <Grid item flexDirection={"row"}>
                    <Typography
                        sx={{
                            fontSize: {xs: 24, md: 26, xl: 30},
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Danke!
                    </Typography>
                </Grid>
                <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Box width={20} height={20}></Box>
                    <Box
                        component="img"
                        sx={{
                            height: {xs: 150, md: 200, xl: 300},
                            width: {xs: 255, md: 340, xl: 500},
                            borderRadius: 4
                        }}
                        alt="Logo"
                        src={thankyou}
                    />
                    <Box width={20} height={20}></Box>
                </Grid>
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid container width={440}  alignItems={"center"} justifyContent={"center"} >
                        <Typography
                            sx={{
                                fontSize: {xs: 12, md: 16, xl: 18},
                                textAlign: "center",
                                fontWeight: "bold"
                            }}
                        >
                            Die Informationen wurden verarbeitet.
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {xs: 12, md: 16, xl: 18},
                                textAlign: "center",
                                fontWeight: "bold"
                            }}
                        >Alle relevanten Details und die  nächsten Schritte sind im Ticket zu finden.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
