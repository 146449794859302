import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Link, NavigateOptions, useLocation, useParams } from "react-router-dom"
import { useMutation, useQuery } from '@tanstack/react-query'
import { apiProvider } from "../api/api"
import {
    Box,
    Typography,
    Avatar,
    CardContent,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Button,
    Dialog,
    Divider, Tooltip, Select
} from "@mui/material"
import {Add, AddCircle, Delete, Help, OpenInNew} from "@mui/icons-material"
import { getReadableCategories } from "../vendors/util"
import { useAuth } from "../auth/auth"
import { Progress } from "../ui/Progress"
import Exiticon from "../forms/components/exiticon"
import {Vendor, Product, vendorRelations} from "../api/models/vendors"
import {CategoryTextField, ProductCategoryTextField, SimpleTextField} from "../ui/Textfields"
import { VendorRelation } from "../api/models/vendors"
import VendorForm from "../vendors/VendorForm"
import MenuItem from "@mui/material/MenuItem";

function DrawerItem(props: {
    icon: JSX.Element;
    link: string;
    navigationOptions?: NavigateOptions;
    text: string;
    hasProducts: boolean
}) {
    return (
            <Grid 
                direction={"row"} 
                alignItems={"center"} 
                borderRadius={2} 
                border={"1px primary.main"} 
                sx={{marginTop: 1}}
            >
                <Button
                    sx={{
                        borderRadius: 2, "&": {
                            textTransform: 'none',},
                        }}
                    component={Link}
                    to={props.link}
                    {...props.navigationOptions}
                    variant={props.hasProducts? "outlined" : "contained"}
                    size={"small"}
                >
                    <Stack direction={"row"} alignItems={"center"}>
                        <OpenInNew sx={{ color: props.hasProducts? "primary.main" : "white", marginRight: 0.5 }}/>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                textAlign: "center",
                                color: props.hasProducts? "primary.main" : "white"
                            }}
                        >
                            {props.text}
                        </Typography>
                    </Stack>
                </Button>
            </Grid>
    )
}

export function SearchDetails() {


    const { id } = useParams()
    const { data, isLoading, refetch } = useQuery(["search", "detail", id], () =>
        apiProvider.getVendor(id!))

    if (isLoading) {
        return <Progress />
    }

    if (data) {
        return <SearchDetailContent vendor={data} refetch={refetch} />
    }

    return <></>
}

function SearchDetailContent({ vendor, refetch }: { vendor: Vendor, refetch: () => void }) {

    const { t } = useTranslation()

    const [productDeleteConfirmationOpen, setProductDeleteConfirmationOpen] = React.useState(false)

    const generalInfos: { name: string, value: string, type: "text" | "link" }[] = [
        { name: t("vendors.details.general_infos.description_title"), value: vendor.description ?? "", type: "text" },
        { name: t("vendors.details.general_infos.webpage_title"), value: vendor.website ?? "", type: "link" },
    ]

    const sources: { [key: string]: string[] } = {}

    vendor.sources?.forEach(source => {
        let additionalData = undefined
        if (source.additional_data) {
            // additionalData = Object.entries(source.additional_data).map(([key, value]) => `${key}: ${value}`).join(", ")
            if (source.additional_data["Vertragsnummer"]) {
                additionalData = `Vertragsnummer: ${source.additional_data["Vertragsnummer"]}`
            }
        }
        const entry = source.name + (additionalData !== undefined ? ` (${additionalData})` : "")


        if (sources[source.source] === undefined) {
            sources[source.source] = [entry]
        } else {
            sources[source.source] = [...sources[source.source], entry]
            sources[source.source] = Array.from(new Set(sources[source.source]))
        }
    })


    const location = useLocation()

    // state and handler for showing the form to add a new product
    const [productFormOpen, setProductFormOpen] = React.useState(false)

    const handleProductAddClick = () => {
        setProductFormOpen(true)
    }

    const onSaveProduct = (product: Product) => {
        setProductFormOpen(false)
        apiProvider.addProductToVendor(vendor.id, product).then(() => {
            refetch()
        })
        // TODO: handle error
    }

    function hasProducts(vendor: any): boolean {
        if (vendor?.products) {
            return vendor.products.length > 0;
        }
        return false;
    }


    let auth = useAuth()

    const vendor_mutation = useMutation((vendor: Vendor) => {
        return apiProvider.updateVendor(vendor.id, vendor)
    })

    const updateVendor = (vendor: Vendor) => vendor_mutation.mutate(vendor)

    const [vendorEditDialogOpen, setVendorEditDialogOpen] = React.useState(false)


    return (
        <Stack direction="column" spacing="8" sx={{ bgcolor: "white" }}>
            <Stack direction="row" alignItems={"center"} spacing={3}
                sx={{ marginBottom: 2, marginLeft: 4, marginTop: 6 }}>
                {vendor.iconUrl ?

                    <img src={vendor.iconUrl} loading="eager" width="80" height="80" alt="" />
                    : <Avatar
                        alt={"None"}
                        sx={{ width: 80, height: 80 }}
                        variant={"square"}
                    >
                        <Typography fontSize={20} textAlign={"center"}>No image</Typography>
                    </Avatar>}
                <Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant="h4">{vendor?.name}</Typography>
                        <AvailabilityStatusChip availabilityStatus={vendor.vendorRelation} />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} sx={{ marginTop: 1 }}>
                        <Typography variant="body1" sx={{
                            fontWeight: "bold",
                            color: "grey",
                            marginRight: 1
                        }}>{getReadableCategories(vendor)}</Typography>
                        <OpenInNew onClick={() => {
                            window.open(vendor.website, '_blank', 'noreferrer')
                        }} sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                                cursor: "pointer",
                            },
                            width: 18,
                            height: 18
                        }}></OpenInNew>
                    </Stack>
                </Stack>
            </Stack>

            <Divider></Divider>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} sx={{ paddingLeft: 2 }}>
                    <Grid item xs={5} md={5}>
                        <InformationCard title={t("vendors.details.general_infos.title")}>
                            {generalInfos.map((info, index) => {
                                return <TextInfoItem key={index} {...info} />
                            })}
                        </InformationCard>
                        <Stack direction={"column"} sx={{ marginLeft: 2 }}>
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{ fontWeight: "bold" }}
                            >
                                {t("vendors.details.internal_infos.status_title_long")}
                            </Typography>
                            <Typography>{t(`vendors.vendor_relations.${vendor.vendorRelation}`)}</Typography>
                        </Stack>
                        <Stack direction={"column"} sx={{ marginLeft: 2, marginTop: 2 }}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {t("vendors.details.products.title")}
                                </Typography>
                                {
                                    auth.user?.hasChangeVendorInfoPermission() ?
                                        <IconButton color="primary" onClick={handleProductAddClick} sx={{ marginLeft: 1 }}>
                                            <AddCircle />
                                        </IconButton>
                                        : null
                                }
                            </Stack>
                            {vendor.products?.map((product, index) => {
                                return (
                                    <Stack
                                        key={"vendorproduct" + index}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            marginTop: 1,
                                            padding: "4px 20px",
                                            maxWidth: "70%",
                                        }}
                                    >
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                        >
                                        <Typography
                                            fontSize={16}
                                            fontWeight={"bold"}
                                        >
                                            {product.name}
                                        </Typography>
                                        <ProductDeleteConfirmationDialog
                                            open={productDeleteConfirmationOpen}
                                            onClose={() => setProductDeleteConfirmationOpen(false)}
                                            onDelete={() => {
                                                apiProvider.deleteProductFromVendor(product.id).then(() => {
                                                    refetch()
                                                })
                                            }}
                                        />
                                         <Button 
                                            // add a styling that changes the background color to blue when hovered
                                            sx={{
                                                marginLeft: 1,
                                                borderRadius: 2,
                                                padding: "0px 10px",
                                                "&:hover": {
                                                    transparency: "0.5",
                                                },
                                                "&": {
                                                    textTransform: 'none',
                                                }
                                            }}
                                            variant= {hasProducts(vendor)? "contained" : "outlined"}
                                            component={Link}
                                            to={`/create?vendorId=${vendor.id}&productId=${product.id}`}
                                            state = {{backgroundLocation: location}}
                                            >
                                                    <Typography
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: hasProducts(vendor)? "white" : "primary.main", marginRight: 0.5,
                                                            textAlign: "center",
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {t("vendors.requests.product_request_button")}
                                                    </Typography>
                                            </Button>
                                        </Stack>
                                        {
                                            auth.user?.hasChangeVendorInfoPermission() ?
                                                <IconButton color="primary" onClick={() => {
                                                    return setProductDeleteConfirmationOpen(true)
                                                }} sx={{ marginLeft: 1 }}>
                                                    <Delete fontSize="small" />
                                                </IconButton>
                                                : null
                                        }
                                    </Stack>
                                )
                            })}
                            <DrawerItem
                                text={t("vendors.requests.new_request_button")}
                                key="create"
                                icon={<Add />}
                                link={`/create?vendorId=${vendor.id}`}
                                // eslint-disable-next-line no-restricted-globals
                                navigationOptions={{
                                    state: { backgroundLocation: location }
                                }}
                                hasProducts={hasProducts(vendor)}
                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={5} md={5}>
                        <InformationCard title={t("vendors.details.internal_infos.title")}>
                            <InfoItem name={t("vendors.details.internal_infos.tags_title")}>
                                <Stack direction="row" spacing={1}>
                                    {vendor.customTags?.map((tag, index) => {
                                        return <Box key={index} sx={{
                                            marginLeft: 2, bgcolor: "lightgrey", color: "white",
                                            paddingLeft: 1, paddingRight: 1, borderRadius: 4,
                                        }}>
                                            <Typography
                                                sx={{ fontSize: 16 }}>{tag.name}</Typography>
                                        </Box>
                                    }
                                    )}
                                </Stack>
                            </InfoItem>
                            <InfoItem name={t("vendors.details.internal_infos.sources_title")}>
                                <Stack direction="column">
                                    {Object.entries(sources).map(([key, value]) => {
                                        return <TextInfoListItem name={key} value={value} key={key}
                                            externalLink={`https://www.google.de/search?q=${key}`} />
                                    })
                                    }
                                </Stack>
                            </InfoItem>
                        </InformationCard>
                        {auth.user?.hasChangeVendorInfoPermission() ?
                            <Stack direction={"column"} sx={{ marginLeft: 2 }}>
                                <Button
                                    variant="outlined" onClick={() => setVendorEditDialogOpen(true)}
                                    sx={{
                                        width: "fit-content",
                                        borderRadius: 2, "&": {
                                            textTransform: 'none',
                                        }
                                    }}
                                    size={"small"}
                                >
                                    {t("vendors.dialog.edit_title")}
                                </Button>
                                <VendorForm
                                    mode="edit"
                                    vendor={vendor}
                                    open={vendorEditDialogOpen}
                                    onClose={() => setVendorEditDialogOpen(false)}
                                    onSave={(vendor: Vendor) => {
                                        updateVendor(vendor)
                                        setVendorEditDialogOpen(false)
                                    }}
                                />
                            </Stack>
                            : null}
                        {auth.user?.hasChangeVendorInfoPermission() ?
                            <NewProductDialog
                                open={productFormOpen}
                                onClose={() => setProductFormOpen(false)}
                                vendor={vendor}
                                onSave={onSaveProduct} />
                            :
                            null
                        }
                    </Grid>
                    {/*{products.length > 0 && <Grid item xs={5} md={5}>*/}
                    {/*    <InformationCard title={t("vendors.details.products.title")}>*/}
                    {/*        <Stack direction="column">*/}
                    {/*            {products.map((product) => {*/}
                    {/*                return <Typography>{`${product.name} (${getReadableCategory(product)})`}</Typography>*/}
                    {/*            })}*/}
                    {/*        </Stack>*/}
                    {/*    </InformationCard>*/}
                    {/*</Grid>}*/}
                </Grid>
            </Box>
        </Stack>
    )
}

function InformationCard({
    title,
    children,
}: {
    title: string;
    children: ReactNode;
}) {
    return (
        <Stack>
            <CardContent>
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold", paddingBottom: 2 }}
                >
                    {title}
                </Typography>
                <Stack spacing={2} direction="column">{children}</Stack>
            </CardContent>

        </Stack>
    )
}

function InfoItem({ name, children, externalLink }: { name: string, children: ReactNode, externalLink?: string }) {

    const linkList = [{ id: 1, key: "Newtron", link: "https://www.newtron.de/" }, {
        id: 2,
        key: "FolderStructure",
        link: "https://www.microsoft.com/de-de/microsoft-365/onedrive/online-cloud-storage"
    }, { id: 3, key: "CCP", link: "https://www.ccpsoft.de/" }, {
        id: 4,
        key: "Spider",
        link: "https://www.spider-software.net/"
    }, { id: 5, key: "Sievo", link: "https://sievo.com/" }, {
        id: 6,
        key: "IT Vertragsdatenbank",
        link: "https://www.google.com/search?q=it+vertragsdatenbank&rlz=1C1CHBF_deDE983DE983&oq=it+vertragsdatenbank&aqs=chrome..69i57j33i160l2.7081j0j4&sourceid=chrome&ie=UTF-8"
    }]
    const filteredList = linkList.filter(item => item.key === name)

    return (
        <Stack direction="column">
            <Stack direction="row" alignItems={"center"}>
                <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", paddingBottom: 0.5 }}>
                    {name}
                </Typography>
                {filteredList.map((item, index) =>
                    <IconButton size={"small"} key={"infoitem-" + { index }} sx={{ padding: 0, marginLeft: 0.5 }} component="a" href={item.link}
                        target="_blank">
                    </IconButton>)}
            </Stack>
            {children}
        </Stack>

    )
}

function TextInfoListItem({ name, value, externalLink }: { name: string; value: string[], externalLink?: string }) {

    return (
        <InfoItem name={name} externalLink={externalLink}>
            <Stack direction="column">
                {value.map((item, index) => (
                    <Typography variant="body1" key={"textinfoitem-" + index}>{item}</Typography>
                ))}
            </Stack>
        </InfoItem>
    )
}

function TextInfoItem({ name, value, type }: { name: string; value: string, type: "text" | "link" }) {

    return (
        <InfoItem name={name}>
            {type === "link" ?
                <Typography variant="body2" component="a" href={value} target="_blank">{value}</Typography>

                : <Typography variant="body1">{value}</Typography>

            }

        </InfoItem>
    )
}

export function AvailabilityStatusChip(props: { availabilityStatus: VendorRelation | undefined }) {
    const { t } = useTranslation()
    return (
        <div>
            {(() => {
                switch (props.availabilityStatus) {
                    case "preferred_vendor":
                        return (
                            <Box sx={{
                                marginLeft: 2, bgcolor: "green", color: "white",
                                paddingLeft: 1, paddingRight: 1, borderRadius: 4,
                            }}>
                                <Typography
                                    sx={{ fontSize: 16 }}>{t(`vendors.vendor_relations.${props.availabilityStatus}`)}</Typography>
                            </Box>
                        )
                    case "unknown":
                        return (
                            <Box sx={{
                                marginLeft: 2, bgcolor: "grey", color: "white",
                                paddingLeft: 1, paddingRight: 1, borderRadius: 4,
                            }}>
                                <Typography
                                    sx={{ fontSize: 16 }}>{t(`vendors.vendor_relations.${props.availabilityStatus}`)}</Typography>
                            </Box>
                        )
                    default:
                        return (
                            <Box sx={{
                                marginLeft: 2, bgcolor: "#002D62", color: "white",
                                paddingLeft: 1, paddingRight: 1, borderRadius: 4,
                            }}>
                                <Typography
                                    sx={{ fontSize: 16 }}>{t(`vendors.vendor_relations.${props.availabilityStatus}`)}</Typography>
                            </Box>
                        )
                }
            })()}
        </div>
    )
}

function ProductDeleteConfirmationDialog(props: {
    open: boolean,
    onClose: () => void,
    onDelete: () => void,
}) {

    const handleClose = () => {
        props.onClose()
    }

    const handleDelete = () => {
        props.onDelete()
        handleClose()
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Produkt löschen?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Sind Sie sicher, dass Sie dieses Produkt löschen möchten?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button onClick={handleDelete}>Löschen</Button>
            </DialogActions>
        </Dialog>
    )
}

function NewProductDialog(props: {
    vendor: Vendor,
    onSave: (product: Product) => void
    open: boolean,
    onClose: () => void,
}) {

    const [product, setProduct] = React.useState({
        name: "",
        description: "",
        website: "",
        category: undefined,
        subCategory: undefined,
    } as Product)

    const handleClose = () => {
        props.onClose()
    }

    const handleSave = () => {
        props.onSave(product)
        handleClose()
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="lg" fullWidth>
            <Grid sx={{ marginTop: 4, marginLeft: 4, marginRight: 4 }}>
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                >
                    <Box width={20} height={20} color="#0000FF" sx={{ paddingLeft: 6 }}>
                        <Exiticon onClose={handleClose}></Exiticon>
                    </Box>
                    <DialogTitle>Neues Produkt oder Dienstleistung hinzufügen</DialogTitle>
                    <Box width={20} height={20} color="#0000FF">
                    </Box>
                </Grid>
                <DialogContent>
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        paddingBottom={2}
                    >
                        <Grid container flexDirection={"column"} justifyContent={"center"} sx={{
                            m: 0.7,
                            width: 600,
                            maxWidth: { xs: 400, md: 600 },
                        }}>
                            <SimpleTextField
                                value={product.name}
                                setValue={(value) => setProduct(prevState => ({
                                    ...prevState,
                                    name: value
                                }))}
                                title={"Name"}
                                subTitle={"Name von dem Produkt bzw. der Dienstleistung"} />
                            <SimpleTextField value={product.description}
                                setValue={(value) => setProduct(prevState => ({
                                    ...prevState,
                                    description: value
                                }))}
                                title={"Beschreibung"}
                                subTitle={"Kurze Beschreibung von dem Produkt bzw. der Dienstleistung"} />
                            <SimpleTextField
                                value={product.website}
                                setValue={(value) => setProduct(prevState => ({
                                    ...prevState,
                                    website: value
                                }))}
                                title={"Website"}
                                subTitle={"Website des Produkts"} />
                            <ProductCategoryTextField
                                value={{ category: product?.category, subCategory: product?.subCategory }}
                                setValue={(category, subCategory) => {
                                    setProduct(prevState => ({
                                        ...prevState,
                                        category: category ?? undefined,
                                        subCategory: subCategory ?? undefined
                                    }))
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={handleSave}>Hinzufügen</Button>
                </DialogActions>
            </Grid>
        </Dialog>
    )
}

function EditDialog(props: {
    vendor: Vendor, onSave: (vendor: Vendor) => void,
    openAvailabilityStatus: boolean,
    handleCloseAvailabilityStatus: () => void,
    handleOpenAvailabilityStatus: () => void,
    availabilityStatus: VendorRelation,
    handleChangeAvailabilityStatus: (vendorRelation: VendorRelation) => void
}) {
    const [open, setOpen] = React.useState(false)

    const [vendorData, setVendorData] = React.useState<{
        name: string,
        website?: string,
        category?: string,
        subCategory?: string,
        vendor_relation: VendorRelation
    }>({
        name: props.vendor.name,
        website: props.vendor.website,
        category: props.vendor.category,
        subCategory: props.vendor.subCategory,
        vendor_relation: props.vendor.vendorRelation
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSave = async () => {
        props.vendor.name = vendorData.name
        props.vendor.website = vendorData.website
        props.vendor.category = vendorData.category
        props.vendor.subCategory = vendorData.subCategory
        props.vendor.vendorRelation = vendorData.vendor_relation

        await props.onSave(props.vendor)
        setOpen(false)
    }

    const {t} = useTranslation()

    const handleChange = (value: any) => {
        props.handleChangeAvailabilityStatus(value)
        setVendorData(prevState => ({...prevState, vendor_relation: value}))
    }


    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen} sx={{
                borderRadius: 2, "&": {
                    textTransform: 'none',
                }
            }} size={"small"}>
                {t("search_details.edit_vendor")}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <Grid sx={{marginTop: 4, marginLeft: 4, marginRight: 4}}>
                    <Grid
                        container
                        flexDirection={"row"}
                        paddingBottom={2}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{width: "100%"}}
                    >
                        <Box width={20} height={20} color="#0000FF" sx={{paddingLeft: 6}}>
                            <Exiticon onClose={handleClose}></Exiticon>
                        </Box>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography
                                sx={{
                                    fontSize: {xs: 20, md: 30},
                                    fontWeight: "bold",
                                    align: "center",
                                    mx: "auto"
                                }}
                            >
                                {t("vendors.edit_vendor")}
                            </Typography>
                            <Tooltip
                                title="Um die Datenqualität hochzuhalten und die Arbeit aller Beteiligten zu erleichtern benötigen wir einige Informationen zur Pflege der Datenbank."
                                placement="bottom-end">
                                <Help
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        marginBottom: "6px",
                                        marginLeft: "2px",
                                        color: "gray",
                                    }}
                                ></Help>
                            </Tooltip>
                        </Stack>
                        <Box width={20} height={20} color="#0000FF">
                        </Box>
                    </Grid>
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        paddingBottom={2}
                    >
                        <Grid container flexDirection={"column"} justifyContent={"center"} sx={{
                            m: 0.7,
                            width: 600,
                            maxWidth: {xs: 400, md: 600},
                        }}>
                            <SimpleTextField value={vendorData?.name}
                                setValue={(value) => setVendorData(prevState => ({
                                    ...prevState,
                                    name: value
                                }))}
                                title={"Lieferanten-Name"}
                                subTitle={"Gib den Namen des (neuen) Lieferanten ein"} />
                            <SimpleTextField value={vendorData?.website}
                                setValue={(value) => setVendorData(prevState => ({
                                    ...prevState,
                                    website: value
                                }))}
                                title={"URL"}
                                subTitle={"Gib hier die Web-Adresse des Lieferanten ein"} />
                            <CategoryTextField
                                value={{category: vendorData?.category, subCategory: vendorData?.subCategory}}
                                setValue={(category, subCategory) => {
                                    setVendorData(prevState => ({
                                        ...prevState,
                                        category: category ?? undefined,
                                        subCategory: subCategory ?? undefined
                                    }))
                                }}
                            />
                            <Stack direction={"column"} alignItems={"flex-start"}>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{fontWeight: "bold", marginLeft: 1}}
                                >
                                    {t("vendors.details.internal_infos.status_title_long")}
                                </Typography>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={props.openAvailabilityStatus}
                                    onClose={props.handleCloseAvailabilityStatus}
                                    onOpen={props.handleOpenAvailabilityStatus}
                                    value={props.availabilityStatus}
                                    onChange={(event) => handleChange(event.target.value as VendorRelation)}
                                    variant={"outlined"}
                                    sx={{
                                        m: 0.7,
                                        width: 600,
                                        maxWidth: {xs: 500, md: 700},
                                    }}
                                >
                                    {vendorRelations.map((vendorRelation, index) => (
                                        <MenuItem
                                            key={index}
                                            value={vendorRelation}
                                        >
                                            {t(`vendors.vendor_relations.${vendorRelation}`)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid
                            container
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            paddingBottom={2}
                            paddingRight={6}
                            paddingLeft={6}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{width: 120, height: 40}}
                                onClick={handleClose}
                            >
                                <Typography variant={"body2"}>Beenden</Typography>
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{width: 120, height: 40}}
                                onClick={handleSave}
                            >
                                <Typography variant={"body2"}>Speichern</Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Dialog>
        </div>
    )
}
