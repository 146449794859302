export const TRANSLATION_EN = {
    app_name: "trado.",
    general: {
        date: {
            today: "Today",
            yesterday: "Yesterday",
        }
    },
    menu: {
        dashboard_title: "Dashboard",
        request_list_title: "Requests",
        user_list_title: "Users",
        vendor_list_title: "Vendors",
        create_title: "Create Request",
        new_purchase_list_title: "New purchase",
        search_list_title: "Search",

    },
    request_details: {
        edit: "Edit",
        delete: "Delete",
        add: "Add",
        add_new: "Add new task",
        add_from_template: "Add from template",
        change_order: "Change order",
        nothing_to_do: "Nothing to do for you at the moment...",
        open_tasks: "My open tasks",
        done_tasks: "My done tasks",
        future_tasks: "Future Tasks",
        process_flow: "Process-Flow"
    },
    request_form: {
        title: "New request",
        subtitle: "Please add more information about your request",
        vendor: "Vendor",
        product: "Product | Service",
        vendor_preselected_newrequest: "Change",
        product_preselected_newrequest: "Change",
        url: "URL",
        number_of_licenses: "Amount of licenses",
        document_upload: "Upload document",
        documents: "Documents"
    },
    edit_dialog: {
        new_task: "Add new task",
        edit: "Edit",
        close: "Close",
        save: "Save",
        assignee: "Assigned to:",
        type: "Task Type",
        title: "Title",
        integration_trigger: "Trigger",
        form: "Form",
    },

    sidebar: {
        time_estimation: "Time estimation",
    },
    request_list: {
        title: "Requests",
        tabs: {
            open_requests: "Open Requests",
            accepted_requests: "Accepted Requests",
            rejected_requests: "Rejected Requests",
        },
        table: {
            id_title: "ID",
            title_title: "Title",
            category_title: "Category",
            status_title: "Status",
            progress_title: "Progress",
            details_title: "Creation Date",
            requester_title: "Requester",
            requester_email: "E-Mail"
        },
        status: {
            in_progress: "In Progress",
            success: "Successfully closed",
            canceled: "Canceled",
        }
    },
    user_list: {
        title: "User List",
        table: {
            user_name: "Name",
            user_email: "E-Mail",
            user_user_name: "User-Name",
            user_roles: "User-Roles",
        }
    },
    vendors: {
        title: "Vendors",
        preferred: "Preferred",
        add: "Add Vendor",
        not_preferred: "Not Preferred",
        vendor_relations: {
            preferred_vendor: "Preferred Vendor",
            managed_contract: "Managed Contract",
            partner_offer: "Partner Offer",
            contract_relation: "Contract Relation",
            billing_relation: "Billing Relation",
            unknown: "Unknown",
        },
        table: {
            logo: "Logo",
            name_title: "Vendor",
            category_title: "Category",
            status_title: "Status",
        },
        details: {
            general_infos: {
                title: "General Information",
                description_title: "Description",
                webpage_title: "Webpage",
            },
            internal_infos: {
                title: "Internal Information",
                categories_title: "Categories",
                status_title: "Status",
                sources_title: "Sources",
                tags_title: "Tags",
            },
            products: {
                title: "Products | Services",
            }
        }
    },
    search_screen: {
        title1: `Hi `,
        title2: `, what do you need?`,
        search_button: "Search",
        search_categories: "Search in Categories",
        no_results: "No Results",
        nothing_found: "Did not find the right thing?",
        new_request: "New Request",
        categories: {
            title: "Search Categories",
        },
    },
    search_details: {
        edit_vendor: `Edit vendor`,
    },
    robots: {
        comments: {
            not_processing_personal_data_for_software: "The creator selected that no personal data will be processed for this request. This is very unusual. Please check if this is correct. Should privacy be added to the workflow?",
            notify_about_existing_vendor: "A business relationship has already been found for the requested supplier {{vendor, string}}.",
            check_sanctions_robot: "The country {{country, string}} in which the supplier is located has been found on the sanctions list. Please check if the business relationship can be continued.",
            actions: {
                "yes": "Yes",
                "no": "No",
            }
        }
    },
    comments: {
        action_delete: "Delete",
    }
}
