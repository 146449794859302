import React from "react"
import { Box, Grid, Stack, Tab, Tabs, } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { apiProvider } from "../api/api"
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import Typography from "@mui/material/Typography"
import { humanReadableDate } from "../util/date"
import { useTranslation } from "react-i18next"
import { QueryKeys } from "../api/queryKeys"
import { UserItem } from "../ui/UserListItem"
import { ProcurementRequestState } from "../api/models/procurementRequest";
import { User } from "../api/models/users";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabContent(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: "100%" }}
            {...other}
        >
            {value === index && <React.Fragment>{children}</React.Fragment>}
        </div>
    )
}

export function RequestList() {
    const { t } = useTranslation()
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }
    return (
        <Stack sx={{ height: "100%", paddingTop: 3, bgcolor: "white" }}>
            <Typography
                variant="h6"
                fontWeight={"bold"}
                marginLeft={3}
                marginTop={2}>
                {t("request_list.title")}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                    <Tab label={t("request_list.tabs.open_requests")} />
                    <Tab label={t("request_list.tabs.closed_requests")} />
                </Tabs>
            </Box>
            <TabContent value={value} index={0}>
                <RequestTable status={"open"} />
            </TabContent>
            <TabContent value={value} index={1}>
                <RequestTable status={"closed"} />
            </TabContent>
        </Stack>
    )
}

export type RequestTableStatus = ProcurementRequestState | "closed";


function RequestTable(props: { status?: RequestTableStatus }) {
    const { t } = useTranslation()
    const columns: GridColDef[] = [
        {
            field: "id",
            headerClassName: "column-header",
            flex: 0,
            renderHeader: () => <strong>{t("request_list.table.id_title")}</strong>,
        },
        {
            field: "title",
            renderHeader: () => <strong>{t("request_list.table.title_title")}</strong>,
            headerClassName: "column-header",
            flex: 1,
        },
        {
            field: "category",
            renderHeader: () => <strong>{t("request_list.table.category_title")}</strong>,
            headerClassName: "column-header",
            flex: 0.6,
            align: "right",
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <Grid container flexDirection={"row"} alignItems={"center"}>
                        <Box
                            sx={{
                                width: 8,
                                height: 8,
                                bgcolor: "#0000FF",
                                borderRadius: 10,
                                marginRight: 1,
                            }}
                        ></Box>
                        <Typography variant="body2">{params.value?.slice(0, 8)}</Typography>
                    </Grid>
                )
            },
        },
        {
            field: "status",
            renderHeader: () => <strong>{t("request_list.table.status_title")}</strong>,
            headerClassName: "column-header",
            flex: 0.8,
            align: "left",
            renderCell: (params: GridRenderCellParams<string>) => {
                return (
                    <StatusChip status={params.value}></StatusChip>

                )
            },
        },
        {
            field: "progress",
            renderHeader: () => <strong>{t("request_list.table.progress_title")}</strong>,
            headerClassName: "column-header",
            flex: 0.4,
            align: "center",
            renderCell: (params: GridRenderCellParams<number>) => {
                return <ProgressBar value={params.value ?? 0} />
            },
        },
        {
            field: "created_date",
            renderHeader: () => <strong>{t("request_list.table.details_title")}</strong>,
            headerClassName: "column-header",
            width: 120,
            align: "center",
            renderCell: (params: GridRenderCellParams<Date>) => {
                return (
                    <Grid container flexDirection={"row"} alignItems={"center"}>
                        <Typography variant="body2">
                            {params.value && humanReadableDate(params.value)}
                        </Typography>
                    </Grid>
                )
            },

        },
        {
            field: "requester",
            renderHeader: () => <strong>{t("request_list.table.requester_title")}</strong>,
            headerClassName: "column-header",
            width: 220,
            align: "center",
            renderCell: (params: GridRenderCellParams<User>) => {
                return (

                    <UserItem user={params.value ?? "Unassigned"} />
                )
            },
        },
    ]

    const statusApproved = props.status === "closed" ? "approved" : props.status;
    const statusCanceled = props.status === "closed" ? "canceled" : props.status;

    const { data: dataApproved, isLoading: isLoadingApproved } = useQuery(
        QueryKeys.getProcurementRequestList(statusApproved),
        async () => await apiProvider.getRequests(statusApproved)
    )

    const { data: dataCanceled, isLoading: isLoadingCanceled } = useQuery(
        QueryKeys.getProcurementRequestList(statusCanceled),
        async () => await apiProvider.getRequests(statusCanceled)
    )

    let data = dataApproved;
    let isLoading = isLoadingApproved;

    if (props.status === "closed") {
        data = [...(dataApproved ?? []), ...(dataCanceled ?? [])];
        isLoading = isLoadingApproved || isLoadingCanceled;
    }

    const navigate = useNavigate()

    const rows = data?.map((request) => {
        return {
            id: request.id,
            title: request.title,
            progress:
                100 *
                (request.tasksStatus.completedTasks /
                    request.tasksStatus.totalTasks),
            category: "Software/Collaboration Software", // TODO
            created_date: request.created_at,
            status: request.status,
            requester: request.created_by,

        }
    })

    return (
        <div style={{ display: "flex", height: "100%" }}>
            <Box
                style={{ flexGrow: 1 }}
                sx={{
                    "& .column-header": { fontSize: 14 },
                    "&:hover": {
                        backgroundColor: "transparent",
                        cursor: "pointer",
                    },
                }}
            >
                <DataGrid
                    sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                    }}
                    loading={isLoading}
                    rows={rows !== undefined ? rows : []}
                    onRowClick={(params) => navigate(`/requests/${params.id}`)}
                    disableSelectionOnClick={true}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                />
            </Box>
        </div>
    )
}

interface ProgressBarProps {
    value: number;
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
    return (
        <div
            style={{
                width: "100%",
                height: 4,
                background: "lightgrey",
                borderRadius: 4,
            }}
        >
            <div
                style={{
                    maxWidth: `${props.value}%`,
                    height: "100%",
                    background: props.value < 30 ? "red" : props.value < 70 ? "orange" : "green",
                }}
            />
        </div>
    )
})


export function StatusChip(props: { status: string | undefined }) {
    const { t } = useTranslation()

    return (
        <div>
            {(() => {
                switch (props.status) {
                    case "open":
                        return (
                            <Box sx={{
                                marginLeft: 2,
                                bgcolor: "orange",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 4,
                                color: "white"
                            }}>
                                <Typography sx={{ fontSize: 14 }}>{t("request_list.status.in_progress")}</Typography>
                            </Box>

                        )
                    case "approved":
                        return (
                            <Box sx={{
                                marginLeft: 2,
                                bgcolor: "green",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 4,
                                color: "white"
                            }}>
                                <Typography sx={{ fontSize: 14 }}>{t("request_list.status.success")}</Typography>
                            </Box>
                        )
                    case "canceled":
                        return (
                            <Box sx={{
                                marginLeft: 2,
                                bgcolor: "#FA5F55",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 4,
                                color: "white"
                            }}>
                                <Typography sx={{ fontSize: 14 }}>{t("request_list.status.canceled")}</Typography>
                            </Box>
                        )
                }
            })()}
        </div>

    )

}
