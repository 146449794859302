import {Answers, CompoundFormElement, Form, FormElement, MultiQuestion, Question, SingleQuestion} from "./questions"
import {useContext, useEffect, useState} from "react"
import {FormContext} from "./FormContent"

export class UIStates {
    private states: { [id: string]: { [key: string]: any } } = {}

    get(element: FormElement, identifier: string): any {
        const state = this.states[element.id]
        return state?.[identifier]
    }

    save(element: FormElement, identifier: string, value: any) {
        if (this.states[element.id] === undefined) {
            this.states[element.id] = {}
        }
        this.states[element.id][identifier] = value
    }

    createUIStates(element: CompoundFormElement): UIStates {
        if (this.states[element.id] === undefined) {
            this.states[element.id] = {}
        }

        if (this.states[element.id]["childUIStates"] !== undefined) {
            return this.states[element.id]["childUIStates"]
        } else {
            const uiStates = new UIStates()
            this.states[element.id]["childUIStates"] = uiStates
            return uiStates
        }
    }
}

export function getIndexOfNextFormElement(currentIndex: number, elements: FormElement[], answers: Answers): number | undefined {
    let nextIndex = currentIndex + 1
    for (let i = nextIndex; i < elements.length; i++) {
        if (elements[i].condition(answers)) {
            return i
        }
    }
    return undefined
}

export function getIndexOfPrevFormElement(currentIndex: number, elements: FormElement[], answers: Answers): number | undefined {
    let prevIndex = currentIndex - 1
    for (let i = prevIndex; i >= 0; i--) {
        if (elements[i].condition(answers)) {
            return i
        }
    }

    return undefined
}

export function removeFromAnswers(currentIndex: number, prevIndex: number, form: Form, elements: FormElement[]) {
    function removeFromElement(element: FormElement) {
        if (element instanceof Question) {
            element.removeAnswers(form)
        } else if (element instanceof CompoundFormElement) {
            element.formElements.forEach((item) => removeFromElement(item))
        }
    }

    for (let i = currentIndex; i > prevIndex; i--) {
        removeFromElement(elements[i])
    }
}

export function useSavableState(element: SingleQuestion): [string | undefined, (value: string | undefined) => void];
export function useSavableState(element: MultiQuestion, propertyName: string): [string | undefined, (value: string | undefined) => void];
export function useSavableState(element: SingleQuestion | MultiQuestion, propertyName?: string): [string | undefined, (value: string | undefined) => void] {
    const property = element instanceof MultiQuestion ? propertyName! : element.property
    const saver = element instanceof MultiQuestion
        ? (question: Question, form: Form, value: string | undefined) => element.save(form, property, value)
        : (question: Question, form: Form, value: string | undefined) => element.save(form, value)

    const loader = element instanceof MultiQuestion
        ? (question: Question, form: Form) => element.getAnswer(form, property)
        : (question: Question, form: Form) => element.getAnswer(form)

    return useCustomSavableState(element, property, loader, saver)
}

export function useSavableManyState(element: MultiQuestion): [{ [property: string]: (string | undefined) }, (value: { [property: string]: (string | undefined) }) => void] {
    return useCustomSavableState(element, element.id, (question, form) => question.getAnswers(form), (question, form, value) => question.saveAll(form, value))
}

export function useCustomSavableState<T, Q extends Question>(element: Q, key: string, loader: (question: Q, form: Form) => T, saver: (question: Q, form: Form, value: T) => void): [T, (value: T) => void] {
    const {form, formElementState} = useContext(FormContext)!

    const [state, setState] = useState<T>(
        formElementState.get(element, key) ?? loader(element, form)
    )

    function saveState(value: T) {
        setState(value)
        formElementState.save(element, key, value)
        saver(element, form, value)
    }

    useEffect(() => {
        saveState(state)
    }, [])

    return [state, saveState] as [T, (value: T) => void]
}
