import { LoginError } from "./api"
import axios from "axios"
import { CompoundFormElement } from "../forms/questions"
import { BaseTask, createTaskFromData, ProcurementRequestTemplate, TaskList, TaskState } from "./models/tasks";
import {
    Comment,
    Document,
    ProcurementRequest,
    ProcurementRequestState,
    RobotComment,
    UserComment
} from "./models/procurementRequest";
import {
    AddProductDataContract,
    AddTaskByTemplateContract,
    BaseTaskContract,
    CommentContract,
    CreateVendorWithCloseDataContract,
    DocumentContract,
    FormContract,
    ProcurementRequestContract,
    ProcurementRequestUpdateContract,
    PropertyConfigContract,
    TaskTemplateContract,
    UserContract,
    VendorContract,
    ProcurementRequestTemplateContract
} from "./apiContracts";
import { Tokens, User } from "./models/users";
import { Category, Product, Vendor } from "./models/vendors";
import { SrvRecord } from "dns";

const accessTokenKey = "accessToken"
const refreshTokenKey = "refreshToken"
const userKey = "user"

export class BackendApiProvider {
    userName = window.localStorage.getItem(userKey) ?? undefined
    loggedOutDelegate?: () => void

    api = axios.create({ baseURL: `${baseURL}/api` })
    loginApi = axios.create({ baseURL: `${baseURL}/api` })

    constructor() {
        const access_token = window.localStorage.getItem(accessTokenKey)
        if (access_token !== null) {
            this.api.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
        }

        this.api.interceptors.response.use((response) => {
            return response
        }, async error => {
            const originalRequest = error.config
            if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true
                await this.refreshToken()
                return this.api(originalRequest)
            }
            return Promise.reject(error)
        })
    }


    async login(username: string, password: string): Promise<void> {
        try {
            const response = await this.loginApi.post("/token/", {
                email: username,
                password: password
            })

            window.localStorage.setItem(userKey, username)

            const tokens = response.data as Tokens

            this.storeTokens(tokens)
        } catch (e: any) {
            if (e?.response?.status === 401) {
                throw new LoginError()
            } else
                throw e
        }
    }

    async refreshToken(): Promise<void> {
        const refreshToken = window.localStorage.getItem(refreshTokenKey)
        if (refreshToken !== undefined) {
            try {
                const response = await this.loginApi.post("/token/refresh/", {
                    refresh: refreshToken,
                }
                )
                const tokens = response.data as Tokens
                this.storeTokens(tokens)
            } catch (e: any) {
                console.log(e)
                this.logout()
            }
        } else {
            console.log("No refresh token found")
            this.logout()
        }
    }

    private storeTokens(tokens: Tokens) {
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + tokens.access
        window.localStorage.setItem(accessTokenKey, tokens.access)
        window.localStorage.setItem(refreshTokenKey, tokens.refresh)
    }

    async changePassword(id: number, oldPassword: string, newPassword: string): Promise<void> {
        await this.api.post(`/users/${id}/change-password/`, {
            old_password: oldPassword,
            new_password: newPassword
        })
    }

    async getMe(): Promise<User> {
        const response = await this.api.get("/users/me/")
        return new User(response.data as UserContract)
    }

    async getRequest(id: number): Promise<ProcurementRequest> {
        const response = await this.api.get(`/procurement-requests/${id}/`)
        return ProcurementRequest.fromBackendData(response.data)
    }

    async getRequests(status?: ProcurementRequestState): Promise<ProcurementRequest[]> {
        const response = await this.api.get(`/procurement-requests/${status ? `?status=${status}` : ""}`)

        const procurementRequests: ProcurementRequestContract[] = await response.data

        return procurementRequests.map((item => {
            return ProcurementRequest.fromBackendData(item)
        }))
    }

    async getComments(procurementRequestId: number): Promise<Comment[]> {
        const response = await this.api.get(`/procurement-requests/${procurementRequestId}/comments/`)
        const comments: CommentContract[] = response.data

        return comments.map(comment => BackendApiProvider.mapComment(comment))
    }

    async postComment(procurementRequestId: number, message: string): Promise<void> {
        return await this.api.post(`/procurement-requests/${procurementRequestId}/comments/`, { "message": message })
    }

    async deleteComment(commentId: number): Promise<void> {
        return await this.api.delete(`/comments/${commentId}/`)
    }

    // upload a document to the backend (optional connect it to a procurement request)
    async postDocument(files: FileList | null, procurementRequestId?: number): Promise<Document[]> {
        if (!files) {
            return []
        }
        return Promise.all(Array.from(files).map(async (file) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('title', file.name)
            if (procurementRequestId !== undefined) {
                formData.append('procurement_request', procurementRequestId as unknown as string ?? "")
            }
            const response = await this.api.post(`/documents/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return this.mapDocument(response.data)
        }))
    }

    async deleteDocument(documentId: number): Promise<void> {
        return await this.api.delete(`/documents/${documentId}/`)
    }

    async executeRobotAction(commentId: number, action: string): Promise<void> {
        return await this.api.post(`/comments/${commentId}/robot-action/`, { "action": action })
    }

    private mapDocument(backendDocument: DocumentContract): Document {
        return new Document(backendDocument.id, backendDocument.title, backendDocument.file)
    }

    private static mapComment(backendComment: CommentContract): Comment {
        if (backendComment.type === "user") {
            return new UserComment(
                backendComment.id,
                backendComment.message,
                new User(backendComment.created_by),
                new Date(backendComment.created_at)
            )
        } else if (backendComment.type === "robot") {
            return new RobotComment(
                backendComment.id,
                backendComment.message,
                new User(backendComment.created_by),
                new Date(backendComment.created_at)
            )
        }

        throw Error("Unknown Comment Type")
    }

    async createRequest(request: {
        properties: { key: string, value: string }[],
        vendor: string | undefined,
        description: string
    }): Promise<ProcurementRequest> {
        const response = await this.api.post(`/procurement-requests/`, request)

        return response.data as ProcurementRequest
    }

    async updateRequest(
        id: number, 
        request: ProcurementRequestUpdateContract
        ): Promise<ProcurementRequest> {
        const response = await this.api.patch(`/procurement-requests/${id}/`, request)

        return response.data as ProcurementRequest
    }

    async acceptRequest(id: number, newVendor?: CreateVendorWithCloseDataContract, vendorId?: string, addProduct?: AddProductDataContract): Promise<ProcurementRequest> {
        const response = await this.api.post(`/procurement-requests/${id}/close/`, {
            status: "approved",
            new_vendor: newVendor,
            vendor_id: vendorId,
            new_product: addProduct,
        })
        return ProcurementRequest.fromBackendData(response.data)
    }

    async cancelRequest(id: number, conclusion: string): Promise<ProcurementRequest> {
        const response = await this.api.post(`/procurement-requests/${id}/close/`, {
            status: "canceled",
            conclusion: conclusion
        })
        return ProcurementRequest.fromBackendData(response.data)
    }

    async getPropertyConfigs(): Promise<PropertyConfigContract[]> {
        const response = await this.api.get(`/properties/`)
        return response.data as PropertyConfigContract[]
    }

    async getTasks(procurement_request: number): Promise<TaskList> {
        const response = await this.api.get(`/procurement-requests/${procurement_request}/tasks/`)
        const data = response.data as BaseTaskContract[]
        return TaskList.fromBackendData(data)
    }

    async executeIntegrationTask(taskId: number): Promise<void> {
        const response = await this.api.post(`/tasks/${taskId}/execute/`, {})
        if (response.status !== 204) {
            throw new Error(`Failed to execute integration task with ID ${taskId}.`)
        }
    }

    async getTemplateTasks(): Promise<TaskTemplateContract[]> {
        const response = await this.api.get(`/task-templates/`)
        return response.data as TaskTemplateContract[]
    }

    // get form types from backend
    async getFormTypes(): Promise<{ key: string, name: string, id: number }[]> {
        const response = await this.api.get(`/forms/`)
        return response.data as FormContract[]
    }

    async getForm(key: string): Promise<CompoundFormElement> {
        const response = await this.api.get(`/forms/${key}/`)
        // TODO add name to form already in backend
        return await CompoundFormElement.fromJson(response.data.data)
    }

    async getFormByParams(type: "create" | "update", vendorId?: string, productId?: string): Promise<CompoundFormElement> {
        const response = await this.api.post(`/forms/by-params/`, {
            type: type,
            vendor_id: vendorId,
            product_id: productId,
        })

        // set which information is already selected (will lead to different form)
        const preset: { [key: string]: string } = {}
        if (vendorId) {
            preset["vendor"] = vendorId
        }
        if (productId) {
            preset["product"] = productId
        }
        // TODO add name to form already in backend
        return await CompoundFormElement.fromJson(response.data.data, preset)
    }

    async getUsers(): Promise<User[]> {
        const response = await this.api.get(`/users/`)

        return response.data.map((user: UserContract) => new User(user))
    }

    async search(query: string, category?: string, subCategory?: string): Promise<Vendor[]> {
        const response = await this.api.get(`/vendors/search/`, {
            params: {
                q: query,
                category: category,
                sub_category: subCategory
            }
        })
        return (response.data as VendorContract[]).map(Vendor.fromContract)
    }

    async autocomplete(query: string, category?: string, subCategory?: string): Promise<Vendor[]> {
        const response = await this.api.get(`/vendors/autocomplete/`, {
            params: {
                q: query,
                category: category,
                sub_category: subCategory
            }
        })
        return (response.data as VendorContract[]).map(Vendor.fromContract)
    }

    async getVendors(): Promise<Vendor[]> {
        const response = await this.api.get(`/vendors/`)
        const data = response.data as VendorContract[]

        return data.map(Vendor.fromContract)
    }

    async getVendorsPaged(page: number, pageSize: number = 100): Promise<PagedResult<Vendor>> {
        const response = await this.api.get(`/vendors/?page=${page}&page_size=${pageSize}`)
        const data = response.data as PagedResult<VendorContract>

        return {
            page: data.page,
            page_size: data.page_size,
            total: data.total,
            items: data.items.map(Vendor.fromContract)
        }
    }

    async getVendor(id: string): Promise<Vendor> {
        const response = await this.api.get<VendorContract>(`/vendors/${id}/`)
        return Vendor.fromContract(response.data)
    }

    async addVendor(vendor: Vendor): Promise<Vendor> {
        const response = await this.api.post(`/vendors/`, {
            name: vendor.name,
            description: vendor.description,
            website: vendor.website,
            icon_url: vendor.iconUrl,
            category: vendor.category,
            sub_category: vendor.subCategory,
            vendor_relation: vendor.vendorRelation,
        })
        return response.data as Vendor
    }

    async updateVendor(id: string, vendor: Vendor): Promise<Vendor> {
        const response = await this.api.put(`/vendors/${id}/`, {
            name: vendor.name,
            icon_url: vendor.iconUrl,
            description: vendor.description,
            website: vendor.website,
            category: vendor.category,
            sub_category: vendor.subCategory,
            vendor_relation: vendor.vendorRelation,
        })
        return response.data as Vendor
    }

    async addProductToVendor(vendorId: string, product: Product): Promise<Product> {
        const response = await this.api.post(`/vendors/${vendorId}/products/`, product)
        return response.data as Product
    }

    async deleteProductFromVendor(productId: string): Promise<void> {
        await this.api.delete(`/vendors/products/${productId}/`)
    }


    async getCategories(): Promise<Category[]> {
        const response = await this.api.get("/categories/")
        return response.data
    }

    logout(): void {
        console.log("Logging out")
        this.userName = undefined
        window.localStorage.removeItem(userKey)
        window.localStorage.removeItem(accessTokenKey)
        window.localStorage.removeItem(refreshTokenKey)

        delete this.api.defaults.headers.common['Authorization']

        if (this.loggedOutDelegate !== undefined) {
            this.loggedOutDelegate()
        }
    }

    async setTaskState(taskId: number, state: TaskState): Promise<BaseTask> {
        const response = await this.api.patch(`/tasks/${taskId}/`, { state: state })
        return createTaskFromData(response.data)
    }

    async addTask(item: Partial<BaseTaskContract> | AddTaskByTemplateContract): Promise<BaseTask> {
        const response = await this.api.post(`/tasks/`, item)
        return createTaskFromData(response.data)
    }

    async deleteTask(id: number): Promise<void> {
        await this.api.delete(`/tasks/${id}/`)
    }

    async editTask(task: Partial<BaseTaskContract> & { id: number }): Promise<BaseTask> {
        const response = await this.api.patch(`/tasks/${task.id}/`, task)
        return createTaskFromData(response.data as BaseTaskContract)
    }

    async getProcurementRequestTemplates(): Promise<ProcurementRequestTemplate[]> {
        const response = await this.api.get('/procurement-request-templates/')
        return response.data.map((template: ProcurementRequestTemplateContract) => new ProcurementRequestTemplate(template))
    }
}

let baseURL: string
let tenant: string
// Production is extracting the tenant from the hostname, development is set via .env
if (process.env.REACT_APP_BACKEND_STAGE === "production") {
    tenant = window.location.hostname.split(".")[0]
    baseURL = process.env.REACT_APP_BACKEND_URL!.replace("{tenant}", tenant)
} else {
    baseURL = process.env.REACT_APP_BACKEND_URL!
    tenant = baseURL.split(".")[0].replace("https://", "").replace("http://", "")
}

export const staticFilesURL = `https://trado-backend-django-static.s3.eu-central-1.amazonaws.com/static/${tenant}/`


export interface PagedResult<T> {
    page: number
    page_size: number
    total: number
    items: T[]

}

export const backendApiProvider: BackendApiProvider = new BackendApiProvider()
