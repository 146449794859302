/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useContext, useEffect} from "react"
import Grid from "@mui/material/Grid"
import {TextField, Tooltip, Typography} from "@mui/material"
import {FreeTextQuestion} from "../questions"
import {Help} from "@mui/icons-material"
import {FormContext, FormElementProps} from "../FormContent"
import {useSavableState} from "../util"

export default function Freetext({
                                     formElement,
                                     hasValidInput,
                                 }: FormElementProps<FreeTextQuestion>) {
    const {form, formElementState} = useContext(FormContext)!

    const [input, setInput] = useSavableState(formElement)
    useEffect(() => {
        hasValidInput(true)
    })

    function update(text: string) {
        setInput(text)
        hasValidInput(true)
        formElement.save(form, text)
        formElementState.save(formElement, "input", text)
    }

    return (
        <div>
            <Grid container flexDirection={"column"} paddingTop={2}>
                <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    paddingBottom={2}
                    sx={{width: "100%"}}
                ></Grid>
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: "100%"}}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        {formElement.title}
                    </Typography>
                    {formElement.id === "additional_info"?
                        <Tooltip title={formElement.info} placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                        : null}
                </Grid>
                    <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Grid container sx={{width: "100%"}}>
                        <TextField
                            id="filled-multiline-static"
                            label="Gib deine Antwort hier ein"
                            multiline
                            rows={
                                formElement.id === "additional_info"
                                    ? 10
                                    : 4
                            }
                            //   defaultValue="Default Value"
                            variant="outlined"
                            sx={{width: "100%"}}
                            inputProps={{maxLength: 1000}}
                            helperText={`${input?.length ?? 0}/1000`}
                            defaultValue={input}
                            onChange={(event) => update(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
