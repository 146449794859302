import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from "react-router-dom"
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import "./services/i18n"
import {ThemeProvider} from "@mui/material"
import {theme} from "./theme"
import * as Sentry from "@sentry/browser";
import {BrowserTracing} from "@sentry/react";

Sentry.init({
    dsn: "https://20bff9b86b394c44a9ef1c9f4ea46176@o4504182190768128.ingest.sentry.io/4504182194634752",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_BACKEND_STAGE
});

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
