import {ProductContract, VendorContract} from "../apiContracts";

export const vendorRelations = ["preferred_vendor", "managed_contract", "contract_relation", "partner_offer", "billing_relation", "unknown"] as const
export type VendorRelation = typeof vendorRelations[number]

export class Vendor {
    id: string
    name: string
    description?: string
    website?: string
    iconUrl?: string
    category?: string
    subCategory?: string
    sources?: any[]
    products?: Product[]
    vendorRelation: VendorRelation
    customTags: { key: string, name: string }[]

    constructor(
        id: string,
        name: string,
        description: string | undefined,
        website: string | undefined,
        iconUrl: string | undefined,
        category: string | undefined,
        sub_category: string | undefined,
        sources: any[],
        products: Product[] | undefined,
        vendorRelation: VendorRelation | undefined,
        customTags: { key: string, name: string }[] | undefined
    ) {
        this.id = id
        this.name = name
        this.description = description
        this.website = website
        this.iconUrl = iconUrl
        this.category = category
        this.subCategory = sub_category
        this.sources = sources
        this.products = products
        this.vendorRelation = vendorRelation ?? "unknown"
        this.customTags = customTags ?? []
    }

    getProductById(id: string): Product | undefined {
        return this.products?.find(product => product.id === id)
    }

    static fromContract(data: VendorContract): Vendor {
        return new Vendor(
            data.id,
            data.name,
            data.description,
            data.website,
            data.icon_url,
            data.category,
            data.sub_category,
            data.sources ?? [],
            data.products?.map(product => Product.fromContract(product)),
            data.vendor_relation as VendorRelation,
            data.custom_tags
        )
    }
}

export class Product {
    id: string
    name: string
    website?: string
    description?: string
    category?: string
    subCategory?: string

    constructor(id: string, name: string, description: string, category: string, sub_category: string) {
        this.id = id
        this.name = name
        this.description = description
        this.category = category
        this.subCategory = sub_category
    }

    static fromContract(data: ProductContract): Product {
        return new Product(
            data.id,
            data.name,
            data.description,
            data?.category?.name,
            data?.sub_category?.name
        )
    }
}

export interface Category {
    id: string
    name: string
    sub_categories: { id: string, name: string }[]
}
