import {Box, CircularProgress} from "@mui/material"
import React from "react"

export function Progress() {
    return (
        <Box
            width="100%"
            height="100%"
            alignItems="center"
            display="flex"
            justifyContent="center">
            <CircularProgress/>
        </Box>
    )
}
