import React, {memo} from 'react'
import {Handle, Position} from 'reactflow'
import {NodeProps} from "@reactflow/core/dist/esm/types/nodes"
import {Box, Grid, Paper, SvgIcon, Tooltip, Typography} from '@mui/material'
import {nodeHeight, nodeWidth} from "./WorkflowGraph"
import {BaseTask, Task} from "../api/models/tasks";


export const WorkflowNodeComponent = memo(({data, isConnectable, selected}: NodeProps<BaseTask>) => {

    let d: string = ""
    let fillColor: string = "#505050"
    switch (data.state) {
        case "not_started":
            fillColor = "#505050"
            d = "M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"
            break
        case "ready_to_start":
            fillColor = "#F09122"
            d = "M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"
            break
        case "completed":
            fillColor = "#179F55"
            d = "M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
            break
    }

    // const maxSize = useStore((store) => {
    //     return Math.max(...(store.getNodes().map((node) => {
    //         return node.width ?? 0
    //     })))
    // })


    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                style={{left: 0, background: 'transparent', border: "0px"}}
                isConnectable={isConnectable}/>

            <Paper variant="outlined" sx={{
                border: selected ? 4 : 1.5,
                borderColor: selected ? fillColor === "#505050" ? "blue" : fillColor : "grey",
                borderRadius: 1,
                p: 1,
                width: nodeWidth,
                height: nodeHeight
            }}>
                <Box display="flex" alignItems="center" paddingBottom="5px">
                    <Grid container width="20px" height="20px" borderRadius="50%" mr={1} bgcolor={fillColor}
                          sx={{justifyContent: "center", alignItems: "center"}}>
                        <SvgIcon sx={{color: "white", width: 20, height: 20}}>
                            <path d={d}/>
                        </SvgIcon>
                    </Grid>
                </Box>

                <Typography fontSize={13} fontWeight={"bold"} color="textPrimary" >
                    {data.title}
                </Typography>
                {data instanceof Task && <Tooltip title={data.assignee?.name ?? "Unassigned"}>
                    <Typography fontSize={11} color="textSecondary" noWrap>
                        {data.assignee?.name ?? "Unassigned"}
                    </Typography>
                </Tooltip>}

            </Paper>

            <Handle
                type="source"
                position={Position.Right}
                style={{right: 0, background: 'transparent', border: "0px"}}
                isConnectable={isConnectable}/>
        </>
    )
})
