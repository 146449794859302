import {Grid, Stack,} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import {Circle, KeyboardDoubleArrowRightOutlined} from "@mui/icons-material"
import {DetailsList} from "../AllPropertyDetails"
import {TaskList} from "../../api/models/tasks";
import {ProcurementRequest, ProcurementRequestUpdate} from "../../api/models/procurementRequest";

export default function Sidebar(props: {
    procurementRequest: ProcurementRequest,
    tasks?: TaskList,
    saveRequestUpdate: (ProcurementRequestUpdate: ProcurementRequestUpdate) => void,
    openSideBarRight: boolean,
    setOpenSideBarRight: (value: boolean) => void
    reloadProcurementRequest: () => void
}) {
    return (
        <Grid
            item
            xs={12}
            md={12}
            sx={{
                borderTopLeftRadius: 20,
                borderTopRightRadius: {xs: 20, md: 0},
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: {xs: 20, md: 0},
                boxShadow: 3,
                marginTop: {xs: 2, md: 0},
                bgcolor: "white",
                height: "100%"
            }}
        >
            <Stack alignItems={"flex-start"} sx={{paddingTop: 2, paddingLeft: 0.5, backgroundColor: "white"}}>
                <KeyboardDoubleArrowRightOutlined onClick={() => props.setOpenSideBarRight(false)} sx={{
                    marginLeft: 2, width: 30, height: 30, "&:hover": {
                        cursor: "pointer",
                    }, color: "blue",

                }}/>

            </Stack>
            <DetailsList procurementRequest={props.procurementRequest} taskList={props.tasks} propertyOrder={1} reloadRequest={props.reloadProcurementRequest}/>
        </Grid>
    )
}

export function StatusText(props: { procurementRequest: ProcurementRequest | undefined }) {
    return (
        <div>
            {(() => {
                switch (props.procurementRequest?.status) {
                    case "open":
                        return (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Circle sx={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 10,
                                    color: "orange"
                                    ,
                                    marginRight: 1
                                }}></Circle>
                                <Typography sx={{fontWeight: "bold", marginRight: 2}}
                                            variant={"body2"}>Ticket in Bearbeitung</Typography>
                            </Stack>
                        )
                    case "approved":
                        return (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Circle sx={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 10,
                                    color: "green"
                                    ,
                                    marginRight: 1
                                }}></Circle>
                                <Typography sx={{fontWeight: "bold", marginRight: 2}}
                                            variant={"body2"}>Ticket erfolgreich abgeschlossen</Typography>
                            </Stack>
                        )
                    case "canceled":
                        return (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Circle sx={{
                                    width: 20,
                                    height: 20,
                                    borderRadius: 10,
                                    color: "#FA5F55"
                                    ,
                                    marginRight: 1
                                }}></Circle>
                                <Typography sx={{fontWeight: "bold", marginRight: 2}}
                                            variant={"body2"}>Ticket storniert</Typography>
                            </Stack>
                        )
                }
            })()}
        </div>

    )
}
