import * as React from "react"
import {useMemo} from "react"
import List from "@mui/material/List"
import ListSubheader from "@mui/material/ListSubheader"
import {
    Autocomplete,
    AutocompleteChangeReason,
    Divider,
    Input,
    ListItemButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Tooltip
} from "@mui/material"
import ListItemText from "@mui/material/ListItemText"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import {ProcurementRequest, PropertyGroup, PropertyValue} from "../../api/models/procurementRequest";
import {ProcurementRequestUpdateContext} from "../AllPropertyDetails"
import {useTranslation} from "react-i18next"

export function DetailProperties(props: { procurementRequest: ProcurementRequest }) {
    const defaultProperties = useMemo(() => {
        return props.procurementRequest?.getPropertiesByOrder(0)
    }, [props.procurementRequest])

    const [open, setOpen] = React.useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    return <List
        sx={{width: "100%", bgcolor: "background.paper"}}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
            <ListSubheader
                component="div"
                id="nested-list-subheader"
            ></ListSubheader>
        }
    >
        <ListItemButton
            onClick={handleClick}
            selected={open}
            dense
            sx={{
                "&": {},
                "&.Mui-selected": {
                    color: "#0000FF"
                }
            }}
        >
            <ListItemText
                primary="Details"
                primaryTypographyProps={{fontWeight: "bold"}}
            />
            {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <DefaultPropertiesItems items={defaultProperties?.default}/>
                {defaultProperties?.groups?.map(group => {
                    return <GroupPropertiesItems group={group} items={group.items}/>
                })}
            </List>
        </Collapse>
    </List>
}

export function DefaultPropertiesItems(props: { items: { [key: string]: PropertyValue } }) {
    return <>
        {Object.entries(props.items)?.map(([key, value]: [string, PropertyValue], index: number) => {
            return <PropertyListItem propertyKey={key} key={index}/>
        })}
    </>
}

export function PropertyListItem(props: { propertyKey: string }) {

    const {t} = useTranslation()

    const {
        editMode,
        procurementRequestUpdate,
        handleFieldChange,
        propertyConfigs
    } = React.useContext(ProcurementRequestUpdateContext)

    const value = procurementRequestUpdate.properties.values[props.propertyKey]
    const config = propertyConfigs?.find(config => config.key === props.propertyKey)

    return <ListItem key={props.propertyKey} dense>
        <ListItemText
            style={{ wordWrap: "break-word" }}
            primary={value.name !== "" && value.name !== undefined ? value.name : props.propertyKey}
            primaryTypographyProps={{
                fontWeight: "bold",
                noWrap: false // Add this line to enable multiline wrapping
            }}
            sx={{width: "50%"}}
        />
        {
            editMode ?
                config ?
                    config.type === "string" ?
                        <PropertyEditString value={value.value}
                                            onChange={(value: string) => handleFieldChange("property", {
                                                key: props.propertyKey,
                                                value: value
                                            })}/>
                        :
                        config.type === "number" ?
                            <PropertyEditNumber value={value.value}
                                                onChange={(value: string) => handleFieldChange("property", {
                                                    key: props.propertyKey,
                                                    value: value
                                                })}/>
                            :
                            config.type === "boolean" ?
                                <PropertyEditBoolean value={value.value}
                                                     onChange={(value: string) => handleFieldChange("property", {
                                                         key: props.propertyKey,
                                                         value: value
                                                     })}/>
                                :
                                config.type === "select" ?
                                    <PropertyEditSelect
                                        property={value}
                                        onChange={(value: string) => handleFieldChange("property", {
                                            key: props.propertyKey,
                                            value: value
                                        })}
                                        options={config.configuration?.options ?? []}
                                    />
                                    :
                                    <Typography sx={{minWidth: 300}}>
                                        {value.value}
                                    </Typography>
                    :
                    null
                :
                <Tooltip
                    title={value.value}
                    placement="top-end"
                >
                    <Typography
                        textAlign={"right"}
                        fontSize={14}
                        sx={{width: "50%"}}
                    >
                        {value.value == "True" && t("general.boolean.true")}
                        {value.value == "False" && t("general.boolean.false")}
                        {(value.value !== "False" && value.value !== "True") && (value.translation ?? value.value)}
                    </Typography>
                </Tooltip>
        }

    </ListItem>
}

function PropertyEditString(props: { value: string, onChange: (value: string) => void }) {
    const [value, setValue] = React.useState(props.value)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        props.onChange(event.target.value)
    };

    return <TextField
        sx={{minWidth: 300}}
        value={value}
        onChange={handleChange}
    />
}

function PropertyEditNumber(props: { value: string, onChange: (value: string) => void }) {
    const [value, setValue] = React.useState(props.value)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
        props.onChange(event.target.value)
    }
    return <TextField
        sx={{minWidth: 300}}
        value={value}
        onChange={handleChange}
        type="number"
    />
}

function PropertyEditBoolean(props: { value: string, onChange: (value: string) => void }) {
    const [value, setValue] = React.useState(props.value == "True" ? true : false)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked)
        props.onChange(event.target.checked ? "True" : "False")
    }
    return <Switch
        checked={value}
        onChange={handleChange}
        color="primary"
    />
}

function PropertyEditSelect(props: { property: PropertyValue, onChange: (value: string) => void, options: string[] }) {
    const [selectedValue, setSelectedValue] = React.useState<string | null>(props.property.value)

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedValue(event.target.value as string)
        props.onChange(event.target.value as string)
    }

    return <Select
        sx={{width: 300}}
        onChange={handleChange}
        value={selectedValue ?? undefined}
    >
        {
            props.options.map((option, index) => {
                return <MenuItem key={index} value={option}>{option}</MenuItem>
            })
        }
    </Select>
}

export function GroupPropertiesItems(props: { group: PropertyGroup, items: { [key: string]: PropertyValue } }) {
    return (
        <React.Fragment key={props.group.key}>
            <Divider/>
            <ListItem>
                <ListItemText
                    style={{ wordWrap: "break-word" }}
                    primary={props.group.name}
                    primaryTypographyProps={{
                        fontWeight: "bold",
                    }}
                />
            </ListItem>
            {Object.entries(props.items)?.map((item, index) => {
                const [key, value] = item
                return <PropertyListItem key={index} propertyKey={key}/>
            })}
        </React.Fragment>
    )
}
