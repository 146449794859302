import {
    BaseTaskContract,
    FormTaskContract,
    TaskContract,
    TaskGroupContract,
    IntegrationTaskContract,
    FormContract, TaskTemplateContract, ProcurementRequestTemplateContract
} from "../apiContracts";
import {User} from "./users";

export type TaskState = "not_started" | "ready_to_start" | "completed"
export type TaskType = "FormTask" | "Task" | "TaskGroup" | "IntegrationTask"

export class FormTemplate {
    id: number
    label: string
    key: string

    constructor(data: FormContract) {
        this.id = data.id
        this.label = data.name
        this.key = data.key
    }
}

export class ProcurementRequestTemplate {
    id: number
    title: string
    order: number
    templateTasks: TaskTemplateContract[]

    constructor(data: ProcurementRequestTemplateContract) {
        this.id = data.id
        this.title = data.title
        this.order = data.order
        this.templateTasks = data.template_tasks.map((task => createTaskFromData(task)))
    }
}

export class TaskList {
    tasks: BaseTask[]

    constructor(tasks: BaseTask[]) {
        this.tasks = tasks
    }

    static fromBackendData(data: BaseTaskContract[]): TaskList {
        return new TaskList(data.map(task => createTaskFromData(task)))
    }


    static getTasksRecursive(tasks: BaseTask[], condition: (task: Task) => boolean): BaseTask[] {
        let result: BaseTask[] = []

        for (const task of tasks) {
            if (task instanceof TaskGroup) {
                result = result.concat(TaskList.getTasksRecursive(task.subtasks, condition))
            } else if (task instanceof Task) {
                if (condition(task)) {
                    result.push(task)
                }
            }
        }

        return result
    }

    getTasksByUser(user: User): Task[] {
        return TaskList.getTasksRecursive(this.tasks, task => {
            return task.assignee?.id === user.id
        })
    }

    getFormTasks(): FormTask[] {
        return TaskList.getTasksRecursive(this.tasks, task => {
            return task instanceof FormTask
        }) as FormTask[]
    }
}

export abstract class BaseTask {
    id: number
    parent?: number
    order: number
    title: string
    state: TaskState
    resourcetype: TaskType

    constructor(data: BaseTaskContract) {
        this.id = data.id
        this.parent = data.parent
        this.order = data.order
        this.title = data.title
        this.state = data.state
        this.resourcetype = data.resourcetype
    }

    getUniqueKey() {
        return `${this.id}_${this.parent || 'null'}_${this.order}_${this.title}_${this.state}_${this.resourcetype}`;
    }

    getAllAssignees(): User[] {
        let result: User[] = []

        if (this instanceof Task) {
            if (this.assignee) {
                result.push(this.assignee)
            }
        } else if (this instanceof TaskGroup) {
            for (const task of this.subtasks) {
                result = result.concat(task.getAllAssignees())
            }
        }

        const uniqueUsers: User[] = Array.from(new Map(result.map(user => [user.id, user])).values());
        return uniqueUsers;
    }

}

export class Task extends BaseTask {
    assignee?: User

    constructor(data: TaskContract) {
        super(data)
        this.assignee = data.assignee ? new User(data.assignee) : undefined
    }
}

export class TaskGroup extends BaseTask {
    executionType: string
    subtasks: BaseTask[]

    constructor(data: TaskGroupContract) {
        super(data)
        this.executionType = data.execution_type || ''
        this.subtasks = data.subtasks ? data.subtasks.map(task => createTaskFromData(task)) : []
    }
}

export class FormTask extends Task {
    form: {
        key: string,
        name: string,
    }

    constructor(data: FormTaskContract) {
        super(data as unknown as TaskContract)
        this.form = data.form!
    }
}

export class IntegrationTask extends Task {
    email: string
    integration_type: string
    integration_config: JSON
    trigger: string

    constructor(data: IntegrationTaskContract) {
        super(data as unknown as TaskContract)
        this.email = data.email!
        this.integration_config = data.integration_config!
        this.integration_type = data.integration_type!
        this.trigger = data.trigger!
    }
}


// Helper function
export function createTaskFromData(data: BaseTaskContract): BaseTask {
    switch (data.resourcetype) {
        case 'Task':
            return new Task(data as TaskContract)
        case 'TaskGroup':
            return new TaskGroup(data as TaskGroupContract)
        case 'FormTask':
            return new FormTask(data as FormTaskContract)
        case 'IntegrationTask':
            return new IntegrationTask(data as IntegrationTaskContract)
        default:
            throw new Error(`Unknown task resourcetype: ${data.resourcetype}`)
    }
}
