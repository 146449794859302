import {
    CompoundFormElement, Form, FormElement,
    Question,
} from "./questions"
import React, {createContext, forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef} from "react"
import {getIndexOfNextFormElement, getIndexOfPrevFormElement, removeFromAnswers, UIStates} from "./util"
import {FormTerminal} from "./FormTerminals"
import {QuestionContent} from "./QuestionContent"
import {FormTerminalContent} from "./FormTerminalContent"
import Logobar from "./components/logobar"
import {Grid} from "@mui/material"
import Dummybutton from "./components/dummybutton"
import {useQueryClient} from "@tanstack/react-query"
import {Progress} from "../ui/Progress"
import {useNavigate} from "react-router-dom"

export interface FormElementProps<T extends FormElement> {
    formElement: T
    hasValidInput: (isValid: boolean) => void
    onClose: () => void,
    preset?: { [key: string]: string }
}

export const FormContext = createContext<{ form: Form, formElementState: UIStates } | undefined>(undefined)


export function FormContainer(props: { form: Form, onClose: () => void, preselection?: { [key: string]: string } }) {
    const ref = useRef<{ onNextScreen(): Boolean, onPrevScreen(): Boolean }>(null)
    const uiStateRef = useRef(new UIStates())

    const [enabled, setEnabled] = React.useState<boolean>(false)

    function nextScreen() {
        const handled = ref.current?.onNextScreen() ?? false
        if (!handled) {
            props.onClose()
        }
    }

    function lastScreen() {
        const handled = ref.current?.onPrevScreen() ?? false
        if (!handled) {
            props.onClose()
        }
    }

    return (
        <FormContext.Provider value={{form: props.form, formElementState: uiStateRef.current}}>
            <Logobar onClose={props.onClose}></Logobar>
            <div>
                <Grid
                    container
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{height: "70vh", width: "100%"}}
                >
                    <Fragment key={props.form.root.id}>
                        <FormElementContent
                            ref={ref}
                            formElement={props.form.root}
                            hasValidInput={setEnabled}
                            onClose={props.onClose}
                            preset={props.preselection}
                        />
                    </Fragment>
                </Grid>
                <Grid item>
                    <Dummybutton
                        lastScreen={lastScreen}
                        nextScreen={nextScreen}
                        enabled={enabled}
                    ></Dummybutton>
                </Grid>
            </div>
        </FormContext.Provider>
    )
}

export const FormElementContent = forwardRef(function FormElementContent(props: FormElementProps<any>, ref) {
    if (props.formElement instanceof FormTerminal) {
        return <FormTerminalContent {...props}/>
    } else if (props.formElement instanceof Question) {
        return <QuestionContent {...props}/>
    } else if (props.formElement instanceof CompoundFormElement) {
        return <CompoundFormContent
            ref={ref}
            {...props}/>
    }

    return <></>
})

export const CompoundFormContent = forwardRef(function CompoundFormContent(props: FormElementProps<CompoundFormElement>, ref) {
    const childRef = useRef<{ onNextScreen(): Boolean, onPrevScreen(): Boolean }>(null)
    const navigate = useNavigate()

    const {form, formElementState} = useContext(FormContext)!
    const queryClient = useQueryClient()

    const {formElement} = props

    const [currentIndex, setCurrentIndex] = React.useState((formElementState.get(formElement, "currentIndex") as number) ?? 0)
    const [isLoading, setIsLoading] = React.useState(false)

    useEffect(() => {
        const loadData = async () => {
            const firstElement = props.formElement.formElements[0]
            if (firstElement !== undefined && firstElement.load !== undefined) {
                setIsLoading(true)
                await firstElement.load(form.answers, queryClient)
                setIsLoading(false)
            }
        }
        loadData()
    }, [])

    useImperativeHandle(ref, () => ({
            onNextScreen(): boolean {
                const handled = childRef.current?.onNextScreen() ?? false
                if (!handled) {
                    const currentElement = formElement.formElements[currentIndex]
                    if (currentElement instanceof FormTerminal) {
                        const closeAction = (currentElement as FormTerminal).closeAction
                        closeAction ? closeAction(form.answers, navigate) : props.onClose()
                        return true
                    } else {
                        const nextIndex = getIndexOfNextFormElement(currentIndex, props.formElement.formElements, form.answers)
                        if (nextIndex !== undefined) {
                            const nextElement = formElement.formElements[nextIndex]
                            if (nextElement.load !== undefined) {
                                const load = async () => {
                                    setIsLoading(true)
                                    await nextElement.load?.(form.answers, queryClient)
                                    setIsLoading(false)
                                }
                                load()
                            }

                            setCurrentIndex(nextIndex)
                            formElementState.save(formElement, "currentIndex", nextIndex)
                            return true
                        } else {
                            return false
                        }
                    }
                } else {
                    return true
                }
            },

            onPrevScreen(): boolean {
                const handled = childRef.current?.onPrevScreen() ?? false
                if (!handled) {
                    const prevIndex = getIndexOfPrevFormElement(currentIndex, props.formElement.formElements, form.answers)
                    if (prevIndex !== undefined) {
                        removeFromAnswers(currentIndex, prevIndex, form, props.formElement.formElements)

                        setCurrentIndex(prevIndex)
                        formElementState.save(formElement, "currentIndex", prevIndex)
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }
        })
    )

    if (isLoading) {
        return <Progress/>
    } else {
        const childFormElement: FormElement | undefined = props.formElement.formElements[currentIndex]
        if (childFormElement !== undefined) {
            return <React.Fragment key={childFormElement.id}>
                <FormContext.Provider value={{form: form, formElementState: formElementState.createUIStates(formElement)}}>
                    <FormElementContent
                        ref={childRef}
                        {...props}
                        formElement={childFormElement}
                        preset={props.preset}/>
                </FormContext.Provider>
            </React.Fragment>
        } else {
            return <></>
        }
    }
})
