import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { useQuery } from '@tanstack/react-query'
import { apiProvider } from "../api/api"
import { Avatar, Button, Chip, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import { getReadableCategories } from "./util"
import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import { AvailabilityStatusChip } from "../search/SearchDetails"
import { Vendor, VendorRelation } from "../api/models/vendors";
import { useAuth } from "../auth/auth"
import VendorForm from "./VendorForm"


export const VendorList = () => {
    const { t } = useTranslation()
    let auth = useAuth()

    const columns: GridColDef[] = [
        {
            field: "icon",
            headerName: "Icon",
            editable: false,
            flex: 0.6,
            renderHeader: () => <strong>{t("vendors.table.logo")}</strong>,
            renderCell: (params: GridRenderCellParams<string>) => {
                if (params.value) {
                    return (
                        (<Avatar
                            alt={"No image"}
                            src={params.value}
                            sx={{ width: 40, height: 40 }}
                        >
                            <Stack direction={"column"}>
                                <Typography fontSize={8} textAlign={"center"}>No</Typography>
                                <Typography fontSize={8} textAlign={"center"}>image</Typography>
                            </Stack>
                        </Avatar>)

                    )
                } else {
                    return (
                        <>
                            <Avatar sx={{ width: 40, height: 40 }}>
                                <Stack direction={"column"}>
                                    <Typography fontSize={8} textAlign={"center"}>No</Typography>
                                    <Typography fontSize={8} textAlign={"center"}>image</Typography>
                                </Stack>
                            </Avatar>
                        </>
                    )
                }
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            editable: false,
            renderHeader: () => <strong>{t("vendors.table.name_title")}</strong>,
        },
        {
            field: "category",
            headerName: "Category",
            renderHeader: () => <strong>{t("vendors.table.category_title")}</strong>,
            flex: 1,
            editable: false,
            renderCell: (params: GridRenderCellParams<string>) => {
                if (params.value) {

                    return (
                        <Grid container flexDirection="column" sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
                            <Stack direction="row" spacing={1}>
                                <Tooltip title={params.value.substring(params.value.indexOf(">") + 1)}
                                    placement="bottom-end">
                                    <Chip
                                        label={params.value.substring(params.value.indexOf(">") + 1)}
                                        variant="outlined"
                                        size="small"
                                        color={"primary"}
                                        sx={{ marginBottom: 0.2, marginTop: 0.2 }}
                                    />
                                </Tooltip>
                            </Stack>

                        </Grid>
                    )

                } else {
                    return <></>
                }
            },
        },
        {
            field: "vendorRelation",
            headerName: "Tags",
            renderHeader: () => <strong>{t("vendors.table.status_title")}</strong>,
            flex: 1,
            editable: false,
            renderCell: (params: GridRenderCellParams<VendorRelation>) => {
                return (

                    <AvailabilityStatusChip availabilityStatus={params.value} />
                )

            },
        },
    ]

    const navigate = useNavigate()

    const [page, setPage] = useState(0)

    const { data, isLoading, refetch } = useQuery(["vendors", "page", page], async () => await apiProvider.getVendorsPaged(page))

    const rows = data?.items?.map((vendor) => {
        return {
            id: vendor.id,
            icon: vendor.iconUrl,
            name: vendor.name,
            category: getReadableCategories(vendor),
            vendorRelation: vendor.vendorRelation,
        }
    })

    const [pageConfig, setPageConfig] = useState(
        {
            pageSize: data?.page_size || 0,
            total: data?.total || 0
        },

    )

    const [newVendorFormOpen, setNewVendorFormOpen] = useState(false)

    const handleNewVendorFormClose = () => {
        setNewVendorFormOpen(false)
    }

    const onNewVendorFormSubmit = (vendor: Vendor) => {
        apiProvider.addVendor(vendor)
        refetch()
        setNewVendorFormOpen(false)
    }

    useEffect(() => {
        setPageConfig((prevPageSize) =>
            data !== undefined
                ? {
                    pageSize: data.page_size,
                    total: data.total
                }
                : prevPageSize,
        )
    }, [data?.page_size, setPageConfig])

    return (
        <div style={{ display: "flex", height: "100%", marginTop: 40 }}>
            <VendorForm
                mode="add"
                vendor={new Vendor(
                    "",
                    "",
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    [],
                    undefined,
                    undefined,
                    undefined,
                )}
                open={newVendorFormOpen}
                onClose={handleNewVendorFormClose}
                onSave={onNewVendorFormSubmit}
            />
            <div style={{ flexGrow: 1 }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        marginLeft={3}
                        marginBottom={2}>
                        {t("vendors.title")}
                    </Typography>
                    {
                        auth.user?.hasChangeVendorInfoPermission() ?
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setNewVendorFormOpen(true)}
                                sx={{ marginRight: 5, marginBottom: 2 }}
                            >
                                {t("vendors.add")}
                            </Button>
                            :
                            <></>
                    }
                </Stack>
                <DataGrid
                    sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        }, backgroundColor: "white"
                    }}
                    loading={isLoading}
                    rows={rows !== undefined ? rows : []}
                    onRowClick={(params) => navigate(`/vendors/${params.id}`)}
                    columns={columns}
                    pageSize={pageConfig.pageSize}
                    rowsPerPageOptions={[pageConfig.pageSize]}
                    pagination
                    paginationMode="server"
                    onPageChange={(page) => setPage(page)}
                    rowCount={pageConfig.total}
                    disableSelectionOnClick
                    disableColumnMenu={true}
                />
            </div>
        </div>
    )
}