/* eslint-disable react/jsx-no-comment-textnodes */
import { useState} from "react"
import Grid from "@mui/material/Grid"
import {Box, Button, ListItemButton, Stack, Typography,} from "@mui/material"
import {AutoStories, Clear, Delete, Description, Remove} from "@mui/icons-material"
import {apiProvider} from "../../api/api"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {Document} from "../../api/models/procurementRequest";
import { useTranslation } from "react-i18next"


/**
 * Component for document upload in multiple request forms
 * @param props 
 * @returns 
 */
export default function UploadFromElement(props: { documents: string | undefined, setDocuments: (value: string) => void }) {

    const {t} = useTranslation()

    const [result, setResult] = useState<Document[]>([])
    const [documents, setDocuments] = useState<Document[]>([])


    const handleDeleteDocument = (document: Document) => {
        const newDocuments = documents.filter((doc) => doc.id !== document.id)
        setDocuments(newDocuments)
        props.setDocuments(newDocuments.map(document => document.id).join(","))
        apiProvider.deleteDocument(document.id)
    }

    return (<Stack direction={"column"}>
            <Typography sx={{fontWeight: "bold", width: "100%", marginLeft: 2}}> {t("request_form.documents")}</Typography>
            <Grid
                container
                border={1}
                borderColor={"#C9C9C9"}
                sx={{m: 0.7, width: 600, maxWidth: {xs: 400, md: 600}}}
                margin={1}
                marginLeft={2}
                borderRadius="5px"
                height={"55px"}
                flexDirection={"row"}
                alignItems={"center"}
                paddingLeft={1.5}
            >
                <Box>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        size="small"
                    >
                        {t("request_form.document_upload")}
                        <input hidden accept="pdf/*" multiple type="file"
                               onChange={async (event) => {
                                   const response = await apiProvider.postDocument(event.target.files)
                                    setDocuments([...documents, response[0]])
                                   props.setDocuments([props.documents?.split(","), response.map(document => document.id)].join(","))
                                   setResult(response)
                               }}/>
                    </Button>
                </Box>
            </Grid>
            <Grid
                container
                sx={{m: 0, width: 600, maxWidth: {xs: 400, md: 600}}}
                height={"55px"}
                flexDirection={"row"}
                alignItems={"center"}
            >
                <Box>
                    <DocumentInfo documents={documents} removeDocument={handleDeleteDocument}/>
                </Box>
            </Grid>
        </Stack>
    )
}

function DocumentInfo(props: { documents: Document[] | undefined, removeDocument: (document: Document) => void }) {
    if (props.documents === undefined) {
        return <Stack></Stack>
    }
    return <Stack direction="column" sx={{justifyContent: 'flex-start'}}>
        {props.documents.map((document) => (
            <ListItem key={document.title}>
                <ListItemButton component="a" href={document.file} target={"_blank"}>
                    <ListItemIcon>
                        <Description/>
                    </ListItemIcon>
                    <ListItemText
                        primary={document.title}
                        sx={{textDecoration: 'underline', color: 'blue'}}
                    />
                </ListItemButton>
                <Clear sx={{cursor: "pointer", color: "gray", '&:hover': {color: "black"} }}onClick={() => {props.removeDocument(document)}}/>
            </ListItem>
        ))}
    </Stack>
}