import {isSameDay, format, subDays} from 'date-fns'
import i18n from "../services/i18n"

export function humanReadableDate(date: Date) {
    const today = new Date()
    const yesterday = subDays(today, 1)

    let result = ''
    if (isSameDay(date, today)) {
        result = i18n.t("general.date.today")
    } else if (isSameDay(date, yesterday)) {
        result = i18n.t("general.date.yesterday")
    } else if (date.getFullYear() === (new Date()).getFullYear()) {
        result = `${format(date, 'dd MMM')}`
    } else {
        result = `${format(date, 'dd MMM YY')}`
    }

    return result
}
