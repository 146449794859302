/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"
import Grid from "@mui/material/Grid"
import {Stack, Typography} from "@mui/material"
import {ExternalLinkTerminal} from "../FormTerminals"
import {FormElementProps} from "../FormContent"
import Button from "@mui/material/Button"

export default function ExternalLinkTerminalContent({
                                                        formElement,
                                                    }: FormElementProps<ExternalLinkTerminal>) {
    return (
        <div>
            <Grid container columns={12} flexDirection={"column"} paddingTop={4}>
                <Grid item flexDirection={"row"}>
                    <Typography
                        sx={{
                            fontSize: {xs: 24, md: 26, xl: 30},
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Hier kannst du direkt bei unserem Partner bestellen
                    </Typography>
                </Grid>
                <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                ></Grid>
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Stack
                        width={500}
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography
                            sx={{
                                fontSize: {xs: 12, md: 16, xl: 18},
                                textAlign: "center",
                            }}
                        >
                            {formElement.description}
                        </Typography>
                        <Button
                            onClick={() => {
                                if (formElement.link?.startsWith("mailto:")) {
                                    window.open(formElement.link, "_self", "noreferrer")
                                } else {
                                    window.open(formElement.link, "_blank", "noreferrer")
                                }
                            }}
                            color="primary"
                            variant="contained"
                            sx={{width: 160, height: 40, textTransform: "none"}}
                        >
                            Bestellen
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}
