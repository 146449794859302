export const TRANSLATION_DE = {
    app_name: "trado.",
    general: {
        date: {
            today: "Heute",
            yesterday: "Gestern",
        },
        actions: {
            edit: "Editieren",
            delete: "Löschen",
            save: "Speichern",
            close: "Schließen",
            cancel: "Abbrechen",
        },
        boolean: {
            true: "Ja",
            false: "Nein",
        }
    },
    menu: {
        dashboard_title: "Übersicht",
        request_list_title: "Anfragen",
        user_list_title: "Benutzer",
        vendor_list_title: "Lieferanten",
        search_list_title: "Suche",
        new_purchase_list_title: "Schnellstart",
        create_title: "Anfrage starten",
    },
    user_menu: {
        change_password: "Passwort ändern",
        change_password_dialog: {
            title: "Passwort ändern",
            old_password: "Altes Passwort",
            new_password: "Neues Passwort",
            confirm_password: "Passwort bestätigen",
            pw_not_match: "Passwörter stimmen nicht überein",
            success: "Passwort erfolgreich geändert",
            error: "Fehler beim Ändern des Passworts",
            cancel: "Abbrechen",
            save: "Speichern",
        },
        logout: "Abmelden",
    },
    request_details: {
        heading: "Ticket Details",
        edit: "Editieren",
        delete: "Löschen",
        add: "Hinzufügen",
        add_new: "Neuen Task hinzufügen",
        add_from_template: "Von Vorlage hinzufügen",
        change_order: "Reihenfolge ändern",
        nothing_to_do: "Für dich gibt es aktuell nichts zu tun...",
        open_tasks: "Meine offenen Tasks",
        done_tasks: "Meine erledigten Tasks",
        future_tasks: "Bevorstehende Tasks",
        closed_tasks: "Abgeschlossene Tasks",
        process_flow: "Prozess-Ablauf",
        general_info: {
            title: "Allgemeine Ticket Infos",
            id_label: "Ticket ID",
        },
        close_ticket: "Ticket schließen",
        reopen_ticket: "reaktivieren",
        status_label: "Status",
        status: {
            in_progress: "In Bearbeitung",
            closed_success: "Erfolgreich abgeschlossen",
            closed_canceled: "Storniert",
        },
        close_ticket_dialog: {
            comment: "Anmerkung",
        },
        tasklist_heading: "Taskliste",
        comments_heading: "Kommentare",
        workflow_heading: "Workflow",
        details_heading: "Details",
        forms_heading: "Formulare",
        documents_heading: "Dokumente",
        no_documents_text: "Keine Dokumente vorhanden",
        upload_documents_button: "Dokumente hinzufügen",
    },
    request_form: {
        title: "Neue Anfrage",
        subtitle: "Bitte gib mehr Informationen zu deinem Bedarf",
        vendor: "Lieferant",
        vendor_preselected_newrequest: "Ändern",
        product: "Produkt | Dienstleistung",
        product_preselected_newrequest: "Ändern",
        url: "URL",
        number_of_licenses: "Anzahl Lizenzen",
        document_upload: "Dokument hochladen",
        documents: "Dokumente",
    },
    edit_dialog: {
        new_task: "Neuen Task hinzufügen",
        edit: "Task Editieren",
        close: "Abbrechen",
        save: "Speichern",
        assignee: "Zugewiesen an:",
        type: "Task-Art",
        title: "Titel",
        order: "Nummer in der Reihenfolge",
        integration_trigger: "Auslöser",
        form: "Formular",
        select_template_title: "Vorlage auswählen",
        select_template_list_item_subtitle: "Vorlage für Anfrage",
        search_template_placeholder: "Suchen",
        search_template_no_results: "Keine Vorlagen gefunden."
    },
    sidebar: {
        time_estimation: "Schätzung Dauer",
    },
    request_list: {
        title: "Anfragen",
        tabs: {
            open_requests: "Offene Anfragen",
            accepted_requests: "Abgeschlossene Anfragen",
            rejected_requests: "Stornierte Anfragen",
            closed_requests: "Abgeschlossene Anfragen"
        },
        table: {
            id_title: "ID",
            title_title: "Titel",
            category_title: "Kategorie",
            status_title: "Status",
            progress_title: "Fortschritt",
            details_title: "Erstelldatum",
            requester_title: "Ersteller",
            requester_email: "E-Mail",
        },
        status: {
            in_progress: "In Bearbeitung",
            success: "Erfolgreich abgeschlossen",
            canceled: "Storniert",
        }
    },
    user_list: {
        title: "User Liste",
        table: {
            user_name: "Name",
            user_email: "E-Mail",
            user_user_name: "User-Name",
            user_roles: "User-Rollen",
        }
    },
    vendors: {
        title: "Lieferanten",
        no_image: "Kein Bild",
        preferred: "Bevorzugt",
        add: "Neuen Lieferant erstellen",
        not_preferred: "Nicht Bevorzugt",
        edit_vendor: "Lieferant bearbeiten",
        vendor_relations: {
            preferred_vendor: "Bevorzugter Lieferant",
            managed_contract: "Gemanagter Vertrag",
            contract_relation: "Vertragsbeziehung",
            partner_offer: "In CCP verfügbar",
            billing_relation: "Rechnungsbeziehung",
            unknown: "Unbekannt",
        },
        dialog: {
            add_title: "Neuen Lieferanten erstellen",
            edit_title: "Lieferanten bearbeiten",
            name_title: "Name",
            name_subtitle: "Name des Lieferanten",
            description_title: "Beschreibung",
            description_subtitle: "Beschreibung des Lieferanten",
            icon_url_title: "Logo",
            icon_url_subtitle: "URL zum Logo",
            website_title: "Webseite",
            website_subtitle: "Webseite des Lieferanten",
            status_title: "Lieferantenstatus",
            save: "Speichern",
            cancel: "Abbrechen"
        },
        table: {
            logo: "Logo",
            name_title: "Lieferant",
            category_title: "Kategorie",
            status_title: "Status",
            edit_title: "Bearbeiten",
        },
        details: {
            general_infos: {
                title: "Unternehmensbeschreibung",
                description_title: "Beschreibung",
                webpage_title: "Webseite",
            },
            internal_infos: {
                title: "Interne Informationen",
                categories_title: "Kategorien",
                status_title: "Status",
                status_title_long: "Lieferantenstatus",
                sources_title: "Quellen:",
                tags_title: "Tags",
            },
            products: {
                title: "Produkte | Dienstleistungen",
            }
        },
        requests: {
            new_request_button: "Neues Produkt | Dienstleistung bestellen",
            product_request_button: "Bestellen"
        },
    },
    search_screen: {
        title1: `Hi `,
        title2: `, was möchtest du kaufen?`,
        search_button: "Suchen",
        search_categories: "In Kategorien suchen",
        no_results: "Keine Ergebnisse",
        nothing_found: "Nicht das Richtige gefunden?",
        new_request: "Neue Bedarfsanfrage",
        categories: {
            title: "Kategorien durchsuchen",
        },
    },
    search_details: {
        edit_vendor: `Lieferant bearbeiten `,
    },
    robots: {
        comments: {
            not_processing_personal_data_for_software: "Für diese Anfrage wurde ausgewählt, dass keinerlei personenbezogene Daten verarbeitet werden.Dies ist sehr ungewöhnlich. Bitte prüfen, ob dies korrekt ist. Soll Datenschutz zum Workflow hinzugefügt werden?",
            notify_about_existing_vendor: "Für den angefragten Lieferanten {{vendor, string}} wurde bereits eine Geschäftsbeziehung gefunden",
            check_sanctions_robot: "Das Land {{country, string}} in dem sich der Lieferant befindet, wurde auf der Sanktionsliste gefunden. Bitte prüfen, ob die Geschäftsbeziehung fortgesetzt werden kann.",
            actions: {
                "yes": "Ja",
                "no": "Nein",
            }
        }
    },
    comments: {
        action_delete: "Löschen",
    }
}
