import React, { useEffect, useState } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import { Stack, TextField, Typography } from "@mui/material"
import { Product, Vendor } from "../api/models/vendors";
import { ProcurementRequestUpdateContext } from "../requests/AllPropertyDetails";
import { ProcurementRequestUpdate } from "../api/models/procurementRequest";

export function ProductAutocompleteTextField() {

    const { procurementRequest, editMode, procurementRequestUpdate, handleFieldChange, propertyConfigs } = React.useContext(ProcurementRequestUpdateContext)

    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState(procurementRequestUpdate.product?.name ?? "")
    const options = procurementRequestUpdate.vendor?.products ?? []
    const product = procurementRequestUpdate.product

    // Call update function for parent component
    function onChange(newProduct: string | Product | null) {
        if (newProduct === null) {
            handleFieldChange("product", undefined)
        } else if (typeof newProduct === "object") {
            handleFieldChange("product", newProduct)
        }
    }


    return <Autocomplete
        freeSolo
        id="vendor-autocomplete"
        includeInputInList
        filterSelectedOptions
        open={open && (inputValue?.length ?? 0) >= 0}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        value={product?.name ?? ""}
        onChange={(event, value) => onChange(value)}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
            setInputValue(value)
            if (reason !== "reset") {
                onChange(value)
            }
        }}
        filterOptions={(options) => options}
        getOptionLabel={(option) => {
            if (typeof option === "string") {
                return option
            } else if (option !== undefined) {
                return option.name
            } else {
                return ""
            }
        }}
        renderInput={(params) => {
            return <TextField
                {...params}
                sx={{ minWidth: 300 }}
                InputProps={{
                    ...params.InputProps,
                }}
            />
        }}
        renderOption={(props, option) => (
            <li {...props}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Stack direction="column">
                        <Typography variant="body1">{option.name}</Typography>
                    </Stack>
                </Stack>
            </li>
        )}
    />
}
