import React from "react"
import { useTranslation } from "react-i18next"
import {
    Avatar,
    Grid,
    Select,
    Stack,
    Tooltip,
    Typography,
    Box,
    Button,
    Dialog,
    MenuItem,
} from "@mui/material"
import { Help } from "@mui/icons-material"
import Exiticon from "../forms/components/exiticon"
import { CategoryTextField, SimpleTextField } from "../ui/Textfields"
import { Vendor, VendorRelation, vendorRelations } from "../api/models/vendors";


export default function VendorForm(props: {
    vendor: Vendor,
    open: boolean,
    onClose: () => void,
    onSave: (vendor: Vendor) => void,
    mode: "add" | "edit",
}) {

    const handleClose = () => {
        props.onClose()
    }

    const [availabilityStatus, setAvailabilityStatus] = React.useState<VendorRelation>(props.vendor.vendorRelation)
    const [openAvailabilityStatus, setOpenAvailabilityStatus] = React.useState(false)

    const handleCloseAvailabilityStatus = () => {
        setOpenAvailabilityStatus(false)
    }


    const handleOpenAvailabilityStatus = () => {
        setOpenAvailabilityStatus(true)
    }

    const [vendorData, setVendorData] = React.useState<{
        name: string,
        website?: string,
        description?: string,
        iconUrl?: string,
        category?: string,
        subCategory?: string,
        vendor_relation: VendorRelation
    }>({
        name: props.vendor.name,
        website: props.vendor.website,
        description: props.vendor.description,
        iconUrl: props.vendor.iconUrl,
        category: props.vendor.category,
        subCategory: props.vendor.subCategory,
        vendor_relation: props.vendor.vendorRelation
    })

    const handleSave = () => {
        props.vendor.name = vendorData.name
        props.vendor.website = vendorData.website
        props.vendor.description = vendorData.description
        props.vendor.iconUrl = vendorData.iconUrl
        props.vendor.category = vendorData.category
        props.vendor.subCategory = vendorData.subCategory
        props.vendor.vendorRelation = vendorData.vendor_relation

        props.onSave(props.vendor)
    }

    const { t } = useTranslation()

    const handleChange = (value: any) => {
        setAvailabilityStatus(value)
        setVendorData(prevState => ({ ...prevState, vendor_relation: value }))
    }


    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} maxWidth="lg" fullWidth>
                <Grid sx={{ marginTop: 4, marginLeft: 4, marginRight: 4 }}>
                    <Grid
                        container
                        flexDirection={"row"}
                        paddingBottom={2}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ width: "100%" }}
                    >
                        <Box width={20} height={20} color="#0000FF" sx={{ paddingLeft: 6 }}>
                            <Exiticon onClose={handleClose}></Exiticon>
                        </Box>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography
                                sx={{
                                    fontSize: { xs: 20, md: 30 },
                                    fontWeight: "bold",
                                    align: "center",
                                    mx: "auto"
                                }}
                            >
                                {props.mode == "edit" ? t("vendors.dialog.edit_title") : t("vendors.dialog.add_title")}
                            </Typography>
                            <Tooltip
                                title="Um die Datenqualität hochzuhalten und die Arbeit aller Beteiligten zu erleichtern benötigen wir einige Informationen zur Pflege der Datenbank."
                                placement="bottom-end">
                                <Help
                                    sx={{
                                        height: "20px",
                                        width: "20px",
                                        marginBottom: "6px",
                                        marginLeft: "2px",
                                        color: "gray",
                                    }}
                                ></Help>
                            </Tooltip>
                        </Stack>
                        <Box width={20} height={20} color="#0000FF">
                        </Box>
                    </Grid>
                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        paddingBottom={2}
                    >
                        <Grid container flexDirection={"column"} justifyContent={"center"} sx={{
                            m: 0.7,
                            width: 600,
                            maxWidth: { xs: 400, md: 600 },
                        }}>
                            {vendorData?.iconUrl ?
                                <img src={vendorData.iconUrl} loading="eager" width="80" height="80" alt="" />
                                :
                                <Avatar
                                    alt={"None"}
                                    sx={{ width: 80, height: 80 }}
                                    variant={"square"}
                                >
                                    <Typography fontSize={12} textAlign={"center"}>{t("vendors.no_image")}</Typography>
                                </Avatar>
                            }
                            <SimpleTextField
                                value={vendorData?.iconUrl}
                                setValue={(value) => setVendorData(prevState => ({
                                    ...prevState,
                                    iconUrl: value
                                }))}
                                title={t("vendors.dialog.icon_url_title")}
                                subTitle={t("vendors.dialog.icon_url_subtitle")}
                            />
                            <SimpleTextField value={vendorData?.name}
                                setValue={(value) => setVendorData(prevState => ({
                                    ...prevState,
                                    name: value
                                }))}
                                title={t("vendors.dialog.name_title")}
                                subTitle={t("vendors.dialog.name_subtitle")}
                            />
                            <SimpleTextField value={vendorData?.description}
                                setValue={(value) => setVendorData(prevState => ({
                                    ...prevState,
                                    description: value
                                }))}
                                title={t("vendors.dialog.description_title")}
                                subTitle={t("vendors.dialog.description_subtitle")}
                            />
                            <SimpleTextField value={vendorData?.website}
                                setValue={(value) => setVendorData(prevState => ({
                                    ...prevState,
                                    website: value
                                }))}
                                title={t("vendors.dialog.website_title")}
                                subTitle={t("vendors.dialog.website_subtitle")}
                            />
                            <CategoryTextField
                                value={{ category: vendorData?.category, subCategory: vendorData?.subCategory }}
                                setValue={(category, subCategory) => {
                                    setVendorData(prevState => ({
                                        ...prevState,
                                        category: category ?? undefined,
                                        subCategory: subCategory ?? undefined
                                    }))
                                }}
                            />
                            <Stack direction={"column"} alignItems={"flex-start"}>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{ fontWeight: "bold", marginLeft: 1 }}
                                >
                                    {t("vendors.dialog.status_title")}
                                </Typography>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={openAvailabilityStatus}
                                    onClose={handleCloseAvailabilityStatus}
                                    onOpen={handleOpenAvailabilityStatus}
                                    value={availabilityStatus}
                                    onChange={(event) => handleChange(event.target.value as VendorRelation)}
                                    variant={"outlined"}
                                    sx={{
                                        m: 0.7,
                                        width: 600,
                                        maxWidth: { xs: 500, md: 700 },
                                    }}
                                >
                                    {vendorRelations.map((vendorRelation) => (
                                        <MenuItem value={vendorRelation}>
                                            {t(`vendors.vendor_relations.${vendorRelation}`)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid
                            container
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            paddingBottom={2}
                            paddingRight={6}
                            paddingLeft={6}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{ width: 120, height: 40 }}
                                onClick={handleClose}
                            >
                                <Typography variant={"body2"}>{t("vendors.dialog.cancel")}</Typography>
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{ width: 120, height: 40 }}
                                onClick={handleSave}
                            >
                                <Typography variant={"body2"}>{t("vendors.dialog.save")}</Typography>
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Dialog>
        </div>
    )
}