import {UserContract} from "../apiContracts";

export class User {
    id: number
    email: string
    username: string
    firstName: string
    lastName: string
    groups?: string[]
    permissions?: string[]

    get name(): string {
        return this.firstName + " " + this.lastName
    }

    constructor(user: UserContract) {
        this.id = user.id
        this.email = user.email
        this.username = user.username
        this.firstName = user.first_name
        this.lastName = user.last_name
        this.groups = user.groups
        this.permissions = user.permissions
    }

    hasPermission(permission: string): boolean {
        return this.permissions?.includes(permission) ?? false
    }

    hasChangeUserPermission(): boolean {
        return this.hasPermission("auth.change_group")
    }

    hasChangeVendorInfoPermission(): boolean {
        return this.hasPermission("vendor.change_vendors")
    }

    hasChangeProcurementRequestPermission(): boolean {
        return this.hasPermission("procurement_requests.change_procurementrequest")
    }


    hasGroup(group: string): boolean {
        return this.groups?.includes(group) ?? false
    }
}


export interface Tokens {
    access: string
    refresh: string
}
