import React from "react"
import Grid from "@mui/material/Grid"
import {Box, Card, CardContent, Typography} from "@mui/material"

type MediaCardProps = {
    heading: string;
    content: string;
    selected: boolean;
    image: string;
    onSelected: () => void;
};

export default function Mediacard({
                                      heading,
                                      content,
                                      image,
                                      selected,
                                      onSelected,
                                  }: MediaCardProps) {
    return (
        <div>
            <Grid container margin={0.5}>
                <Card
                    onClick={onSelected}
                    sx={{
                        width: {xs: 140, md: 210, xl: 260},
                        height: {xs: 220, md: 320, xl: 390},

                        border: 2,
                        transition: "0.3s",
                        borderColor: selected ? "#4d4dff" : "lightgray",
                        "&:hover": {
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            borderColor: selected ? "#4D4DFF" : "#9A9AFF",
                        },
                    }}
                    raised={selected}>
                    <Grid
                        container
                        justifyContent={"center"}
                        paddingLeft={2}
                        paddingRight={2}
                    sx={{height: "50%", paddingTop: 5}}>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: {xs: 50, md: 80, xl: 120},
                            }}
                            alt="Card Content"
                            src={require(`../../assets/optionimages/${image}.png`)}
                        />
                    </Grid>
                    <CardContent sx={{height: "50%"}}>
                        <Typography
                            gutterBottom
                            textAlign={"center"}
                            fontWeight={"bold"}
                            sx={{
                                fontSize: {xs: 11, md: 17, xl: 21},
                            }}
                        >
                            {heading}
                        </Typography>
                        <Typography
                            color="text.secondary"
                            textAlign={"center"}
                            sx={{
                                fontSize: {xs: 12, md: 16, xl: 18},
                            }}
                        >
                            {content}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    )
}
