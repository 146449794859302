export namespace QueryKeys {
    export const procurementRequestPrefix = "procurement-request"

    export function getProcurementRequestList(status?: string) {
        return [procurementRequestPrefix, status]
            .filter((item): item is string => !!item)
    }


    export function getProcurementRequest(procurementRequestId?: number) {
        return [procurementRequestPrefix, procurementRequestId]
    }

    export function getWorkflow(procurementRequestId?: number, workflowId?: number) {
        return [procurementRequestPrefix, procurementRequestId, "workflow", workflowId]
    }

    export function getTasks(procurementRequestId: number, nodeId: number): any[] {
        return [procurementRequestPrefix, procurementRequestId, "node", nodeId, "tasks"]
    }

    export function getComments(procurementRequestId: number) {
        return [procurementRequestPrefix, procurementRequestId, "comments"]
    }
}
