import ReactFlow, {Edge, MarkerType, Position} from "reactflow"
import React, {useMemo} from "react"
import {Node} from "@reactflow/core/dist/esm/types"
import {WorkflowNodeComponent} from "./CustomNodes"
import 'reactflow/dist/style.css'
import {BaseTask, TaskGroup, TaskList} from "../api/models/tasks";
import {CustomEdge} from "./CustomEdge";


export const nodeWidth = 220
export const nodeHeight = 100

function getNodes(taskList: TaskList): { nodes: Node<BaseTask>[], edges: Edge[] } {

    let nodes: Node<BaseTask>[] = []
    let edges: Edge[] = []
    let predecessors: Node[] = []
    taskList.tasks.forEach((item, i) => {
        let newNodes: Node[] = []
        if (item instanceof TaskGroup && item.executionType === "parallel") {
            newNodes = item.subtasks?.map((subtask, j) => {
                return {
                    id: subtask.id.toString(),
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    data: subtask,
                    type: "workflow-node",
                    height: nodeHeight,
                    width: nodeWidth,
                    position: {
                        x: nodeWidth * 1.5 * i,
                        y: nodeHeight * 1.5 * Math.round(j - item.subtasks!.length / 2) + (item.subtasks!.length % 2 === 0 ? nodeHeight * 1.5 / 2 : 0) - nodeHeight / 2
                    },
                }
            }) ?? []
        } else {
            newNodes = [{
                id: item.id.toString(),
                sourcePosition: Position.Right,
                targetPosition: Position.Left,
                data: item,
                type: "workflow-node",
                height: nodeHeight,
                width: nodeWidth,
                position: {x: nodeWidth * 1.5 * i, y: -nodeHeight / 2},
            }]
        }

        nodes = nodes.concat(newNodes)

        predecessors.forEach((predecessor) => {
            newNodes.forEach((newNode) => {
                const edge = {
                    id: `${predecessor.id}-${newNode.id}`,
                    source: predecessor.id,
                    target: newNode.id,
                    type: 'custom',
                    markerEnd: {
                        type: MarkerType.Arrow,
                        color: 'black',
                        width: 20,
                        height: 20,
                    },
                    style: {strokeWidth: '2px', stroke: 'black'}
                }
                edges.push(edge)
            })
        })

        predecessors = newNodes
    })

    return {nodes: nodes, edges: edges}
}

const nodeTypes = {
    "workflow-node": WorkflowNodeComponent,
}

const edgeTypes = {
    "custom": CustomEdge
}

export function WorkflowGraph(props: {
    taskList: TaskList,
}) {
    const {
        nodes,
        edges
    } = useMemo(() => getNodes(props.taskList), [props.taskList])

    return <ReactFlow
        style={{height: '100%', width: '100%',}}
        nodes={nodes}
        edges={edges}
        fitView={true}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        panOnDrag={false}
        zoomOnScroll={false}
        proOptions={{hideAttribution: true}}
        attributionPosition="bottom-center"
        preventScrolling={false}
        fitViewOptions={{padding: 0.2}}
    />
}
