import React, {useState} from 'react'
import {Checkbox, Collapse, IconButton, List, ListItem, ListItemButton, ListItemText, Stack} from '@mui/material'
import {
    Add,
    Book,
    Delete,
    Edit,
    ExpandLess,
    ExpandMore,
    Filter2,
    MoreVertOutlined,
    OpenInNew,
    PlusOne
} from '@mui/icons-material'
import {BaseTask, FormTask, IntegrationTask, Task, TaskGroup, TaskList, TaskState} from "../api/models/tasks"
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {apiProvider} from "../api/api";
import EditTaskDialog, {SelectTaskTemplateDialog} from "./EditTaskDialog";
import {FormDialog} from "./sidebar/FormTaskListItem";
import {useAuth} from "../auth/auth";
import {ProcurementRequest} from "../api/models/procurementRequest";
import {AddTaskByTemplateContract, BaseTaskContract} from "../api/apiContracts";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import {useTranslation} from "react-i18next"

export function TaskListComponent(props: { taskList: TaskList, procurementRequest: ProcurementRequest }) {

    const user = useAuth().user
    const hasEditPermission = user?.hasChangeProcurementRequestPermission() ?? false

    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [addTemplateDialogOpen, setAddTemplateDialogOpen] = useState(false)

    const addNewTaskMutation = useAddTaskMutation(props.procurementRequest)

    function onSaveNewTask(item: Partial<BaseTaskContract> | { template: number }) {
        item = {...item, procurement_request: props.procurementRequest.id, order: props.taskList.tasks.length}
        addNewTaskMutation.mutate(item)
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openPlusMenu = Boolean(anchorEl);
    const handleClickPlusMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePlusMenu = () => {
        setAnchorEl(null);
    };

    const {t} = useTranslation()


    return <>
        {hasEditPermission &&
            <>
                <EditTaskDialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} onSave={onSaveNewTask}/>
                <SelectTaskTemplateDialog open={addTemplateDialogOpen} onClose={() => setAddTemplateDialogOpen(false)}
                                          onSelect={template => onSaveNewTask({template: template.id})}/>
            </>
        }
        <Stack>
            <List dense={true}>
                {props.taskList.tasks.map((task) => (
                    <TaskItem key={task.id} task={task} level={0}
                              procurementRequest={props.procurementRequest}
                              hasEditPermission={hasEditPermission}/>
                ))}
            </List>
            {hasEditPermission &&
                <Stack direction={"row"} justifyContent={"center"} sx={{width: "100%"}}>
                    <Button
                        id="basic-button"
                        aria-controls={openPlusMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openPlusMenu ? 'true' : undefined}
                        onClick={handleClickPlusMenu}
                        sx={{borderRadius: 2}}
                        variant={"outlined"}
                        size={"small"}
                    >
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography
                                sx={{color: "blue", textTransform: "none"}}>{t("request_details.add")}</Typography>
                            <Add sx={{color: "blue", fontSize: 24}}></Add>
                        </Stack>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openPlusMenu}
                        onClose={handleClosePlusMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleClosePlusMenu()
                            setAddDialogOpen(true)
                        }} sx={{color: "grey"}}><PlusOne sx={{marginRight: 1}}/>{t("request_details.add_new")}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleClosePlusMenu()
                            setAddTemplateDialogOpen(true)
                        }} sx={{color: "grey"}}><Book sx={{marginRight: 1}}/>{t("request_details.add_from_template")}
                        </MenuItem>
                    </Menu>
                </Stack>
            }
        </Stack>
    </>
}

function TaskItem({task, level, procurementRequest, hasEditPermission}: {
    task: BaseTask,
    level: number,
    procurementRequest: ProcurementRequest,
    hasEditPermission: boolean
}) {
    const {t} = useTranslation()

    const [open, setOpen] = useState(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [formDialogOpen, setFormDialogOpen] = useState(false)
    const completeTaskMutation = useUpdateTaskMutation(task, procurementRequest)
    const deleteTaskMutation = useDeleteTaskMutation(task, procurementRequest)

    const handleClick = () => {
        if (task instanceof TaskGroup) {
            setOpen(!open)
        } else if (task instanceof FormTask) {
            setFormDialogOpen(true)
        } else if (task instanceof IntegrationTask) {
            // Do nothing for now
        } else {
            completeTaskMutation.mutate(task.state === "completed" ? "ready_to_start" : "completed")
        }
    }

    const handleExecute = () => {
        apiProvider.executeIntegrationTask(task.id).then(() => {
            completeTaskMutation.mutate("completed")
        })
        // TODO: handle error
    }

    const editTaskMutation = useEditTaskMutation(procurementRequest)
    const onSaveTask = (editedTask: Partial<BaseTaskContract>) => {
        editTaskMutation.mutate({...editedTask, id: task.id})
    }

    const addTaskMutation = useAddTaskMutation(procurementRequest)

    const onSaveChild = (newTask: Partial<BaseTaskContract>) => {
        addTaskMutation.mutate(newTask)
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openTaskMenu = Boolean(anchorEl);
    const handleClickTaskMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseTaskMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {hasEditPermission &&
                <EditTaskDialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}
                                onSave={onSaveTask}
                                task={task}/>}
            {task instanceof TaskGroup &&
                <EditTaskDialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} onSave={onSaveChild}
                                parent={task} key={task.getUniqueKey()}/>
            }
            {task instanceof FormTask && <FormDialog open={formDialogOpen} setOpen={setFormDialogOpen} formTask={task}
                                                     procurementRequest={procurementRequest}/>}
            <ListItem disablePadding={true} sx={{marginBottom: 0.5, paddingLeft: level * 8}}
                      secondaryAction={hasEditPermission ? (<><IconButton
                          id="basic-button"
                          aria-controls={openTaskMenu ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openTaskMenu ? 'true' : undefined}
                          onClick={handleClickTaskMenu}
                          sx={{borderRadius: 0, padding: 0}}
                      >
                          <MoreVertOutlined sx={{color: "grey"}}></MoreVertOutlined>
                      </IconButton>
                          <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openTaskMenu}
                              onClose={handleCloseTaskMenu}
                              MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                              }}
                          >
                              <MenuItem onClick={() => {
                                  handleCloseTaskMenu()
                                  setEditDialogOpen(true)
                              }} sx={{color: "grey"}}><Edit sx={{marginRight: 1}}/>{t("request_details.edit")}
                              </MenuItem>
                              {level !== 2 && task instanceof TaskGroup ?
                                  <MenuItem onClick={() => {
                                      handleCloseTaskMenu()
                                      setAddDialogOpen(true)
                                  }} sx={{color: "grey"}}><PlusOne sx={{marginRight: 1}}/>{t("request_details.add_new")}
                                  </MenuItem> : null}
                              <MenuItem onClick={() => {
                                  handleCloseTaskMenu()
                                  setEditDialogOpen(true)
                              }} sx={{color: "grey"}}><Filter2
                                  sx={{marginRight: 1}}/>{t("request_details.change_order")}
                              </MenuItem>
                              <MenuItem onClick={() => {
                                  handleCloseTaskMenu()
                                  deleteTaskMutation.mutate()
                              }} sx={{color: "grey"}}><Delete sx={{marginRight: 1}}/>{t("request_details.delete")}
                              </MenuItem>
                          </Menu></>) : undefined}>
                <ListItemButton onClick={handleClick} dense
                                sx={{border: 1, borderRadius: 2, borderColor: "lightgrey"}}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Checkbox
                            edge="start"
                            checked={task.state === "completed"}
                            inputProps={{'aria-labelledby': `task-label-${task.id}`}}
                        />
                        <ListItemText id={`task-label-${task.id}`} primary={`${task.order + 1}. `}
                                      sx={{marginRight: 0.2}}/>
                        <ListItemText id={`task-label-${task.id}`} primary={task.title}/>
                        {task instanceof TaskGroup ? (open ? <ExpandLess/> : <ExpandMore/>) : null}
                    </Stack>
                    {task instanceof FormTask && <OpenInNew sx={{fontSize: 20, marginLeft: 1}}/>}
                    {task instanceof Task && task.assignee && (
                        <Stack direction={"row"} alignItems={"center"} sx={{marginLeft: 1}}>
                            <Typography sx={{fontSize: 12, color: "grey"}}>{task.assignee.name}</Typography>
                        </Stack>
                    )}
                    {
                        task instanceof IntegrationTask && (
                            <Button
                                disabled={task.state !== "ready_to_start"}
                                variant='contained'
                                sx={{marginLeft: 2}}
                                onClick={() => {
                                    handleExecute()
                                }}
                            >
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}
                                       sx={{margin: "0 3", padding: "0 10px"}}>
                                    <Typography
                                        sx={{fontSize: 12, color: "primary", text: "center"}}>{"E-mail senden"}</Typography>
                                </Stack>
                            </Button>
                        )
                    }
                </ListItemButton>
            </ListItem>
            {task instanceof TaskGroup && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {task.subtasks.map((subtask) => (
                            <TaskItem key={subtask.id} task={subtask} level={level + 1}
                                      procurementRequest={procurementRequest}
                                      hasEditPermission={hasEditPermission}/>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    )
}

export function useUpdateTaskMutation(task: BaseTask, procurementRequest: ProcurementRequest) {
    const queryClient = useQueryClient()
    return useMutation((state: TaskState) => apiProvider.setTaskState(task.id, state),
        {
            onMutate: () => {
                queryClient.setQueryData(["procurement-request", procurementRequest.id, "tasks"], (old: TaskList | undefined) => {
                    if (!old) {
                        return undefined
                    }
                    return new TaskList(old.tasks.map(item => {
                        if (item.id === task.id) {
                            item.state = (task.state === "completed" ? "ready_to_start" : "completed")
                        }
                        return item
                    }))
                })
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(["procurement-request", procurementRequest.id, "tasks"])
            }
        }
    )
}

function useDeleteTaskMutation(task: BaseTask, procurementRequest: ProcurementRequest) {
    const queryClient = useQueryClient()
    return useMutation(() => apiProvider.deleteTask(task.id),
        {
            onMutate: () => {
                queryClient.setQueryData(["procurement-request", procurementRequest.id, "tasks"], (old: TaskList | undefined) => {
                    return old ? new TaskList(old.tasks.filter(item => item.id !== task.id)) : undefined
                })
            },
            onSettled: async () => {
                await queryClient.invalidateQueries(["procurement-request", procurementRequest.id, "tasks"])
            }
        }
    )
}

function useTaskMutation<T>(procurementRequest: ProcurementRequest, mutationFn: (item: T) => Promise<BaseTask>) {
    const queryClient = useQueryClient()
    return useMutation((item: T) => {
            return mutationFn(item)
        },
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(["procurement-request", procurementRequest.id, "tasks"])

            }
        })
}

function useEditTaskMutation(procurementRequest: ProcurementRequest) {
    return useTaskMutation(procurementRequest, (item: Partial<BaseTaskContract> & { id: number }) => {
        item = {...item, procurement_request: procurementRequest.id}
        return apiProvider.editTask(item)
    })
}

function useAddTaskMutation(procurementRequest: ProcurementRequest) {
    return useTaskMutation(procurementRequest, (item: Partial<BaseTaskContract> | AddTaskByTemplateContract) => {
        item = {...item, procurement_request: procurementRequest.id}
        return apiProvider.addTask(item)
    })
}
