import {FormElementProps} from "./FormContent";
import React from "react";
import {SendProcurementRequestTerminal, ExternalLinkTerminal} from "./FormTerminals";
import SendProcurementRequestTerminalContent from "./terminals/SendProcurementRequestTerminalContent";
import ExternalLinkTerminalContent from "./terminals/ExternalLinkTerminalContent";

export function FormTerminalContent(props: FormElementProps<any>) {
    if (props.formElement instanceof ExternalLinkTerminal) {
        return <ExternalLinkTerminalContent
            {...props}
            formElement={props.formElement}
        />
    } else if (props.formElement instanceof SendProcurementRequestTerminal) {
        return <SendProcurementRequestTerminalContent
            {...props}
            formElement={props.formElement}
        />
    } else {
        return <></>
    }
}
