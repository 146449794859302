import {
    CountryQuestion,
    FormQuestion,
    FreeTextQuestion,
    OptionQuestion,
    TimeQuestion,
    MultiSelectionQuestion,
    VendorInfoQuestion,
    VendorInfoFixedQuestion,
    ProductInfoFixedQuestion,
} from "./questions"
import FormQuestionComponent from "./moreinfo/formQuestionComponent"
import OptionQuestionComponent from "./fouroptions/OptionQuestionComponent"
import CountryQuestionComponent from "./fouroptions/CountryQuestionComponent"
import VendorInfoFixedComponent from "./moreinfo/VendorInfoFixedComponent"
import Freetext from "./freetext/freetext"
import React from "react"
import {FormElementProps} from "./FormContent"
import TimeQuestionComponent from "./fouroptions/TimeQuestionComponent"
import MultiSelectionQuestionComponent from "./moreinfo/multiSelectionQuestionComponent"

import VendorInfoQuestionComponent from "./moreinfo/VendorInfoQuestionComponent"
import ProductInfoFixedComponent from "./moreinfo/ProductInfoFixedComponent"

export function QuestionContent(props: FormElementProps<any>) {
    switch (props.formElement.constructor) {
        case ProductInfoFixedQuestion:
            return (
                <ProductInfoFixedComponent
                    {...props}
                    formElement={props.formElement as ProductInfoFixedQuestion}
                />
            )
        case VendorInfoFixedQuestion:
            return (
                <VendorInfoFixedComponent
                    {...props}
                    formElement={props.formElement as VendorInfoFixedQuestion}
                />
            )
        case VendorInfoQuestion:
            return (
                <VendorInfoQuestionComponent
                    {...props}
                    formElement={props.formElement as VendorInfoQuestion}
                />
            )

        case FormQuestion:
            return (
                <FormQuestionComponent
                    {...props}
                    formElement={props.formElement as FormQuestion}
                />
            )

        case MultiSelectionQuestion:
            return (
                <MultiSelectionQuestionComponent
                    {...props}
                    formElement={props.formElement as MultiSelectionQuestion}
                />
            )

        case OptionQuestion:
            return (
                <OptionQuestionComponent
                    {...props}
                    formElement={props.formElement as OptionQuestion}
                />
            )

        case CountryQuestion:
            return (
                <CountryQuestionComponent
                    {...props}
                    formElement={props.formElement as CountryQuestion}
                />
            )

        case TimeQuestion:
            return (
                <TimeQuestionComponent
                    {...props}
                    formElement={props.formElement as TimeQuestion}
                />
            )

        case FreeTextQuestion:
            return (
                <Freetext
                    {...props}
                    formElement={props.formElement as FreeTextQuestion}
                />
            )

        default:
            return <></>
    }
}
