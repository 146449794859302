import {BackendApiProvider, backendApiProvider} from "./backendApiProvider"

// export interface ApiProvider {
//     userName: string | undefined
//     loggedOutDelegate?: () => void
//
//     login(username: string, password: string): Promise<void>
//
//     refreshToken(): Promise<void>
//
//     getRequests(status?: string): Promise<ProcurementRequest[]>
//
//     getRequest(id: number): Promise<ProcurementRequest>
//
//     getWorkflow(id: number): Promise<Workflow>
//
//     getEditableWorkflow(id: number): Promise<EditableWorkflow>
//
//     getWorkflowDefinitions(): Promise<WorkflowDefinition[]>
//
//     getWorkflowDefinition(key: string): Promise<WorkflowDefinition>
//
//     getWorkflowDefinition(key: string): Promise<WorkflowDefinition>
//
//     saveWorkflowDefinition(key: string, definition: Omit<WorkflowDefinition, "key">): Promise<void>
//
//     getComments(procurementRequestId: number): Promise<Comment[]>
//
//     postComment(procurementRequestId: number, message: string): Promise<void>
//
//     deleteComment(commentId: number): Promise<void>
//
//     openDocument(documentId: number): Promise<void>
//
//     postDocument(files: FileList | null): Promise<Document[]>
//
//     executeRobotAction(commentId: number, action: string): Promise<void>
//
//     createRequest(request: { properties: { key: string, value: string }[], vendor: string | undefined, description: string }): Promise<ProcurementRequest>
//
//     updateRequest(id: number, request: { properties: { key: string, value: string }[], vendor: string | undefined }): Promise<ProcurementRequest>
//
//     acceptRequest(id: number, newVendor?: CreateVendorWithCloseData, vendorId?: string, addProduct?: AddProductData): Promise<ProcurementRequest>
//
//     cancelRequest(id: number, conclusion: string): Promise<ProcurementRequest>
//
//     transitionNode(id: number, targetState: WorkflowNodeState): Promise<void>
//
//     assignNode(nodeId: number, userId?: number): Promise<void>
//
//     getTasks(nodeId: number): Promise<Task[]>
//
//     updateTask(taskId: number, completed: boolean): Promise<Task>
//
//     getUsers(): Promise<User[]>
//
//     search(query: string, category?: string, subCategory?: string): Promise<Vendor[]>
//
//     getVendors(): Promise<Vendor[]>
//
//     getVendorsPaged(page: number, pageSize?: number): Promise<PagedResult<Vendor>>
//
//     getVendor(id: string): Promise<Vendor>
//
//     updateVendor(id: string, vendor: Vendor): Promise<Vendor>
//
//     getCategories(): Promise<Category[]>
//
//     getForm(key: string): Promise<CompoundFormElement>
//
//     updateVendorPreferredStatus(id: string, is_preferred: boolean): Promise<void>
//
//     logout(): void;
//
//     getProperties(): Promise<Property[]>
//
//     getMe(): Promise<User>
// }

export class LoginError extends Error {
}

// export const apiProvider: ApiProvider = (process.env.REACT_APP_BACKEND_STAGE === "localMock") ? mockApiProvider : backendApiProvider
export const apiProvider: BackendApiProvider = backendApiProvider
