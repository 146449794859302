import * as React from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {Settings} from "@mui/icons-material"
import {IconButton} from "@mui/material"
import {apiProvider} from "../api/api"
import {Comment} from "../api/models/procurementRequest"
import {useQueryClient} from "@tanstack/react-query"
import {useTranslation} from "react-i18next"
import {QueryKeys} from "../api/queryKeys"

export default function EditComment(props: { comment: Comment, procurementRequestId: number }) {
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const onDelete = async () => {
        await apiProvider.deleteComment(props.comment.id)
        await queryClient.invalidateQueries(QueryKeys.getComments(props.procurementRequestId))
        handleClose()
    }

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{marginTop: 0.2}}
            >
                <Settings
                    sx={{
                        width: 20,
                        heigth: 20,
                        "&:hover": {
                            backgroundColor: "transparent",
                            cursor: "pointer",
                        },
                        color: "5A5A5A",
                    }}
                ></Settings>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {/*<MenuItem onClick={handleClose}>Edit</MenuItem>*/}
                <MenuItem onClick={onDelete}>{t("comments.action_delete")}</MenuItem>
            </Menu>
        </div>
    )
}
