import React, {useMemo, useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Backdrop,
    Box, Button,
    Checkbox, CircularProgress, Collapse,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Typography
} from '@mui/material'
import Divider from "@mui/material/Divider"
import ExpandMore from "@mui/icons-material/ExpandMore"
import {AccessAlarm, CheckCircleOutlined, NextPlanOutlined, OpenInNew} from "@mui/icons-material"
import {FormTask, Task, TaskList} from "../api/models/tasks";
import {useAuth} from "../auth/auth";
import {useUpdateTaskMutation} from "./TaskListComponent";
import List from "@mui/material/List";
import {FormDialog} from "./sidebar/FormTaskListItem";
import {ProcurementRequest} from "../api/models/procurementRequest";
import {useParams} from "react-router-dom"
import {useQuery} from "@tanstack/react-query"
import {apiProvider} from "../api/api"
import {useTranslation} from "react-i18next";

export function MyTasks(props: { tasks: TaskList, procurementRequest: ProcurementRequest }) {

    const { t } = useTranslation()
    const auth = useAuth()

    const [myOpenTasks, myFutureTasks, myDoneTasks] = useMemo(() => {
        const myTasks = props.tasks.getTasksByUser(auth.user!)
        const myOpenTasks = myTasks.filter(task => task.state === 'ready_to_start')
        const myFutureTasks = myTasks.filter(task => task.state === 'not_started')
        const myDoneTasks = myTasks.filter(task => task.state === 'completed')
        return [myOpenTasks, myFutureTasks, myDoneTasks]
    }, [props.tasks, auth.user])

    const [openBackDrop, setOpenBackDrop] = React.useState(myDoneTasks.length > 0? false: true);
    const handleCloseBackDrop = () => {
        setOpenBackDrop(false);
    };
    const handleOpenBackDrop = () => {
        setOpenBackDrop(true);
    };

    return (
        <Stack direction={"column"} alignItems={"center"} sx={{width: "100%", padding: 2}}>

            <Accordion defaultExpanded={true} disableGutters={true} elevation={0}
                       sx={{width: "100%", '&:before': {
                               display: 'none',
                           } }}>
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{color: "blue"}}/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{flexDirection: "row-reverse", borderRadius: 2}}
                >
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography fontWeight={"bold"} sx={{marginRight: 0.5, color: "blue"}}>{t("request_details.open_tasks")}</Typography>
                        <AccessAlarm sx={{color: "blue"}}></AccessAlarm>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box display="flex" justifyContent="center" sx={{marginLeft: 2}}>
                        <Box width="100%">
                            <Stack direction={"column"} alignItems={"center"}>
                                {myOpenTasks.length === 0 ?
                                    <Stack direction={"column"} alignItems={"center"}>
                                        <Box
                                            component="img"
                                            sx={{
                                                width: 180,
                                                height: 140
                                            }}
                                            alt="Logo"
                                            src={require("../assets/NothingToDo.png")}
                                        />
                                        <Typography sx={{fontWeight: "bold", marginTop: 4, textAlign: "center"}}>{t("request_details.nothing_to_do")}</Typography>
                                    </Stack>
                                    :
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        sx={{width: "100%"}}
                                    >
                                        {myOpenTasks.map((task, index) => 
                                        <TaskItem
                                            key={index}
                                            task={task}
                                            procurementRequest={props.procurementRequest}
                                            />
                                        )}
                                    </Box>}
                            </Stack>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            {myFutureTasks.length > 0 ? (
            <Accordion defaultExpanded={false} disableGutters={true} elevation={0} sx={{width: "100%", '&:before': {
                    display: 'none',
                }}}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{flexDirection: "row-reverse", borderRadius: 2}}
                >
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography fontWeight={"bold"} sx={{marginRight: 0.5}}>{t("request_details.future_tasks")}</Typography>
                        <NextPlanOutlined></NextPlanOutlined>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionDetails>
                        <MyTaskList tasks={myFutureTasks} procurementRequest={props.procurementRequest}/>
                    </AccordionDetails>
                </AccordionDetails>
            </Accordion>)
            : undefined}
            <Accordion defaultExpanded={false} disableGutters={true} elevation={0} sx={{width: "100%", '&:before': {
                    display: 'none',
                }}}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{flexDirection: "row-reverse", borderRadius: 2}}
                >
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography fontWeight={"bold"} sx={{marginRight: 0.5, }}>{t("request_details.done_tasks")}</Typography>
                        <CheckCircleOutlined></CheckCircleOutlined>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box display="flex" justifyContent="center" sx={{width: "100%"}}>
                        <Box width="100%">
                            <Stack direction={"column"} alignItems={"center"} sx={{width: "100%"}}>
                    <MyTaskList tasks={myDoneTasks} procurementRequest={props.procurementRequest}/>
                            </Stack>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Stack>
    )
}

function MyTaskList(props: { tasks: Task[], procurementRequest: ProcurementRequest }) {
    return <Box display="flex" justifyContent="center" sx={{width: "100%"}}>
        <Box width="100%">
            <Stack direction={"column"} alignItems={"center"} sx={{width: "100%"}}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{width: "100%"}}
                > <List sx={{width: "100%"}}>
                    {props.tasks.map((task, index) => <TaskItem key={index} task={task} procurementRequest={props.procurementRequest}/>)}
                </List>
                </Box>
            </Stack>
        </Box>
    </Box>
}

function TaskItem({task, procurementRequest}: { task: Task, procurementRequest: ProcurementRequest }) {
    const updateTaskMutation = useUpdateTaskMutation(task, procurementRequest)
    const [formDialogOpen, setFormDialogOpen] = useState(false)

    const handleClick = () => {
        if (task instanceof FormTask) {
            setFormDialogOpen(true)
        } else {
            updateTaskMutation.mutate(task.state !== "completed" ? "completed" : "ready_to_start")
        }
    }


    const id = Number.parseInt(useParams()["id"]!)

    const {isLoading, data: taskList} = useQuery({
        queryKey: ["procurement-request", id, "tasks"],
        queryFn: () => apiProvider.getTasks(id),
    })

    const parentTask = taskList?.tasks.find((todo) => (todo.id === task.parent))


    return <>
        {task instanceof FormTask &&
            <FormDialog open={formDialogOpen} setOpen={setFormDialogOpen}
                        formTask={task}
                        procurementRequest={procurementRequest}/>}
        <ListItem disablePadding={true} sx={{paddingLeft: 1, marginBottom: 0.5}}>
            <ListItemButton onClick={handleClick} dense sx={{border: 1, borderRadius: 2, borderColor: "lightgrey"}}>
                <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                    edge="start"
                    checked={task.state === "completed"}
                    inputProps={{'aria-labelledby': `task-label-${task.id}`}}
                />
                <ListItemText id={`task-label-${task.id}`} primary={task.title} sx={{textDecoration: task.state === "completed" ? "line-through" : null}}/>
                {task instanceof FormTask && <OpenInNew sx={{marginLeft: 1, fontSize: 20}}/>}
                    <Stack  direction={"row"} alignItems={"center"} sx={{marginLeft: 1}}>
                        <Typography sx={{fontSize: 12, color: "grey", textDecoration: task.state === "completed" ? "line-through" : null }}>{parentTask?.title}</Typography>
                    </Stack>
                </Stack>
            </ListItemButton>
        </ListItem>
    </>
}

export default MyTasks
