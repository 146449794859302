import Avatar from "@mui/material/Avatar"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import React from "react"
import {Grid, Stack, Tooltip} from "@mui/material"
import EditableUserItem from "./EditableUserItem"
import {User} from "../api/models/users";

export function UserItem(props: { user: User | string }) {
    return (
        <>
            <Grid container flexDirection={"row"} alignItems="center" justifyContent="left" width="100%">
                <Tooltip title={typeof props.user === "string" ? props.user : props.user?.email}>

                    <Stack direction={"row"} alignItems={"center"}>
                        <Avatar
                            sx={{
                                minWidth: 0,
                                mr: "auto",
                                justifyContent: "center",
                                width: 24,
                                height: 24,
                                marginLeft: 0.5,
                            }}
                        >
                            {(typeof props.user === "string" ? props.user : props.user?.name).charAt(0).toUpperCase()}
                        </Avatar>
                        <Typography
                            noWrap
                            textAlign={"left"}
                            fontSize={14}
                            color={"black"}
                            paddingLeft={1}
                        >
                            {(typeof props.user === "string" ? props.user : props.user?.name)}
                        </Typography>
                    </Stack>
                </Tooltip>

            </Grid>
        </>
    )
}

export function UserListItem({
                                 initial,
                                 editable,
                                 onSave,
                                 additionalItems = {"unassigned": "Unassigned"}
                             }: {
                                 initial: User | string,
                                 editable?: boolean,
                                 onSave?: (itemKey: string, itemObject: User | string) => void,
                                 additionalItems?: { [key: string]: string }
                             }
) {
    const userItem = editable && onSave ?
        <EditableUserItem initialItem={typeof initial === "object" ? initial.username : initial} onSave={onSave}
                          additionalItems={additionalItems}/> :
        <UserItem user={(typeof initial === "string" ? additionalItems[initial] : initial)}/>

    return <ListItem key={initial.toString()} dense>
        {userItem}
    </ListItem>
}
