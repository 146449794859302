import {Stack, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Mail} from "@mui/icons-material";
import * as React from "react";
import {TaskList} from "../api/models/tasks";
import {useAuth} from "../auth/auth";
import {ProcurementRequest} from "../api/models/procurementRequest";

export function Introduction(props: {
    taskList: TaskList,
    procurementRequest: ProcurementRequest
}) {
    const auth = useAuth()

    const myTasks = props.taskList.getTasksByUser(auth.user!);
    const myOpenTasks = myTasks.filter(task => task.state !== "completed")
    const currentTask = props.taskList.tasks.find(task => task.state === "ready_to_start")

    return (
        <Stack direction={"column"}>
            <Stack direction={"row"} alignItems={"center"}>
                <Stack direction={"row"} sx={{width: "100%"}}>
                <Box
                    component="img"
                    sx={{
                        maxWidth: {xs: 230, md: 100},
                        maxHeight: {xs: 60, md: 80},
                    }}
                    alt="Logo"
                    src={require("../assets/springer/robot.png")}
                />
                <Stack direction={"column"} sx={{bgcolor: "#f2f2f2", borderTopLeftRadius: 0,
                    borderTopRightRadius: 25,
                    borderBottomLeftRadius: 25,
                    borderBottomRightRadius: 25, padding: 1, width: "100%", boxShadow: 6}}>
                <Box sx={{paddingLeft: 2, width: "100%"}}>
                    <Typography sx={{fontWeight: "bold"}}>
                        Hi {auth.user?.firstName},
                    </Typography>
                    <Typography display="inline">
                        Du hast insgesamt noch{" "}
                        <Tooltip title={myOpenTasks.map((task, index) => (
                            <Typography key={index} sx={{fontSize: 14}}>
                                {index+1}. {task.title}
                            </Typography>
                        ))}                                     arrow={true}
                        >
                            <Typography display="inline" sx={{fontWeight: "bold"}}>
                                {myOpenTasks.length}
                            </Typography>
                        </Tooltip>{" "}
                        offene To-Dos im Prozess. Der Koordinator ist{" "}
                        <Typography display="inline" sx={{fontWeight: "bold"}}>
                            {props.procurementRequest?.coordinator?.name}
                        </Typography>
                        <IconButton
                            aria-label="Linkedin.com"
                            onClick={() =>
                                window.open(`mailto:${props.procurementRequest?.coordinator?.email}`)
                            }
                            sx={{
                                width: 8,
                                height: 8,
                                marginLeft: 1,
                                marginRight: 0.5,
                            }}
                        >
                            <Mail/>
                        </IconButton>
                        {currentTask && ". Der aktuelle Prozess-Schritt ist: "}
                        {currentTask && <>
                            <Typography display="inline" sx={{fontWeight: "bold"}}>
                                {currentTask.title}.{" "}
                            </Typography>
                            Der Status dort ist:{" "}
                            <Typography display="inline" sx={{fontWeight: "bold"}}>
                                In Bearbeitung.
                            </Typography>
                        </>}
                    </Typography>
                </Box>
                        {props.procurementRequest.getPropertyByKey("department")?.value === "news_media_national" ?
                        <Stack direction={"row"} alignItems={"left"} sx={{width: "100%", marginLeft: 2}}>
                        <Stack direction={"row"} alignItems={"center"} ><Typography sx={{fontWeight: "bold"}}>Deine Kontakte für Legal:</Typography><Typography sx={{marginLeft: 0.5}}>Katharina Ziegenhagen, Kira Elb (NMN)</Typography></Stack>
                        <Stack direction={"row"} alignItems={"center"} sx={{marginLeft: 1}}><Typography sx={{fontWeight: "bold"}}>Deine Kontakte für Datenschutz:</Typography><Typography sx={{marginLeft: 0.5}}> Nicole Krüger, Nadiné Kuhn, Sara Attanjaoui (NMN).</Typography></Stack>
                    </Stack>
                        : undefined}
                    {props.procurementRequest.getPropertyByKey("department")?.value === "holding" ?
                    <Stack direction={"row"} alignItems={"left"} sx={{width: "100%", marginLeft: 2}}>
                        <Stack direction={"row"} alignItems={"center"} ><Typography sx={{fontWeight: "bold"}}>Deine Kontakte für Legal:</Typography><Typography sx={{marginLeft: 0.5}}>Zurab Kelbakiani (Holding)</Typography></Stack>
                        <Stack direction={"row"} alignItems={"center"} sx={{marginLeft: 1}}><Typography sx={{fontWeight: "bold"}}>Deine Kontakte für Datenschutz:</Typography><Typography sx={{marginLeft: 0.5}}>Kerstin Münzer, Leopold Lewitscharoff (Holding)</Typography></Stack>
                    </Stack>
                        : undefined}
                </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
