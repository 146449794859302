import {
    Button,
    Dialog,
    Grid,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material"
import Box from "@mui/material/Box"
import Exiticon from "../forms/components/exiticon"
import {CheckCircleOutline, DoNotDisturb, Help} from "@mui/icons-material"
import * as React from "react"
import List from "@mui/material/List"
import {useMutation, useQueryClient} from "@tanstack/react-query"
import {apiProvider} from "../api/api"
import {Progress} from "../ui/Progress"
import {CategoryTextField, SimpleTextField, VendorTextField} from "../ui/Textfields"
import {ProcurementRequest} from "../api/models/procurementRequest";
import {Vendor} from "../api/models/vendors";
import {AddProductDataContract, CreateVendorWithCloseDataContract} from "../api/apiContracts";

function AcceptedContent(props: {
    procurementRequest: ProcurementRequest,
    handleClose: () => void,
    setSelectedValue: (value: string) => void,
}) {
    const [selectedVendor, setSelectedVendor] = React.useState<Vendor | undefined>(props.procurementRequest.vendor)

    const [newVendorData, setNewVendorData] = React.useState<Partial<CreateVendorWithCloseDataContract>>({
        name: props.procurementRequest.properties.values["vendor_name"]?.value,
        website: props.procurementRequest.properties.values["vendor_url"]?.value,
    })
    const [productData, setProductData] = React.useState<Partial<AddProductDataContract>>({
        name: props.procurementRequest.properties.values["product_name"]?.value,
    })

    const queryClient = useQueryClient()

    const mutation = useMutation(async () => {
            const newProduct = productData.name !== undefined && productData.name !== "" ? productData as AddProductDataContract : undefined
            if (selectedVendor !== undefined) {
                await apiProvider.acceptRequest(props.procurementRequest.id, undefined, selectedVendor.id, newProduct)
            } else {
                await apiProvider.acceptRequest(props.procurementRequest.id, newVendorData as CreateVendorWithCloseDataContract, undefined, newProduct)
            }
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["procurement-request"])
                props.handleClose()
            }
        }
    )

    const createNewVendor = selectedVendor === undefined && newVendorData.name !== undefined
    const hasValidData = selectedVendor !== undefined || (newVendorData.name !== undefined && newVendorData.name !== "")

    return <>
        {mutation.isLoading && <Progress/>}
        {!mutation.isLoading && <Grid container flexDirection={"column"} paddingTop={2} paddingBottom={2}>
            <Grid
                container
                flexDirection={"row"}
                paddingBottom={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{width: "100%"}}
            >
                <Box width={20} height={20} color="#0000FF" sx={{paddingLeft: 6}}>
                    <Exiticon onClose={props.handleClose}></Exiticon>
                </Box>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        Lieferant & Produkt oder Dienstleistung anlegen
                    </Typography>
                    <Tooltip
                        title="Um die Datenqualität hochzuhalten und die Arbeit aller Beteiligten zu erleichtern benötigen wir einige Informationen zur Pflege der Datenbank."
                        placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Stack>
                <Box width={20} height={20} color="#0000FF">
                </Box>
            </Grid>
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                paddingBottom={2}>
                <Grid container flexDirection={"column"} justifyContent={"center"} sx={{
                    m: 0.7,
                    width: 600,
                    maxWidth: {xs: 400, md: 600},
                }}>
                    <VendorTextField value={selectedVendor ?? newVendorData?.name}
                                     setValue={(value) => {
                                         if (value instanceof Vendor) {
                                             setSelectedVendor(value)
                                         } else {
                                             setSelectedVendor(undefined)
                                             setNewVendorData(prevState => ({...prevState, name: value}))
                                         }
                                     }}
                                     title={"Lieferanten-Name"}
                                     subTitle={"Gib den Namen des (neuen) Lieferanten ein"}
                                     footerText={createNewVendor ? "Es wurde ein bestehender Lieferant ausgewählt. Dieser Einkauf wird bei diesem Lieferanten vermerkt." : "Es wird ein neuer Lieferant angelegt. Bitte gib die Daten des Lieferanten ein."}/>
                    {createNewVendor && (
                        <>
                            <SimpleTextField value={newVendorData?.website}
                                             setValue={(value) => setNewVendorData(prevState => ({
                                                 ...prevState,
                                                 website: value
                                             }))}
                                             title={"URL"}
                                             subTitle={"Gib hier die Web-Adresse des Lieferanten ein"}/>
                            <CategoryTextField
                                value={{
                                    category: newVendorData?.category, subCategory: newVendorData?.sub_category
                                }}
                                setValue={(category, subCategory) => {
                                    setNewVendorData(prevState => ({
                                        ...prevState,
                                        category: category ?? undefined,
                                        subCategory: subCategory ?? undefined
                                    }))
                                }}
                                disabled={selectedVendor !== undefined}
                            />
                        </>)}
                    <SimpleTextField
                        value={productData?.name}
                        setValue={(value) => setProductData(prevState => ({...prevState, name: value}))}
                        title={"Produkt | Dienstleistung"}
                        subTitle={"Was für ein Produkt oder Dienstleistung wird vom Lieferanten eingekauft?"}/>
                    <SimpleTextField
                        value={productData?.website}
                        setValue={(value) => setProductData(prevState => ({...prevState, website: value}))}
                        title={"Link zum Produkt bzw. Dienstleistung"}
                        subTitle={"Gib die Web-Adresse des Produktes oder der Dienstleistung ein"}/>
                </Grid>
                <BackForwardButtons
                    enabled={hasValidData}
                    backFunction={() => {
                        props.setSelectedValue('')
                    }}
                    forwardFunction={() => mutation.mutate()}/>
            </Grid>
        </Grid>}
    </>
}

function CanceledComponent(props: {
    requestId: number,
    handleClose: () => void,
    setSelectedValue: (value: string) => void
}) {
    const [value, setValue] = React.useState<string>("")

    const queryClient = useQueryClient()


    const mutation = useMutation(() => apiProvider.cancelRequest(props.requestId, value),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(["procurement-request"])
                props.handleClose()
            }
        }
    )

    return <>
        {mutation.isLoading && <Progress/>}
        {!mutation.isLoading && <Grid container flexDirection={"column"} paddingTop={2} paddingBottom={2}>
            <Grid
                container
                flexDirection={"row"}
                paddingBottom={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{width: "100%"}}
            >
                <Box width={20} height={20} color="#0000FF" sx={{paddingLeft: 6}}>
                    <Exiticon onClose={props.handleClose}></Exiticon>
                </Box>
                <Stack direction={"row"} alignItems={"center"}>
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        Schließen des Tickets
                    </Typography>
                    <Tooltip
                        title="Um die Datenqualität hochzuhalten und die Arbeit aller Beteiligten zu erleichtern benötigen wir einige Informationen zur Pflege der Datenbank."
                        placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Stack>
                <Box width={20} height={20} color="#0000FF">
                </Box>
            </Grid>
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                paddingBottom={2}
            >
                <Grid container flexDirection={"column"} justifyContent={"center"} sx={{
                    m: 0.7,
                    width: 600,
                    maxWidth: {xs: 400, md: 600},
                }}>
                    <TextFieldDeclineReason
                        title={"Begründung für Schließen des Tickets"}
                        subTitle={"Bitte begründe kurz warum das Ticket geschlossen wird"}
                        value={value}
                        setValue={setValue}
                    />

                </Grid>
                <BackForwardButtons
                    enabled={value !== ""}
                    backFunction={() => {
                        props.setSelectedValue('')
                    }}
                    forwardFunction={
                        () => mutation.mutate()
                    }/>
            </Grid>
        </Grid>}
    </>
}

function SelectCloseReasonContent(props: { handleClose: () => void, setSelectedValue: (value: string) => void }) {
    return <Stack>
        <Grid container flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <Stack sx={{width: "100%", direction: "row", paddingTop: 2}}>
                <Box width={20} height={20} color="#0000FF" sx={{paddingLeft: 6}}>
                    <Exiticon onClose={props.handleClose}></Exiticon>
                </Box>
            </Stack>
            <Stack sx={{width: "600px", marginTop: 10, marginBottom: 10}}>

                <Paper variant="outlined" sx={{padding: 2}}>
                    <Typography sx={{textAlign: "center", fontWeight: "bold", color: "grey"}}>Grund für das Schließen
                        des Tickets auswählen</Typography>
                    <List>
                        <ListItemButton sx={{
                            textAlign: "left",
                            borderColor: "lightgrey",
                            borderRadius: 2,
                            margin: 0.5,
                            fontWeight: "light",
                            color: "grey",
                            ":hover": {
                                borderRadius: 2,
                            },
                        }}
                                        onClick={() => {
                                            props.setSelectedValue("accepted")
                                        }}
                        >
                            <ListItemText
                                primary={"Einkauf erfolgreich"}
                            ></ListItemText>
                            <CheckCircleOutline
                                sx={{
                                    marginLeft: 2,
                                    color: "green"
                                }}
                            ></CheckCircleOutline>
                        </ListItemButton>
                        <ListItemButton sx={{
                            textAlign: "left",
                            borderColor: "lightgrey",
                            borderRadius: 2,
                            margin: 0.5,
                            fontWeight: "light",
                            color: "grey",
                            ":hover": {
                                borderRadius: 2,
                            },
                        }}
                                        onClick={() => {
                                            props.setSelectedValue("canceled")
                                        }}
                        >
                            <ListItemText
                                primary={"Nicht mehr benötigt"}
                            />

                            <DoNotDisturb
                                sx={{
                                    marginLeft: 2,
                                    color: "red"
                                }}
                            ></DoNotDisturb>
                        </ListItemButton>
                    </List>
                </Paper>
            </Stack>
        </Grid>
    </Stack>
}

export default function CloseTicket(props: {
    procurementRequest: ProcurementRequest,
    open: boolean,
    onClose: () => void
}) {
    const [selectedValue, setSelectedValue] = React.useState("")

    const handleClose = () => {
        props.onClose()
    }

    return (
        <Dialog onClose={handleClose} open={props.open} fullWidth={true} maxWidth={"lg"}>
            {(() => {
                switch (selectedValue) {
                    case "accepted":
                        return <AcceptedContent procurementRequest={props.procurementRequest} handleClose={handleClose}
                                                setSelectedValue={setSelectedValue}/>
                    case "canceled":
                        return <CanceledComponent requestId={props.procurementRequest.id} handleClose={handleClose}
                                                  setSelectedValue={setSelectedValue}/>
                    case '':
                        return <SelectCloseReasonContent handleClose={handleClose} setSelectedValue={setSelectedValue}/>
                }
            })()}
        </Dialog>
    )
}

export function TextFieldDeclineReason(props: {
    title: string,
    subTitle: string,
    value: string,
    setValue: (value: string) => void,
}) {
    return (
        <Stack direction="column" sx={{marginBottom: 0.5}}>
            <Stack direction={"row"} alignItems={"center"}>
                <Typography textAlign={"left"} fontWeight={"bold"} marginLeft={0.9}>{props.title}</Typography>
                <Tooltip title={props.subTitle} placement="top-start">
                    <Help
                        sx={{
                            height: "20px",
                            width: "20px",
                            marginBottom: "6px",
                            marginLeft: "2px",
                            color: "gray",
                        }}
                    ></Help>
                </Tooltip> </Stack>
            <TextField
                variant="outlined"
                id="outlined-start-produktvorschlag"
                sx={{
                    m: 0.7,
                    width: 600,
                    maxWidth: {xs: 600, md: 800},
                }}
                size={"medium"}
                multiline
                rows={16}
                value={props.value}
                onChange={(event) => props.setValue(event.target.value)}
            />
        </Stack>
    )
}

export function BackForwardButtons(props: {
    enabled: boolean,
    backFunction: () => void,
    forwardFunction: () => void,
}) {
    return (
        <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            paddingBottom={2}
            paddingRight={6}
            paddingLeft={6}>
            <Button
                variant="outlined"
                color="primary"
                sx={{width: 120, height: 40}}
                onClick={props.backFunction}

            >
                <Typography variant={"body2"}>Zurück</Typography>
            </Button>
            <Button
                color="primary"
                variant="contained"
                disabled={!props.enabled}
                sx={{width: 120, height: 40}}
                onClick={props.forwardFunction}>
                <Typography variant={"body2"}>Senden</Typography>
            </Button>
        </Grid>
    )
}
