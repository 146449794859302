import * as React from "react";
import Box from "@mui/material/Box";
import {Stack, Step, StepButton, Stepper, Typography} from "@mui/material"
import {CheckCircle, Circle} from "@mui/icons-material";
import {TaskList} from "../api/models/tasks";

export function HorizontalNonLinearStepper(props: { taskList: TaskList }) {

    const activeStep = props.taskList.tasks.findIndex(task => task.state === "ready_to_start")

    return (
        <Box sx={{width: '90%'}}>
            <Stepper nonLinear activeStep={activeStep}>
                {props.taskList.tasks.map((task, index) => (
                    <Step key={task.id} completed={task.state === "completed"}>
                        <StepButton color="inherit" icon={task.state === "completed" ? <CheckCircle
                            sx={{
                                color: "green",
                                border: activeStep === index ? 3 : 0,
                                borderRadius: 20,
                                borderColor: "blue",
                                width: 24,
                                height: 24
                            }}/> : <Circle sx={{
                            color: "lightgrey",
                            border: activeStep === index ? 3 : 0,
                            borderRadius: 20,
                            borderColor: "blue",
                            width: 24,
                            height: 24
                        }}/>}>
                            <Stack direction={"column"}>
                            <Typography
                                sx={{
                                    color: activeStep === index ? "blue" : "grey",
                                    fontSize: 14, fontWeight: "bold"
                                }}>{task.title}</Typography>
                                <Stack direction={"column"}>
                                    {task.getAllAssignees().map(assignee => assignee.name).join(', ')}

                                </Stack>
                            </Stack>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {/*<div>*/}
            {/*    {allStepsCompleted() ? (*/}
            {/*        <React.Fragment>*/}
            {/*            <Typography sx={{mt: 2, mb: 1}}>*/}
            {/*                All steps completed - you&apos;re finished*/}
            {/*            </Typography>*/}
            {/*            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>*/}
            {/*                <Box sx={{flex: '1 1 auto'}}/>*/}
            {/*                <Button onClick={handleReset}>Reset</Button>*/}
            {/*            </Box>*/}
            {/*        </React.Fragment>*/}
            {/*    ) : (*/}
            {/*        <React.Fragment>*/}
            {/*            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>*/}
            {/*                <Box sx={{flex: '1 1 auto'}}/>*/}
            {/*                <Stack direction={"row"} alignItems={"center"}>*/}
            {/*                    {activeStep !== steps.length &&*/}
            {/*                        (completed[activeStep] ? (*/}
            {/*                            <Typography variant="caption" sx={{display: 'inline-block'}}>*/}
            {/*                                Prozess-Schritt {activeStep + 1} ist bereits erledigt*/}
            {/*                            </Typography>*/}
            {/*                        ) : (*/}
            {/*                            <Button onClick={handleComplete} variant={"contained"}>*/}
            {/*                                {completedSteps() === totalSteps() - 1*/}
            {/*                                    ? 'Prozess abschließen'*/}
            {/*                                    : 'Abschließen'}*/}
            {/*                            </Button>*/}
            {/*                        ))}*/}
            {/*                    <Button onClick={handleUnComplete}>Zurücksetzen</Button>*/}
            {/*                </Stack>*/}
            {/*            </Box>*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</div>*/}
        </Box>
    )
}