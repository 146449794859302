import { Fragment, useEffect, useState } from "react"
import { Dialog } from "@mui/material"
import { FormContainer } from "../forms/FormContent"
import { useLocation, useNavigate } from "react-router-dom"
import { apiProvider } from "../api/api"
import { Answers, Form } from "../forms/questions"

export function CreateDialog() {
    const navigate = useNavigate()

    const location = useLocation()

    const searchParams = new URLSearchParams(window.location.search)
    const vendorId = searchParams.get("vendorId") ?? undefined
    const productId = searchParams.get("productId") ?? undefined

    const [form, setForm] = useState<Form | undefined>(undefined)

    useEffect(() => {
        let active = true
        load()
        return () => {
            active = false
        }

        async function load() {
            const data = await apiProvider.getFormByParams("create", vendorId, productId)
            if (!active) {
                return
            }
            const answers: Answers = {
                ...(vendorId !== undefined && { vendorId }),
                ...(productId !== undefined && { productId }),
            }
            setForm(data !== undefined ? new Form(data, answers) : undefined)
        }

    }, [vendorId, productId])

    function onClose() {
        navigate(location.state.backgroundLocation)
    }

    return <Dialog
        aria-labelledby="label"
        open={true}
        fullWidth
        maxWidth="lg"
        PaperProps={{
            sx: {
                alignItems: "space-between",
                justifyContent: "space-between",
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 1,
                paddingBottom: 1,
            },
        }}
    >
        {form && <Fragment key={form.id}>
            <FormContainer form={form} onClose={onClose} />
        </Fragment>}
    </Dialog>
}
