/* eslint-disable react/jsx-no-comment-textnodes */
import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid"
import {Box, Button, ListItemButton, Stack, Tooltip, Typography,} from "@mui/material"
import {AutoStories, Description, Help,} from "@mui/icons-material"
import {VendorInfoQuestion} from "../questions"
import {FormElementProps} from "../FormContent"
import {apiProvider} from "../../api/api"
import {TextInput} from "./formQuestionComponent"
import {useCustomSavableState, useSavableState} from "../util"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {VendorAutocomplete} from "../../vendors/VendorAutocomplete"
import {Product, Vendor} from "../../api/models/vendors";
import {Document} from "../../api/models/procurementRequest";
import { useTranslation } from "react-i18next"
import UploadFromElement from "./UploadFromElement"

function VendorInfoInputFields({formElement, hasValidInput}: FormElementProps<VendorInfoQuestion>) {

    const { t } = useTranslation()

    const [selectedVendorId, setSelectedVendorId] = useCustomSavableState<{
        vendorId: string,
        productId?: string
    } | undefined, VendorInfoQuestion>(formElement, "vendorId",
        (question, form) => {
            const productId = question.getAnswer(form, "productId")
            const vendorId = question.getAnswer(form, "vendorId")
            if (vendorId !== undefined) {
                return {vendorId: vendorId, productId: productId}
            } else {
                return undefined
            }
        },
        (question, form, value) => {
            question.save(form, "vendorId", value?.vendorId)
            question.save(form, "productId", value?.productId)
        }
    )

    const [selectedVendor, setSelectedVendor] = useState<{ vendor: Vendor, product?: Product } | undefined>(undefined)

    useEffect(() => {
        if (selectedVendorId !== undefined) {
            if (selectedVendorId?.vendorId !== selectedVendor?.vendor?.id) {
                apiProvider.getVendor(selectedVendorId.vendorId).then(vendor => {
                    const product = selectedVendorId.productId ? vendor.getProductById(selectedVendorId.productId) : undefined;
                    setSelectedVendor({
                        vendor: vendor,
                        product: product
                    })
                    setVendorInputValue(vendor.name)
                    setProductInputValue(product?.name)
                    setUrlInputValue(vendor.website)
                })
            }
        } else {
            setSelectedVendor(undefined)
        }
    }, [selectedVendorId])

    const [vendorInputValue, setVendorInputValue] = useSavableState(formElement, "vendor_name")
    const [productInputValue, setProductInputValue] = useSavableState(formElement, "product_name")
    const [urlInputValue, setUrlInputValue] = useSavableState(formElement, "vendor_url")
    const [quantityInputValue, setQuantityInputValue] = useSavableState(formElement, "license_amount")
    const [documents, setDocuments] = useSavableState(formElement, "document_ids")

    function onVendorChange(newValue: { vendor: Vendor, product?: Product } | string | undefined) {
        let newVendorInputValue: string | undefined = undefined
        let newSelectedVendorId = undefined
        let newSelectedVendor = undefined
        let newUrlInputValue = urlInputValue
        if (typeof newValue === "string") {
            newVendorInputValue = newValue
        } else if (newValue) {
            newSelectedVendorId = {vendorId: newValue.vendor.id, productId: newValue.product?.id}
            newSelectedVendor = newValue
            newVendorInputValue = newValue.vendor.name
            newUrlInputValue = newValue.vendor.website
            setProductInputValue(newValue.product?.name)
        }
        setVendorInputValue(newVendorInputValue)
        setSelectedVendorId(newSelectedVendorId)
        setSelectedVendor(newSelectedVendor)
        setUrlInputValue(newUrlInputValue)
        checkInput(newVendorInputValue)
    }

    function checkInput(vendorInputValue: string | undefined) {
        hasValidInput((vendorInputValue?.length ?? 0) > 0)
    }

    useEffect(() => {
        checkInput(vendorInputValue)
    }, [selectedVendorId, vendorInputValue])

    return <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        paddingBottom={2}>

        <VendorAutocomplete
            fullWidth={false}
            label={t("request_form.vendor") as string}  
            productAndVendor={true}
            initialValue={vendorInputValue}
            selectedItem={selectedVendor}
            onUpdateValue={(newValue: {
                vendor: Vendor,
                product?: Product
            } | string | undefined) => onVendorChange(newValue)}/>

        <TextInput
            label={t("request_form.product")}
            value={productInputValue}
            InputLabelProps={{shrink: !!productInputValue}}
            onChange={(event) => {
                setProductInputValue(event.target.value)
            }}
        />

        <TextInput
            label={t("request_form.url")}
            value={urlInputValue}
            InputLabelProps={{shrink: !!urlInputValue}}
            onChange={(event) => {
                const urlInputValue = event.target.value
                checkInput(vendorInputValue)
                return setUrlInputValue(urlInputValue)
            }}
        />

        <TextInput
            label={t("request_form.number_of_licenses")}
            value={quantityInputValue}
            onChange={(event) => setQuantityInputValue(event.target.value)}/>

        <UploadFromElement documents={documents} setDocuments={setDocuments}/>
    </Grid>
}

export default function VendorInfoQuestionComponent(props: FormElementProps<VendorInfoQuestion>) {
    return (
        <div>
            <Grid container flexDirection={"column"} paddingTop={2} paddingBottom={2}>
                <Grid
                    container
                    flexDirection={"row"}
                    paddingBottom={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{width: "100%"}}
                >
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 30},
                            fontWeight: "bold",
                            align: "center",
                        }}
                    >
                        {props.formElement.title}
                    </Typography>
                    <Tooltip title={props.formElement.info} placement="bottom-end">
                        <Help
                            sx={{
                                height: "20px",
                                width: "20px",
                                marginBottom: "6px",
                                marginLeft: "2px",
                                color: "gray",
                            }}
                        ></Help>
                    </Tooltip>
                </Grid>

                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    paddingBottom={2}>

                    <VendorInfoInputFields {...props}/>
                </Grid>
            </Grid>
        </div>
    )
}
