import i18next from "i18next"
import {initReactI18next} from "react-i18next"
import {TRANSLATION_EN} from "../translation/en"
import {TRANSLATION_DE} from "../translation/de"

const resources = {
    en: {translation: TRANSLATION_EN},
    de: {translation: TRANSLATION_DE},
}

i18next
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: "de",
    })

export default i18next
