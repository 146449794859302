import React, {useState} from "react"
import Autocomplete from "@mui/material/Autocomplete"
import {TextInput} from "../forms/moreinfo/formQuestionComponent"
import {debounce, InputAdornment, Stack, TextField, Typography} from "@mui/material"
import {Product, Vendor} from "../api/models/vendors";
import {backendApiProvider} from "../api/backendApiProvider";

export type ItemTypeMapping<ProductAndVendor> = ProductAndVendor extends true ? {
    vendor: Vendor,
    product?: Product
} : Vendor;

type VendorAutocompleteParams<ProductAndVendor extends boolean> = {
    productAndVendor: ProductAndVendor
    label?: string,
    initialValue: string | undefined,
    loading?: boolean,
    selectedItem: ItemTypeMapping<ProductAndVendor> | undefined,
    onUpdateValue: (value: (ItemTypeMapping<ProductAndVendor> | string | undefined)) => void,
} & React.ComponentProps<typeof TextField>

export function VendorAutocomplete<ProductAndVendor extends boolean>(props: VendorAutocompleteParams<ProductAndVendor>) {
    const [open, setOpen] = useState(false)
    const [items, setItems] = useState<{ vendor: Vendor, product?: Product }[]>([])
    const selectedItem: {
        vendor: Vendor,
        product?: Product
    } | undefined = (props.productAndVendor ? props.selectedItem : props.selectedItem ? {
        vendor: props.selectedItem,
        product: undefined
    } : undefined) as { vendor: Vendor, product?: Product } | undefined

    const [inputValue, setInputValue] = useState(props.initialValue ?? "")

    function onChange(value: { vendor: Vendor, product?: Product } | string | null) {
        if (value === null) {
            props.onUpdateValue(undefined)
        } else if (typeof value === "string") {
            props.onUpdateValue(value)
        } else if (props.productAndVendor) {
            props.onUpdateValue(value as ItemTypeMapping<ProductAndVendor>)
        } else {
            props.onUpdateValue(value.vendor as ItemTypeMapping<ProductAndVendor>)
        }
    }

    const autoCompleteFunction = React.useMemo(
        () =>
            debounce(
                async (
                    request: { input: string },
                    callback: (results?: readonly Vendor[]) => void,
                ) => {
                    const result = await backendApiProvider.autocomplete(request.input)
                    callback(result)
                },
                0,
            ),
        [],
    );

    React.useEffect(() => {
        let active = true

        if (inputValue === '') {
            setItems(selectedItem ? [selectedItem] : [])
            return undefined
        }

        autoCompleteFunction({input: inputValue}, (results) => {
            if (active) {
                let newOptions: { vendor: Vendor, product?: Product }[] = []

                if (results) {
                    newOptions = results?.flatMap((item) => {
                        if (props.productAndVendor) {
                            const vendorAndProduct = item.products?.map((product) => {
                                return {vendor: item, product: product}
                            })
                            return [{vendor: item, product: undefined}, ...vendorAndProduct ?? []]

                        } else {
                            return [{vendor: item, product: undefined}]
                        }
                    }) as { vendor: Vendor, product?: Product }[] ?? []
                }

                setItems(newOptions)
            }
        })

        return () => {
            active = false
        };
    }, [selectedItem, inputValue, fetch])

    return <Autocomplete
        freeSolo
        id="vendor-autocomplete"
        includeInputInList
        filterSelectedOptions
        loading={props.loading ?? false}
        open={open && (inputValue?.length ?? 0) >= 3}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={items}
        value={selectedItem ?? null}
        onChange={(event, value) => onChange(value)}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
            setInputValue(value)
            if (reason !== "reset") {
                onChange(value)
            }
        }}
        filterOptions={(options) => options}
        getOptionLabel={(option) => {
            if (typeof option === "string") {
                return option
            } else if (option !== undefined) {
                return option.vendor.name
            } else {
                return ""
            }
        }}
        renderInput={(params) => {
            return <TextInput
                {...params}
                {...props}
                disabled={true}
                label={props.label}
                InputProps={{
                    ...params.InputProps,
                    ...props.InputProps,
                    startAdornment: (
                        selectedItem && (
                            <InputAdornment position="start">
                                <img
                                    src={selectedItem.vendor.iconUrl}
                                    loading="lazy"
                                    style={{maxWidth: 24, maxHeight: 24}}
                                    alt=""
                                />
                            </InputAdornment>
                        )
                    ),
                }}
            />
        }}
        renderOption={(props, option) => (
            <li {...props}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <img src={option.vendor.iconUrl} loading="lazy" style={{maxWidth: 24, maxHeight: 24}} alt=""/>
                    <Stack direction="column">
                        <Typography variant="body1">{option.vendor.name}</Typography>
                        {option.product && <Typography variant="body2">{option.product.name}</Typography>}
                    </Stack>
                </Stack>
            </li>
        )}
    />
}
