import * as React from "react"
import { useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Button,
    Divider,
    Grid,
    Skeleton,
    Stack,
    Typography,
    Box,
    IconButton
} from "@mui/material"

import { apiProvider } from "../api/api"
import Activities from "./Activities"
import CloseTicket from "./CloseTicket"
import { useAuth } from "../auth/auth"
import { ArrowBack, Delete, ExpandMore, GetApp, KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material"
import { WorkflowGraph } from "../workflow-editor/WorkflowGraph";
import { AllPropertyDetails } from "./AllPropertyDetails"
import MyTasks from "./MyTasks"
import { HorizontalNonLinearStepper } from "./HorizontalStepper";
import { Introduction } from "./Introduction";
import { TaskListComponent } from "./TaskListComponent";
import { ProcurementRequest, ProcurementRequestUpdate } from "../api/models/procurementRequest";
import { QueryKeys } from "../api/queryKeys"
import Sidebar from "./sidebar/Sidebar"
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";


export function RequestDetails() {
    const id = Number.parseInt(useParams()["id"]!)

    const { t } = useTranslation()

    const { data: procurementRequest, refetch: reloadProcurementRequest } = useQuery(
        ["procurement-request", id],
        async () => await apiProvider.getRequest(id)
    )

    const { isLoading, data: taskList } = useQuery({
        queryKey: ["procurement-request", id, "tasks"],
        queryFn: () => apiProvider.getTasks(id),
    })

    const user = useAuth().user
    const hasEditPermission = user?.hasChangeProcurementRequestPermission() ?? false

    const [openDialog, setOpenDialog] = React.useState(false)

    let auth = useAuth()

    const queryClient = useQueryClient()

    const [openDetails, setOpenDetails] = React.useState(false)
    const [openSideBarRight, setOpenSideBarRight] = useSessionStorage("ui_sidebar", false)


    const handleClickOpenDetails = () => {
        setOpenDetails(true)
    }

    const handleCloseDetails = () => {
        setOpenDetails(false)
    }

    const updateDetailsMutation = useMutation(
        (procurementRequestUpdate: ProcurementRequestUpdate) =>
            // update the procurementRequest to backend format
            apiProvider.updateRequest(id, {
                ...procurementRequestUpdate,
                vendor: procurementRequestUpdate.vendor?.id ?? null,
                product: procurementRequestUpdate.product?.id ?? null,
                coordinator: procurementRequestUpdate.coordinator?.id ?? undefined,
                properties: procurementRequestUpdate.properties.values
                    ? Object.entries(procurementRequestUpdate.properties.values).map(
                        ([key, value]) => ({
                            key,
                            value: value.value,
                        })
                    )
                    : [],
            }),
        {
            onSettled: () => {
                // After mutation or error, refetch the procurementRequest
                queryClient.refetchQueries(["procurement-request", id]);
            },
        }
    );

    const { data } = useQuery(QueryKeys.getComments(id), () => {
        return apiProvider.getComments(id)
    })

    return <>
        <Grid
            container
            sx={{
                width: "100%",
                flexDirection: "row",
                height: "100%",
                bgcolor: "white"
            }}
        >
            <Grid item xs={openSideBarRight ? 9 : 12} md={openSideBarRight ? 9 : 12} paddingRight={0.1}
                sx={{ bgcolor: "white" }}>
                <Grid
                    container
                    flexDirection={{ xs: "column", md: "row" }}
                    paddingLeft={2}
                    paddingRight={2}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{ height: 60 }}
                >
                    <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
                                #{id} {procurementRequest?.title?.substring(0, 30)}
                            </Typography>
                            <StatusIcon procurementRequest={procurementRequest}></StatusIcon>
                            <Button onClick={handleClickOpenDetails} variant="outlined" sx={{
                                "&": {
                                    textTransform: 'none',
                                },
                            }} style={{ borderRadius: 8, fontWeight: "bold", marginLeft: 6 }} size={"small"}>
                                {t("request_details.heading")}
                            </Button>
                            {
                                procurementRequest ?
                                    <AllPropertyDetails
                                        procurementRequest={procurementRequest!}
                                        taskList={taskList!}
                                        openSideBar={openDetails}
                                        handleCloseSideBar={handleCloseDetails}
                                        saveRequestUpdate={updateDetailsMutation.mutate}
                                        loading={updateDetailsMutation.isLoading}
                                        reloadRequest={reloadProcurementRequest}
                                    />
                                    :
                                    null
                            }
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"}>
                            {auth.user?.hasChangeProcurementRequestPermission() ?
                                <Stack>

                                    {procurementRequest?.status === "open" ? <Stack>
                                        <Button variant="outlined" onClick={() => setOpenDialog(true)} sx={{
                                            "&": {
                                                textTransform: 'none',
                                            },
                                        }} style={{ borderRadius: 8, fontWeight: "bold" }} size={"small"}>
                                            {t("request_details.close_ticket")}
                                        </Button>
                                        <CloseTicket
                                            procurementRequest={procurementRequest!}
                                            open={openDialog}
                                            onClose={() => setOpenDialog(false)}
                                        />
                                    </Stack> : <Stack>
                                        <Button variant="contained" onClick={() => setOpenDialog(true)} sx={{
                                            "&": {
                                                textTransform: 'none',
                                            },
                                        }} style={{ borderRadius: 8 }} size={"small"}>
                                            {t("request_details.reopen_ticket")}
                                        </Button>
                                    </Stack>}
                                </Stack>
                                : null}
                            {openSideBarRight ? undefined :
                                <KeyboardDoubleArrowLeftOutlined sx={{
                                    marginLeft: 2, width: 30, height: 30, "&:hover": {
                                        cursor: "pointer",
                                    }, color: "blue",

                                }}
                                    onClick={() => setOpenSideBarRight(true)}

                                ></KeyboardDoubleArrowLeftOutlined>
                            }
                        </Stack>


                    </Grid>
                </Grid>
                {isLoading && <Skeleton width={210} height={118} />}
                {procurementRequest && taskList && (
                    <Grid item>
                        <Grid item>
                            {procurementRequest.status === "open" ? null :
                                <Grid container direction="column" sx={{ padding: 2 }}>
                                    <Typography fontWeight={"bold"}>{t("request_details.status_label")}</Typography>
                                    <StatusTextClosedTicket
                                        procurementRequest={procurementRequest}></StatusTextClosedTicket>
                                </Grid>
                            }
                            {hasEditPermission ? undefined :
                                <Grid container direction="column" sx={{ padding: 2 }}>
                                    <Introduction procurementRequest={procurementRequest}
                                        taskList={taskList} />
                                </Grid>
                            }
                            <Divider></Divider>
                            <Grid container direction="column" sx={{ padding: 2, marginTop: 4, }}>
                                <MyTasks tasks={taskList} procurementRequest={procurementRequest} />
                            </Grid>
                            <Grid container direction="column" sx={{ padding: 2, marginTop: 4, marginBottom: 6 }}>
                                <Stack direction={"column"} alignItems={"center"}>
                                    <Typography sx={{ fontWeight: "bold", marginBottom: 3 }}>Prozess-Ablauf</Typography>
                                    <HorizontalNonLinearStepper taskList={taskList}></HorizontalNonLinearStepper>
                                </Stack>
                            </Grid>
                            <Accordion defaultExpanded={false} disableGutters={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ flexDirection: "row-reverse" }}
                                >
                                    <Typography fontWeight={"bold"}>Tasks aller Beteiligter</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {taskList &&
                                        <TaskListComponent
                                            taskList={taskList}
                                            procurementRequest={procurementRequest} />}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={false} disableGutters={true} elevation={0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ flexDirection: "row-reverse" }}
                                >
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <Typography fontWeight={"bold"}
                                            sx={{ marginRight: 2 }}>{t("request_details.comments_heading")}</Typography>
                                        {data?.length && data?.length > 0 ?
                                            <Badge badgeContent={+data?.length} color="primary"></Badge> : undefined}
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Activities procurementRequestId={id}></Activities>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={false} disableGutters={true} elevation={0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ flexDirection: "row-reverse" }}
                                >
                                    <Typography fontWeight={"bold"}>{t("request_details.workflow_heading")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {taskList && <Box display="flex" justifyContent="center">
                                        <Box width="100%" height="500px">
                                            <WorkflowGraph taskList={taskList} />
                                        </Box>
                                    </Box>}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {openSideBarRight ?
                <Grid item xs={3} md={3}>
                    {procurementRequest &&
                        <Sidebar
                            procurementRequest={procurementRequest}
                            openSideBarRight={openSideBarRight}
                            setOpenSideBarRight={setOpenSideBarRight}
                            saveRequestUpdate={updateDetailsMutation.mutate}
                            reloadProcurementRequest={reloadProcurementRequest}
                        />
                    }
                </Grid> : undefined
            }
        </Grid>
    </>
}


export function StatusIcon(props: { procurementRequest: ProcurementRequest | undefined }) {

    const { t } = useTranslation();

    return (
        <div>
            {(() => {
                switch (props.procurementRequest?.status) {
                    case "open":
                        return (
                            <Box sx={{
                                marginLeft: 2,
                                bgcolor: "orange",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 4,
                                color: "white"
                            }}>
                                <Typography sx={{ fontSize: 16 }}>{t("request_details.status.in_progress")}</Typography>
                            </Box>

                        )
                    case "approved":
                        return (
                            <Box sx={{
                                marginLeft: 2,
                                bgcolor: "green",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 4,
                                color: "white"
                            }}>
                                <Typography sx={{ fontSize: 16 }}>{t("request_details.status.in_progress")}</Typography>
                            </Box>
                        )
                    case "canceled":
                        return (
                            <Box sx={{
                                marginLeft: 2,
                                bgcolor: "#FA5F55",
                                paddingLeft: 1,
                                paddingRight: 1,
                                borderRadius: 4,
                                color: "white"
                            }}>
                                <Typography sx={{ fontSize: 16 }}>{t("request_details.status.in_progress")}</Typography>
                            </Box>
                        )
                }
            })()}
        </div>

    )

}

export function StatusTextClosedTicket(props: { procurementRequest: ProcurementRequest | undefined }) {

    const { t } = useTranslation();

    return (
        <div>
            {(() => {
                switch (props.procurementRequest?.status) {
                    case "open":
                        return (
                            <Stack direction={"column"}>
                                <Stack direction={"row"}>
                                    <Typography fontWeight={"bold"}>{ }:</Typography>
                                    <Typography sx={{
                                        marginLeft: 1,
                                        color: "green",
                                    }}>{t("request_details.status_label")}:</Typography>
                                </Stack>
                                <Typography
                                    fontWeight={"bold"}>{t("request_details.close_ticket_dialog.comment")}:</Typography>
                                <Typography>
                                    {props.procurementRequest.conclusion ? props.procurementRequest.conclusion : "Lieferant & Produkt/Dienstleistung erfolgreich angelegt."}
                                </Typography>
                            </Stack>
                        )
                    case "approved":
                        return (
                            <Stack direction={"column"}>
                                <Stack direction={"row"}>
                                    <Typography fontWeight={"bold"}>{t("request_details.status_label")}:</Typography>
                                    <Typography sx={{
                                        marginLeft: 1,
                                        color: "green",
                                    }}>{t("request_details.status.closed_success")}</Typography>
                                </Stack>
                                <Typography
                                    fontWeight={"bold"}>{t("request_details.close_ticket_dialog.comment")}:</Typography>
                                <Typography>
                                    {props.procurementRequest.conclusion ? props.procurementRequest.conclusion : "Lieferant & Produkt/Dienstleistung erfolgreich angelegt."}
                                </Typography>
                            </Stack>
                        )
                    case "canceled":
                        return (
                            <Stack direction={"column"}>
                                <Stack direction={"row"}>
                                    <Typography fontWeight={"bold"}>{t("request_details.status_label")}:</Typography>
                                    <Typography sx={{
                                        marginLeft: 1,
                                        color: "#FA5F55",
                                    }}>{t("request_details.status.closed_canceled")}</Typography>
                                </Stack>
                                <Typography
                                    fontWeight={"bold"}>{t("request_details.close_ticket_dialog.comment")}:</Typography>
                                <Typography>
                                    {props.procurementRequest.conclusion ? props.procurementRequest.conclusion : "Lieferant & Produkt/Dienstleistung erfolgreich angelegt."}
                                </Typography>
                            </Stack>
                        )
                }
            })()}
        </div>
    )
}
