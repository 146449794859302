import {
    Avatar,
    Box,
    Button,
    Chip,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    Stack,
    Typography
} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import List from "@mui/material/List"
import {apiProvider} from "../api/api"
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom"
import {Cancel, OpenInNew} from "@mui/icons-material"
import {useQuery} from "@tanstack/react-query"
import {getReadableCategories} from "../vendors/util"
import {AvailabilityStatusChip} from "./SearchDetails"
import {useAuth} from "../auth/auth"
import {Vendor} from "../api/models/vendors";
import {useTranslation} from "react-i18next"
import {VendorAutocomplete} from "../vendors/VendorAutocomplete";


export function SearchScreen() {
    const {t} = useTranslation()
    const location = useLocation()
    const [currentQueryParameters, setSearchParams] = useSearchParams()

    const query = currentQueryParameters.get("query") ?? ""
    const setQuery = (value: string) => setSearchParams((prev) => {
        prev.set("query", value)
        return prev
    }, {replace: true})
    const selectedCategory = currentQueryParameters.get("category") ?? undefined
    const selectedSubCategory = currentQueryParameters.get("subCategory") ?? undefined


    const [results, setResults] = useState<Vendor[]>([])

    const {data: categories} = useQuery(["categories"], () => apiProvider.getCategories())

    const availableSubCategories = useMemo(() => {
        if (selectedCategory) {
            const category = categories?.find(category => category.name === selectedCategory)
            return category?.sub_categories
        }
    }, [selectedCategory, categories])


    async function search(query: string | undefined, categoryName: string | undefined, subCategoryName: string | undefined) {
        if ((query !== undefined && query?.length > 0) || subCategoryName !== undefined) {
            setResults(await apiProvider.search(query ?? "", categoryName, subCategoryName))
        } else {
            setResults([])
        }
    }

    function handleQuery(value: string) {
        console.log("handleQuery", value)
        setQuery(value)
        if (query.length < 1) {
            setResults([])
            setSubmitted(false)
        }

    }

    function updateCategory(category: string | undefined) {
        setSearchParams((prev) => {
            if (category === undefined) {
                prev.delete("category")
            } else {
                prev.set("category", category)
            }
            return prev
        }, {replace: true})
        search(query, category, undefined)
    }

    function updateSubCategory(subCategory: string | undefined) {
        setSearchParams((prev) => {
            if (subCategory === undefined) {
                prev.delete("subCategory")
            } else {
                prev.set("subCategory", subCategory)
            }
            return prev
        }, {replace: true})
        search(query, selectedCategory, subCategory)
    }

    const [showCategories, setShowCategories] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (query.length > 0 || selectedSubCategory !== undefined) {
            setSubmitted(true)
            search(query, selectedCategory, selectedSubCategory)
        }
    }, [])


    const handleSubmit = (query: string) => {
        console.log(`Searching for: ${query}`)
        search(query, selectedCategory, selectedSubCategory)
        setSubmitted(true)
    }

    let auth = useAuth()

    return (
        <Stack direction="column" alignItems={"center"} spacing={3}
               sx={{marginBottom: 2, marginLeft: 4, marginTop: 20}}>
            <Stack direction="column" sx={{width: "80%",}} alignItems={"center"} justifyContent={"center"}>
                <Typography variant="h4" sx={{
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    marginBottom: 4
                }}>{t("search_screen.title1")}{auth.user?.firstName}{t("search_screen.title2")}</Typography>
                <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Grid item xs={10}>
                        <VendorAutocomplete
                            productAndVendor={true}
                            initialValue={query}
                            selectedItem={undefined}
                            onUpdateValue={(value) => {
                                console.log("onUpdateValue", value)
                                const newQuery = typeof value === "string" ? value : value?.vendor?.name ?? ""
                                handleQuery(newQuery)
                                if (typeof value !== "string") {
                                    handleSubmit(newQuery)
                                }
                            }}
                            onChange={(event) => handleQuery(event.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    console.log("onKeyDown", event.key)
                                    event.preventDefault()
                                    handleSubmit(query)
                                }
                            }}
                            fullWidth
                            autoFocus={true}
                        />
                        <Stack direction={"row"}>
                            {selectedCategory ?
                                <Button
                                    onClick={() => {
                                        updateCategory(undefined)
                                        updateSubCategory(undefined)
                                        setShowCategories(false)
                                    }}
                                    sx={{
                                        color: "grey", "&": {
                                            textTransform: 'capitalize',
                                        },
                                    }} endIcon={<Cancel></Cancel>}>{selectedCategory}</Button> : null}


                            {selectedSubCategory ?
                                <Button onClick={() => {
                                    updateSubCategory(undefined)
                                }} sx={{
                                    color: "grey", "&": {
                                        textTransform: 'capitalize',
                                    },
                                }} endIcon={<Cancel></Cancel>}>{selectedSubCategory}</Button>
                                : null}
                        </Stack>
                    </Grid>
                </Grid>
                {(query.length > 1 && submitted) || selectedSubCategory ? null :
                    <Grid item xs={2} alignItems={"center"} justifyContent={"center"} marginTop={1}>
                        <Button sx={{marginRight: 0.5}} variant="contained" color="primary"
                                onClick={(event) => {
                                    event.preventDefault()
                                    handleSubmit(query)
                                }}>
                            <Typography sx={{textTransform: "none"}}>{t("search_screen.search_button")}</Typography>
                        </Button>
                        <Button sx={{marginLeft: 0.5}} variant="outlined" onClick={() => setShowCategories(true)}>
                            <Typography sx={{textTransform: "none"}}>{t("search_screen.search_categories")}</Typography>
                        </Button>
                    </Grid>
                }

                {query.length > 1 || selectedCategory ?
                    <SearchResultList results={results}/>
                    : null}
                <Stack direction="row" justifyContent={"center"}>
                    <Stack direction={"column"} alignItems={"center"}>
                        {query.length > 1 && submitted ?
                            <Box width="100%">
                                <Stack direction={"row"} width="100%" padding="50px"
                                       alignItems={"center"} justifyContent="left">
                                    {results.length > 0 ?
                                        <Typography
                                            fontWeight={"bold"}>{t("search_screen.nothing_found")}</Typography> :
                                        <Typography fontWeight={"bold"}>{t("search_screen.no_results")}</Typography>}
                                    <Button
                                        sx={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                            px: 2.5,
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            marginLeft: 2
                                        }}
                                        variant="contained"
                                        component={Link}
                                        to={"/create"}
                                        state={{backgroundLocation: location}}
                                        size="small"
                                    >{t("search_screen.new_request")}</Button>
                                </Stack>
                            </Box> : null}


                        {showCategories && !selectedSubCategory ?
                            <Stack sx={{width: "400px", paddingTop: 4,}}>
                                <Box sx={{
                                    padding: 2, border: 1, borderColor: "lightgrey",
                                    borderRadius: 2,
                                }}>
                                    <Typography sx={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        color: "grey"
                                    }}>{t("search_screen.categories.title")}</Typography>
                                    <List>
                                        {(selectedCategory === undefined ? categories ?? [] : availableSubCategories ?? []).map((category: any) => {
                                            return <ListItemButton sx={{
                                                textAlign: "center",
                                                border: 1,
                                                borderColor: "lightgrey",
                                                borderRadius: 2,
                                                margin: 0.5,
                                                fontWeight: "light",
                                                ":hover": {
                                                    borderRadius: 2,
                                                    bgcolor: "blue",
                                                    color: "white"

                                                },
                                            }}
                                                                   onClick={() => {
                                                                       if (selectedCategory === undefined && selectedSubCategory === undefined) {
                                                                           updateCategory(category.name)
                                                                       } else if (selectedCategory !== undefined && selectedSubCategory === undefined) {
                                                                           updateSubCategory(category.name)
                                                                       }
                                                                   }}
                                            >{`${category.name}`} </ListItemButton>
                                        })}
                                    </List>
                                </Box>
                            </Stack> : null
                        }
                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    )
}

function SearchResultList(props: { results: Vendor[] }) {
    const navigate = useNavigate()
    return (
        <List sx={{width: "85%"}}>
            {props.results.map((result) => (
                <ListItem key={result.name}
                          sx={{alignItems: "flex-start", justifyContent: "flex-start", height: "100%"}}>
                    <ListItemButton onClick={() => navigate(`/search/${result.id}`)}>
                        <ListItemAvatar>
                            <Avatar
                                alt={result.name}
                                src={result.iconUrl}
                                variant="square"
                                sx={{width: 120, height: 120, marginRight: 2}}
                            />
                        </ListItemAvatar>
                        <Stack direction="column">
                            <Stack direction="row" alignItems={"center"}>
                                <Typography variant="h6" component="div"
                                            sx={{fontWeight: "bold"}}>{result.name}</Typography>
                                {result.vendorRelation &&
                                    <AvailabilityStatusChip availabilityStatus={result.vendorRelation}/>}
                            </Stack>
                            <Stack direction="row" alignItems={"center"}>
                                <Typography variant="body1" component="a"
                                            target="_blank">{getReadableCategories(result)} | </Typography>
                                <OpenInNew onClick={() => {
                                    window.open(result?.website, '_blank', 'noreferrer')
                                }} sx={{
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                    },
                                    marginLeft: 1,
                                    width: 18,
                                    height: 18
                                }}></OpenInNew>
                            </Stack>
                            <Typography variant="body2"
                                        gutterBottom>{result.description?.substring(0, 300)}...</Typography>
                        </Stack>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
}
