import {
    EdgeProps,
    BaseEdge,
} from 'reactflow';
import React, {useMemo} from "react";

export const CustomEdge = ({
                               sourceX,
                               sourceY,
                               targetX,
                               targetY,
                               style = {},
                               markerEnd,
                           }: EdgeProps) => {
    const distance = Math.abs(targetX - sourceX);
    const radius = 15

    function topCenterPath() {
        return `M ${sourceX},${sourceY}
                L ${sourceX + distance / 3 - radius},${sourceY}
                A ${radius} ${radius} 0 0 1 ${sourceX + distance / 4} ${sourceY + radius}
                L ${sourceX + distance / 4},${-radius}
                A ${radius} ${radius} 1 0 0 ${sourceX + distance / 4 + radius},${0}`
    }

    function bottomCenterPath() {
        return `M ${sourceX},${sourceY}
                L ${sourceX + distance / 4 - radius},${sourceY}
                A ${radius} ${radius} 0 0 0 ${sourceX + distance / 4} ${sourceY - radius}
                L ${sourceX + distance / 4},${radius}
                A ${radius} ${radius} 1 0 1 ${sourceX + distance / 4 + radius},${0}`
    }

    function centerTopPath() {
        return `A ${radius} ${radius} 1 0 0 ${sourceX + 3 * distance / 4},${-radius}
                L ${sourceX + 3 * distance / 4},${targetY + radius}
                A ${radius} ${radius} 0 0 1 ${sourceX + 3 * distance / 4 + radius},${targetY}
                L ${sourceX + 3 * distance / 4 + radius},${targetY}
                L ${targetX},${targetY}`
    }

    function centerBottomPath() {
        return `A ${radius} ${radius} 1 0 1 ${sourceX + 3 * distance / 4},${radius}
                L ${sourceX + 3 * distance / 4},${targetY - radius}
                A ${radius} ${radius} 0 0 0 ${sourceX + 3 * distance / 4 + radius},${targetY}
                L ${sourceX + 3 * distance / 4 + radius},${targetY}
                L ${targetX},${targetY}`
    }

    const edgePath = useMemo(() => {

        sourceY = Math.round(sourceY)
        targetY = Math.round(targetY)

        let path = ''
        if (sourceY < 0) {
            path += topCenterPath()
        } else if (sourceY === 0) {
            path += `M ${sourceX},${sourceY}`
            path += `L ${sourceX + distance / 4},${0}`
        } else {
            path += bottomCenterPath()
        }

        path += `L ${sourceX + 3 * distance / 4 - radius},${0}`

        if (targetY < 0) {
            path += centerTopPath()
        } else if (targetY === 0) {
            path += `L ${sourceX + 3 * distance / 4 + radius},${0}`
            path += `L ${targetX},${targetY}`
        } else {
            path += centerBottomPath()
        }
        return path;
    }, [sourceX, sourceY, targetX, targetY]);


    return <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
    </>
}
