import {Avatar, Box, Grid, ListItemAvatar, Stack, TextField,} from "@mui/material"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import * as React from "react"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import EditComment from "./EditComment"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {apiProvider} from "../api/api"
import {humanReadableDate} from "../util/date"
import {Announcement, Send} from "@mui/icons-material"
import {useTranslation} from "react-i18next"
import {QueryKeys} from "../api/queryKeys"
import {Comment, RobotComment, UserComment} from "../api/models/procurementRequest";

function CommentMessage(props: { value: Comment, procurementRequestId: number }) {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    let message: string
    if (props.value instanceof UserComment) {
        message = props.value.message
    } else if (props.value instanceof RobotComment) {
        message = t(props.value.translationKey, props.value.formattingArgs ?? {})
    } else {
        message = ""
    }

    async function onExecuteAction(actionType: string) {
        await apiProvider.executeRobotAction(props.value.id, actionType)
        await queryClient.invalidateQueries(["procurement-request"])
    }

    return <Grid
        sx={{
            borderRadius: 2,
            marginBottom: 2,
        }}
    >
        <ListItem alignItems="center" dense sx={{borderRadius: 2}}>
            {
                props.value instanceof RobotComment
                    ?
                    <Box
                        src={require("../assets/springer/robot.png")}
                        component="img"
                        sx={{
                            maxWidth: 90,
                            maxHeight: 60,
                            marginRight: 2,
                            padding: 0.7,
                        }}
                        alt="Logo">
                    </Box>:
                    <ListItemAvatar>
                        <Avatar
                            sx={{

                                height: 50, width: 50, marginRight: 2, padding: 0.7
                            }}
                        />
                    </ListItemAvatar>}

            <Grid
                container
                sx={{
                    borderTopLeftRadius: 25,
                    borderTopRightRadius: 25,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 25,
                    padding: 0.5,
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    bgcolor:
                        props.value instanceof RobotComment ? "#c7c8d4" : "#D1E3F5",
                    boxShadow: 6,
                }}
            >
                <Grid container flexDirection={"row"} sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "95%",
                }}>
                    {props.value instanceof RobotComment ? (
                            <Box sx={{width:"5%"}}>
                            <Announcement
                                color="warning"
                                sx={{width: 30, height: 30, marginLeft: 1,}}
                            ></Announcement>
                    </Box>) : null}
                    <Box sx={{width: "95%", }}>
                        <ListItemText
                            sx={{marginLeft: 1}}
                            primary={<Typography sx={{color: "#5A5A5A", fontSize: 12}}>{props.value.created_by.name}</Typography>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{
                                            display: "inline",
                                            color: "5A5A5A"
                                        }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {`${humanReadableDate(props.value.created_at)} - `}
                                        {message}
                                    </Typography>
                                </React.Fragment>

                            }
                        />
                    </Box>

                </Grid>
                <Stack direction={"row"} sx={{width: "5%", justifyContent: "flex-end"}}>
                    <EditComment comment={props.value} procurementRequestId={props.procurementRequestId}/>
                </Stack>
            </Grid>

            {props.value instanceof RobotComment && props.value.actions?.map(action => (
                <Button variant="text" onClick={() => onExecuteAction(action.type)}>{t(`robots.comments.actions.${action.type}`)}</Button>
            ))}
        </ListItem>
    </Grid>
}

export default function Activities(props: { procurementRequestId: number }) {
    const queryClient = useQueryClient()
    const {data} = useQuery(QueryKeys.getComments(props.procurementRequestId), () => {
        return apiProvider.getComments(props.procurementRequestId)
    })

    const [comment, setComment] = React.useState("")

    return (
        <Grid item sx={{margin: 2}}>
            <List
                sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    borderRadius: 5,
                }}>
                {data &&
                    data.map((value, index) => {
                        return <CommentMessage value={value} procurementRequestId={props.procurementRequestId}/>
                    })}

                {/* https://www.frontendmentor.io/solutions/interactive-comments-section-with-reactmui-rylYdOyBc */}
                <Box
                    sx={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "15px",
                    }}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                            variant="circular"
                            sx={{
                                height: 50, width: 50,
                            }}
                        />
                        <TextField
                            multiline
                            fullWidth
                            minRows={1}
                            id="outlined-multilined"
                            placeholder="Schreibe einen Kommentar..."
                            value={comment}
                            onChange={(e) => {
                                setComment(e.target.value)
                            }}
                        />
                        <Button
                            size="medium"
                            variant="text"
                            color="primary"
                            sx={{fontWeight: "bold"}}
                            onClick={async () => {
                                setComment("")
                                await apiProvider.postComment(props.procurementRequestId, comment)
                                await queryClient.invalidateQueries(QueryKeys.getComments(props.procurementRequestId))
                            }}>
                            <Send/>
                        </Button>
                    </Stack>
                </Box>
            </List>
        </Grid>
    )
}
