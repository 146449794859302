import React from 'react'
import './App.css'
import {
    Routes,
    Route, useLocation,
} from "react-router-dom"
import {AuthProvider, RequireAuth} from "./auth/auth"
import {LoginPage} from "./login/login"
import LoggedIn from "./loggedIn"
import {RequestList} from "./requests/RequestList"
import {Analytics} from "./analytics/analytics"
import {CreateDialog} from "./create/create"
import {RequestDetails} from "./requests/RequestDetails"
import {UserList} from "./users/Users"
import {VendorList} from "./vendors/Vendors"
import {SearchScreen} from "./search/SearchScreen"
import {SearchDetails} from "./search/SearchDetails"

export default function App() {
    let location = useLocation()
    let state = location.state as { backgroundLocation?: Location }

    return (
        <AuthProvider>
            <Routes location={state?.backgroundLocation || location}>
                <Route path="/" element={
                    <RequireAuth>
                        <LoggedIn/>
                    </RequireAuth>
                }>
                    {/*<Route path="/" element={<Dashboard/>}/>*/}
                    <Route path="/" element={<RequestList/>}/>
                    <Route path="/requests" element={<RequestList/>}/>
                    <Route path="/requests/:id" element={<RequestDetails/>}/>
                    <Route path="/analytics" element={<Analytics/>}/>
                    <Route path="/users" element={<UserList/>}/>
                    <Route path="/vendors" element={<VendorList/>}/>
                    <Route path="/vendors/:id" element={<SearchDetails/>}/>
                    <Route path="/search" element={<SearchScreen/>}/>
                    <Route path="/search/:id" element={<SearchDetails/>}/>
                </Route>
                <Route path="/login" element={<LoginPage/>}/>
            </Routes>

            {state?.backgroundLocation && (
                <Routes>
                    <Route path="/create" element={<CreateDialog/>}/>
                </Routes>
            )}
        </AuthProvider>
    )
}
