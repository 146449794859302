import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface exiticonProps {
  onClose: (params: any) => any;
}
//.
export default function Exiticon({ onClose }: exiticonProps) {
  return (
    <div>
      <HighlightOffIcon
        onClick={onClose}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
            cursor: "pointer",
          },
          height: 30,
          width: 30,
        }}
      ></HighlightOffIcon>
    </div>
  );
}
