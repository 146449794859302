import {Product, Vendor} from "../api/models/vendors";

export function getReadableCategory(product: Product): string {
    return [
        product.category,
        product.subCategory
    ].filter((it): it is string => !!it)
        .join(" > ")
}

export function getReadableCategories(vendor: Vendor): string {
    return [
        vendor.category,
        vendor.subCategory
    ].filter((it): it is string => !!it)
        .join(" > ")
}
